import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { Icon } from 'semantic-ui-react';

const MenuItem = ({active, to, name, icon}) => (
    <div className="col-auto">
        <Link to={to} className={`btn btn-link-default ${active ? 'active' : ''}`}>
            <span className="icon-text"><Icon name={icon} className='m-0'/></span>
            <span className="text-name">{name}</span>
        </Link>
    </div>
);

class Footer extends Component {

    constructor(props) {
        super(props);
        //console.log(props);
        this.state = {
            currentUrl: ''
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        //console.log('getDerivedStateFromProps', nextProps);
        if(nextProps.currentUrl !== prevState.currentUrl) {
            return { 
                currentUrl: nextProps.currentUrl               
            };
        }
        return null;  
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log('componentDidUpdate', prevProps, prevState);
    }
    
    render() {

        const { currentUrl } = this.state;
        
        return (
            <div className="footer">
                <div className="no-gutters">
                    <div className="col-auto mx-auto">
                        <div className="row no-gutters justify-content-center">
                            <MenuItem to={'/dictionary'} name={'사전검색'} icon={'search'} className='footer-btn-color' active={currentUrl === '/dictionary' ? true : false} />
                            <MenuItem to={'/dictionary/Wordbook'} name={'단어장'} icon={'book'} className='footer-btn-color' active={currentUrl === '/dictionary/Wordbook' ? true : false}/>
                            <MenuItem to={'/dictionary/History'} name={'검색이력'} icon={'history'} className='footer-btn-color' active={currentUrl === '/dictionary/History' ? true : false}/>
                            <MenuItem to={'/qna'} name={'관련사이트'} icon={'linkify'} className='footer-btn-color' active={currentUrl === '/qna' ? true : false}/>
                            <MenuItem to={'/recommend'} name={'친구추천'} icon={'talk'} className='footer-btn-color' active={currentUrl === '/recommend' ? true : false}/>

                            {/*}
                            <div className="col-auto">
                                <Link to='/dictionary' className="btn btn-link-default">
                                    <span className="icon-text"><Icon name='search' className='m-0'/></span>
                                    <span className="text-name">사전검색</span>
                                </Link>
                            </div>
                            <div className="col-auto">
                                <Link to='/dictionary/Wordbook' className="btn btn-link-default">
                                    <span className="icon-text"><Icon name='book' className='m-0'/></span>
                                    <span className="text-name">단어장</span>
                                </Link>
                            </div>                        
                            <div className="col-auto">
                                <Link to='/dictionary/History' className="btn btn-link-default">
                                    <span className="icon-text"><Icon name='history' className='m-0'/></span>
                                    <span className="text-name">검색이력</span>
                                </Link>
                            </div>                            
                            <div className="col-auto">
                                <Link to='/qna' className="btn btn-link-default">
                                    <span className="icon-text"><Icon name='talk' className='m-0'/></span>
                                    <span className="text-name">문의하기</span>
                                </Link>
                            </div>
                            */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;