import axios from 'axios';

import * as types from 'actions/ActionTypes';

// 표제어 검색 ----------------------------------------------------------------//
export const getSearchPyojaeItems = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/dic/search/pyojaeItems',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const getSearchWordSynInfo = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/dic/search/wordSynItems',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

// 단어장 ----------------------------------------------------------------//
export const insertWordbookItem = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/dic/wordbook/insertWordbookItem',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const selectWordbookItems = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/dic/wordbook/searchWordbookItems',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const deleteWordbookItem = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/dic/wordbook/deleteWordbookItem',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}