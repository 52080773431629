import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Grid, Message, Select, Divider } from 'semantic-ui-react';
import Toastr from 'modern-toastr';

import * as service from 'services/system';
import * as systemTypes from 'types/systemTypes';

Toastr.setPosition('toast-top-center');

const mapStateToProps = state => ({
    user: state.auth.user
});

class MgrUserDetail extends Component {
    
    constructor(props) {
        super(props);

        const state = {
            userItem: null,            
            userId: 0,
            userEmail: '',
            userName: '',
            userPhone: '',
            userCheckEmail: '',
            userAuth: '',
            changeCheckEmail: 0,
            changeUserAuth: 0           
        };
        
        let v = {
			couponStatus: {
				error: false,
				message: '쿠폰상태를 선택해 주세요.'
			},
		};

		let emailCheckOption = systemTypes.DETAIL_OPT_CHECK_EMAIL_LABEL.map(key => {
			let item = systemTypes.DETAIL_OPT_CHECK_EMAIL.properties[key];
			return {
				key: item.id,
				text: item.label,
				value: item.value,
				style: { fontSize: '0.9em' },
			};
        });
        
        let userAuthOption = systemTypes.DETAIL_OPT_USER_AUTH_LABEL.map(key => {
			let item = systemTypes.DETAIL_OPT_USER_AUTH.properties[key];
			return {
				key: item.id,
				text: item.label,
				value: item.value,
				style: { fontSize: '0.9em' },
			};
		});
        //console.log(props);
        const userItem = (props.location.post !== undefined && props.location.post !== null) ? props.location.post.userItem : null;
        state.userItem = userItem;
        state.userId = (userItem && userItem.ID !== null) ? userItem.ID : '';
        state.userEmail = (userItem && userItem.USEREMAIL !== null) ? userItem.USEREMAIL.trim() : '';
        state.userName = (userItem && userItem.USERNAME !== null) ? userItem.USERNAME.trim() : '';
        state.userPhone = (userItem && userItem.PHONENUMBER !== null) ? userItem.PHONENUMBER : '';
        state.userCheckEmail = userItem ? (userItem.CHECK_EMAIL === 'T') ? '인증완료' : '인증필요' : '인증필요';

        this.state = {			
			isWarning: false,            
            ...state,
            emailCheckOption,
            userAuthOption,	
		};
    }

    componentDidMount() {
    }

    handleOnClickGoBack = () => {
        this.props.history.goBack();
    }

    typeHandler = (e, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {		
			this.setState({ [name]: value });
		}
    };
    
    onSumbit = e => {
        e.preventDefault();
        
        const user = this.props.user;

        if(user !== undefined && user !== null && user.grade === 0) {
            const { userId, changeCheckEmail, userCheckEmail } = this.state;
            if(userId !== '') {
                const checkEmail = changeCheckEmail === 0 ? 'F' : 'T';                
                this.fetchUpdateUserInfo({
                    userId: userId,
                    userCheckEmail: checkEmail
                });
                                
            } else {
                Toastr.error('변경할 사용자 정보가 올바르지 않습니다. 확인 후 다시 시도해 주세요.');   
            }
        } else {
            Toastr.error('관리자 권한이 필요합니다.');   
        }        
    };
    
    fetchUpdateUserInfo = async(params) => {

        const userInfo = await service.updateUserItem(params);

        if(userInfo !== null) {            
            if(userInfo.status === 200) {
                Toastr.info(userInfo.data.message)       
            } else {
                Toastr.error(userInfo.data.message);
            }
        } else {
            Toastr.error('사용자 정보 변경중에 오류가 발생하였습니다. 다시 시도해 주세요.');
        }
    }

    render() {
        const {	userEmail, userName, userPhone, userCheckEmail, emailCheckOption, userAuthOption,
            changeCheckEmail, changeUserAuth, isWarning, errors	} = this.state;
            
        return (
            <div className="container my-5">
                <div className="row mb-4">
                    <div className='col'>
                        <Button size='tiny' circular icon='arrow left' onClick={this.handleOnClickGoBack} />
                        <span className="ml-3 mt-1 text-13 font-600">회원 상세정보</span>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-12 mx-auto">
                        <Form warning={isWarning} key="big" size="big">
                            <Grid>							
                                <Grid.Row style={{ marginLeft: '0px' }}>
                                    <Grid.Column>
                                        <Message warning header="아래 사항을 확인해 주세요." list={errors} />									

                                        <Form.Group width={16}>
                                            <Form.Field width={8}>                                            
                                                <Form.Input	
                                                    label='이메일'
                                                    fluid											
                                                    name="userEmail"
                                                    value={userEmail}
                                                    readOnly
                                                />                                            
                                            </Form.Field>	
                                            
                                            <Form.Field width={8}>
                                                <Form.Input
                                                    label='사용자명'	
                                                    fluid											
                                                    name="userName"
                                                    value={userName}
                                                    readOnly
                                                />
                                            </Form.Field>
                                        </Form.Group>

                                        <Form.Group width={16}>
                                            <Form.Field width={8}>                                            
                                                <Form.Input	
                                                    label='연락처'
                                                    fluid											
                                                    name="userPhone"
                                                    value={userPhone}
                                                    readOnly
                                                />                                            
                                            </Form.Field>	
                                            
                                            <Form.Field width={8}>
                                                <Form.Input	
                                                    label='이메일 인증'
                                                    fluid											
                                                    name="checkEmail"
                                                    value={userCheckEmail}
                                                    readOnly
                                                />
                                            </Form.Field>
                                        </Form.Group>

                                        <Form.Group width={16}>
                                            <Form.Field width={8}>                                            
                                                <Form.Input	
                                                    label='사용자권한'
                                                    fluid											
                                                    name="userPhone"
                                                    value={''}
                                                    readOnly
                                                />                                            
                                            </Form.Field>
                                        </Form.Group>

                                        <Divider />

                                        <Form.Group width={16}>
                                            <Form.Field required width={8}>
                                                <label>이메일인증 변경</label>
                                                <Select
                                                    name="changeCheckEmail"
                                                    fluid
                                                    options={emailCheckOption}
                                                    placeholder="이메일 인증"
                                                    style={{ fontSize: '0.9em' }}
                                                    onChange={this.typeHandler}
                                                    value={changeCheckEmail}
                                                />
                                            </Form.Field>
                                            <Form.Field required width={8}>
                                                <label>사용자권한 변경</label>
                                                <Select
                                                    name="changeUserAuth"
                                                    fluid
                                                    options={userAuthOption}
                                                    placeholder="사용자 권한"
                                                    style={{ fontSize: '0.9em' }}
                                                    onChange={this.typeHandler}
                                                    value={changeUserAuth}
                                                />
                                            </Form.Field>                                            
                                        </Form.Group>
                                                                    
                                    </Grid.Column>
                                </Grid.Row>							

                                <Grid.Row style={{ marginLeft: '0px' }}>
                                    <Grid.Column textAlign="center">
                                        <Button size="huge" onClick={this.handleOnClickGoBack}>
                                            이전
                                        </Button>
                                        <Button size="huge" color="teal" onClick={this.onSumbit}>
                                            수정
                                        </Button>									
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </div>

                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, null)(MgrUserDetail);