import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Icon, Button } from 'semantic-ui-react';
import Validator, { Validation, Message } from 'es-validator';
import PropTypes from 'prop-types';

import * as actions from 'actions';

let mapStateToProps = state => {
	return {
        user: state.auth.user,
		changed: state.auth.changed,
	};
};

const mapDispatchToProps = dispatch => ({
	change: user => dispatch(actions.updateUserInfo(user)),
	changing: changed => dispatch(actions.setChangedPw(changed)),
});

class ChangePassword extends Component {

    constructor(props) {
        super(props);

        this.errorPrint = v => (
			<div key={v.key} className="valid-feedback">
				<span>{v.message}</span>
			</div>
        );

        this.validatorForChangePassword = new Validator({
            oldPassword: {
				_name: '이전 암호',
				_hasText: { message: '이전 비밀번호를 입력해주세요.' },
				_minLength: {
					min: 8,
					message: '8자 이상의 비밀번호를 입력해 주세요.',
				}				
			},
			newPassword: {
				_name: '새로운 암호',
				_hasText: { message: '새 비밀번호를 입력해주세요.' },
				_minLength: {
					min: 8,
					message: '8자 이상의 비밀번호를 입력해 주세요.',
				}				
			},
			rePassword: {
				_name: '암호확인',
				_hasText: { message: '비밀번호를 입력해주세요.' },
				_makeFunc: {
					func: (value, state) => value === state.newPassword,
					message: '암호가 일치하지 않습니다.',
				}
			},
		});

        const change = {
            oldPassword: '',
			newPassword: '',
			rePassword: '',
        };
        
        this.state = {
            ...change,
            user: props.user,
            changed: props.changed,	
            vChange: this.validatorForChangePassword.prepare(change),
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {        
        if(nextProps.user !== prevState.user || nextProps.changed !== prevState.changed) {
            //console.log(nextProps.changed, prevState.changed);
            return { 
                user: nextProps.user,
                changed: nextProps.changed               
            };
        }        
        return null;
    }

    onInputTextChange = input => e => {
		this.setState({
			[input]: e.target.value,
		});
    };

    handleOnClickGoBack = (e) => {
        this.props.history.goBack();
    }

    onSubmit = (e) => {
        e.preventDefault();

        const { vChange, ...state } = this.state;
		const { change } = this.props;

		this.setState({
			vChange: this.validatorForChangePassword.submit(state),
		});

        if (!this.validatorForChangePassword.getError()) {
            //console.log(this.state.user);
			change({
                userId: this.state.user.id,
				oldPassword: this.state.oldPassword,
				newPassword: this.state.newPassword,
			});
		} 
    }

    render() {
        const { vChange, changed } = this.state;
        const { oldPassword, newPassword, rePassword } = vChange;
        const { changing } = this.props;
        
        if(changed) {
            changing(false);
            return <Redirect to={'/login'} />
        }

        return (
            <div className='container my-5'>
                <div className="row mb-5">
                    <div className="col text-uppercase">
                        <h5>비밀번호 변경</h5>
                    </div>
                    <div className="col-auto align-self-end"></div>
                </div>

                <div className="row flex-colum">
                    <div className="col-12 mx-auto">
                        <div className="row">
                            <div className="col-12 align-self-center text-center">
                        
                                <form className="form-signin">
                                    <div className="row">
                                        <div className="col-auto align-self-center">
                                            <Icon name='keyboard' />
                                        </div>
                                        <div className="col pl-0">
                                            <Validation isValid={oldPassword.error}>
                                                <div className="form-group float-label">
                                                    <input 
                                                        id="oldPassword"
                                                        name="oldPassword"
                                                        type="password" 
                                                        className="form-control" 
                                                        autoComplete="on" 
                                                        onChange={this.onInputTextChange('oldPassword')}
                                                        value={this.state.oldPassword}
                                                        required />
                                                    <label htmlFor="oldPassword" className="form-control-label">Old Password</label>
                                                </div>
                                                <Message once valid={oldPassword} render={this.errorPrint} />
                                            </Validation>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-auto align-self-center">
                                            <Icon name='lock' />
                                        </div>
                                        <div className="col pl-0">
                                            <Validation isValid={newPassword.error}>
                                                <div className="form-group float-label">
                                                    <input 
                                                        id="newPassword"
                                                        name="newPassword"
                                                        type="password" 
                                                        className="form-control" 
                                                        autoComplete="on" 
                                                        onChange={this.onInputTextChange('newPassword')}
                                                        value={this.state.newPassword}
                                                        required />
                                                    <label htmlFor="newPassword" className="form-control-label">New Password</label>
                                                </div>
                                                <Message once valid={newPassword} render={this.errorPrint} />
                                            </Validation>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-auto align-self-center">
                                            <Icon name='key' />
                                        </div>
                                        <div className="col pl-0">
                                            <Validation isValid={rePassword.error}>
                                                <div className="form-group float-label">
                                                    <input 
                                                        id="rePassword" 
                                                        name="rePassword"
                                                        type="password" 
                                                        className="form-control" 
                                                        autoComplete="on" 
                                                        onChange={this.onInputTextChange('rePassword')}
                                                        value={this.state.rePassword}
                                                        required 
                                                    />
                                                    <label htmlFor="rePassword" className="form-control-label">Repassword</label>
                                                </div>
                                                <Message once valid={rePassword} render={this.errorPrint} />
                                            </Validation>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                        
                </div>

                <div className='row mt-5'>
                    <div className="col-12 text-center">
                        <Button className="mr-2"
                            onClick={e => this.handleOnClickGoBack(e)}>
                            <span className="mr-1">이전</span>
                        </Button>
                        <Button color='orange' onClick={e => this.onSubmit(e)}>
                            <Icon name='retweet' />
                            <span className="mr-1">변경하기</span>
                        </Button>
                    </div>
                </div>
                       
            </div>
                
        );
    }
}

ChangePassword.propTypes = {
	changed: PropTypes.bool,
};

ChangePassword.defaultProps = {
	changed: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);