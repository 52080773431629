import React, { Component, Fragment } from 'react';
import { Icon, Card, Image } from 'semantic-ui-react';
import Toastr from 'modern-toastr';

Toastr.setPosition('toast-top-center');

class AskUs extends Component {
  render() {
    return (
      <Fragment>
        <div className="container my-5">
          <div className="row mb-4">
            <div className="col">
              <h4 className="mb-0">관련사이트</h4>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 mb-4">
              <div className="row">
                <div className="col">
                  <Card fluid>
                    <a href="https://www.natmal.com" target="_blank">
                      <Image
                        centered
                        className="max-width-300 p-5 bg-white"
                        src="/assets/images/logos/Logo_50_C.png"
                      />
                    </a>
                    <Card.Content>
                      <Card.Meta>
                        <span className="date">(주)낱말 - 낱말닷컴</span>
                      </Card.Meta>
                      <Card.Description>
                        낱말닷컴은 국내 최대 규모의 유의어 사전을 서비스
                        중입니다. 다양한 형태의 유의어를 찾아보고 경험해 보시면
                        어휘력 향상에 큰 도움이 됩니다. 글쓰기에 도움이 되는
                        문장검사(맞춤법, 유의어 추천 등), 독서력 검사, 어휘력
                        검사, 낱말퍼즐, 영어-한글 유의어 검색 등 다양한 서비스를
                        동시에 누릴 수 있습니다.
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <a href="https://www.natmal.com" target="_blank">
                        <Icon name="linkify" /> www.natmal.com
                      </a>
                    </Card.Content>
                  </Card>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 mb-4">
              <div className="row">
                <div className="col">
                  <Card fluid>
                    <a href="https://opendict.korean.go.kr" target="_blank">
                      <Image
                        centered
                        className="max-width-300 p-5 bg-white"
                        src="/assets/images/logos/logo.png"
                      />
                    </a>
                    <Card.Content>
                      <Card.Meta>
                        <span className="date">국립국어원 - 우리말샘</span>
                      </Card.Meta>
                      <Card.Description>
                        우리말샘은 국립국어원에서 서비스 중인 국어사전
                        사이트입니다. 1백만 개 이상의 표제어가 있습니다. 단어의
                        자세한 뜻풀이를 알고 싶은 분은 우리말샘을 이용하세요.
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <a href="https://opendict.korean.go.kr" target="_blank">
                        <Icon name="linkify" /> opendict.korean.go.kr
                      </a>
                    </Card.Content>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AskUs;
