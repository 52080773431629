import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Form, Card, TextArea, Checkbox, Button, Input } from 'semantic-ui-react';
import Toastr from 'modern-toastr';

import * as actions from 'actions';
import * as service from 'services/system';

Toastr.setPosition('toast-top-center');

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
	logout: () => dispatch(actions.logout()),
});

const options = [
	{ key: '1', text: '기능 및 서비스 부족', value: '기능 및 서비스 부족' },
	{ key: '2', text: '시스템 품질에 대한 불만(오류 및 오작동)', value: '시스템 품질에 대한 불만(오류 및 오작동)' },
	{ key: '3', text: '서비스 이용 제한', value: '서비스 이용 제한' },
	{ key: '4', text: '서비스 한시적 이용', value: '서비스 한시적 이용' },
	{ key: '5', text: '개인 정보 누출 우려', value: '개인 정보 누출 우려' },
	{ key: '6', text: '기타', value: '기타' },
];

class Withdraw extends Component {
	constructor(props) {
		super(props);

		this.state = {
			fetching: false,
			isWarning: false,
			isChecking: false,
			userPassword: '',
			reasonMain: '',
			reasonSub: '',
			chkAgreement: false,
			reasonMainError: false,
			chkAgreementError: false,
		};
	}

	componentDidMount() {
		const user = this.props.user;
		if (user) {
			this.setState({ user: this.props.user });
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ user: nextProps.user });
	}

	handleOnChangePassword = (e) => {
		this.setState({
			userPassword: e.target.value,
		});
	};

	handleOnChangeSelect = (e, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {
			this.setState({ [name]: value });
		}
	};

	handleOnChangeReasonSub = (e) => {
		this.setState({
			reasonSub: e.target.value,
		});
	};

	handleOnChangeChkAgreement = (e, { name, checked }) => {
		this.setState({
			chkAgreement: checked,
		});
	};

	handleOnClickCancel = (e) => {
		e.preventDefault();

		this.setState({
			isChecking: false,
			userPassword: '',
			reasonMain: '',
			reasonSub: '',
			chkAgreement: false,
			reasonMainError: false,
			chkAgreementError: false,
		});
	};

	handleOnClickSaveUserCancel = () => {
		const user = this.state.user;
		const userPw = this.state.userPassword;
		const reasonMain = this.state.reasonMain;
		const reasonSub = this.state.reasonSub;
		const chkAgreement = this.state.chkAgreement;

		let agreement = 'F';

		if (userPw === '') {
			Toastr.error('사용자 비밀번호를 입력해 주세요.');
			return;
		}

		if (reasonMain === '') {
			this.setState({
				reasonMainError: true,
			});
			Toastr.error('탈퇴사유를 선택해 주세요.');
			return;
		} else {
			this.setState({
				reasonMainError: false,
			});
		}

		if (chkAgreement) {
			agreement = 'T';
			this.setState({
				chkAgreementError: false,
			});
		} else {
			this.setState({
				chkAgreementError: true,
			});
			Toastr.error('탈퇴를 위한 사용자 동의가 필요합니다.');
			return;
		}

		if (user !== undefined && user !== null) {
			//console.log(user);
			this.fetchSaveUserCancel({
				userId: user.id,
				userPw: userPw,
				userEmail: user.userEmail,
				userName: user.userName,
				reasonMain: reasonMain,
				reasonSub: reasonSub,
				agreement: agreement,
			});
		} else {
			Toastr.error('회원인증이 필요한 서비스 입니다. 로그인 후 이용해 주세요.');
		}
	};

	fetchSaveUserCancel = async (params) => {
		const userCancelInfo = await service.insertUserCancelInfo(params);
		//console.log(userCancelInfo);
		if (userCancelInfo !== null) {
			if (userCancelInfo.status === 200) {
				Toastr.info('탈퇴요청이 정상적으로 처리되었습니다.');
				this.props.logout();
				this.props.history.push('/');
			} else {
				Toastr.error(userCancelInfo.data.message);
			}
		} else {
			Toastr.error('회원 탈퇴 처리 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
		}
	};

	render() {
		const {
			isWarning,
			isChecking,
			isChkPasswordError,
			userPassword,
			reasonMain,
			reasonSub,
			reasonMainError,
			chkAgreement,
			chkAgreementError,
		} = this.state;
		return (
			<div className="container my-5">
				<div className="row mb-4">
					<div className="col text-uppercase">
						<h4 className="mb-0">회원탈퇴</h4>
					</div>

					<div className="col-auto align-self-end"></div>
				</div>
				<div className="row">
					<div className="col-12 mb-4">
						<div className="row">
							<div className="col">
								<div className="ui negative message">
									<ul className="list">
										<li className="content">
											회원 탈퇴를 하시면 해당 아이디로 가입된 모든 서비스는 사용할 수 없습니다.
										</li>
										<li className="content">
											또한 해당 아이디로 가입/사용하면서 축척된{' '}
											<span className="text-highlight-blue font-600">
												모든 정보 및 기록은 모두 삭제
											</span>
											되며, <span className="text-blue font-600">복구가 불가능</span>합니다.
										</li>
										<li className="content">
											만약, 해당 아이디로 사용 중인 유료서비스가 있는 경우{' '}
											<span className="text-highlight-blue font-600">
												환불규정에 의하여 환불처리
											</span>
											가 진행됩니다.
										</li>
										<li className="content">
											탈퇴가 완료되면{' '}
											<span className="text-highlight-blue font-600">자동 로그아웃</span>됩니다.
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 mb-4">
						<Form warning={isWarning} key="big">
							<Grid>
								<Grid.Row style={{ marginLeft: '0px' }}>
									<Grid.Column>
										<Card fluid size="small">
											<Card.Content>
												<Form.Field
													name="userPassword"
													control={Input}
													type="password"
													label="비밀번호"
													placeholder="비밀번호를 입력해 주세요."
													onChange={this.handleOnChangePassword}
													value={userPassword}
												/>
												<Form.Select
													name="reasonMain"
													fluid
													label="탈퇴사유"
													options={options}
													placeholder="탈퇴사유를 선택해 주세요."
													onChange={this.handleOnChangeSelect}
													value={reasonMain}
													error={reasonMainError}
												/>
												<Form.Field
													name="reasonSub"
													control={TextArea}
													label="기타"
													placeholder="위의 탈퇴사유 이외에 저희에게 한 말씀 적어주시면 서비스 개선 및 업데이트 시 참고하도록 하겠습니다."
													onChange={this.handleOnChangeReasonSub}
													value={reasonSub}
												/>
												<Form.Field
													name="agreement"
													control={Checkbox}
													label=" 위 내용을 모두 확인하였으며, 탈퇴 시 모든 정보가 복구 불가능함에 동의합니다."
													onChange={this.handleOnChangeChkAgreement}
													checked={chkAgreement}
													error={chkAgreementError}
												/>
											</Card.Content>
										</Card>
									</Grid.Column>
								</Grid.Row>

								<Grid.Row style={{ marginLeft: '0px' }}>
									<Grid.Column textAlign="center">
										<Button onClick={this.handleOnClickCancel}>취소하기</Button>
										&nbsp;
										<Button color="teal" onClick={this.handleOnClickSaveUserCancel}>
											탈퇴하기
										</Button>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Form>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Withdraw));
