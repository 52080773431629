import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Segment, Grid, Divider, Statistic } from 'semantic-ui-react'

import Toastr from 'modern-toastr';
import CanvasJSReact from 'utils/canvasjs.react';

import * as moment from 'moment';
import * as service from 'services/system';

Toastr.setPosition('toast-top-center');

const CanvasJSChart = CanvasJSReact.CanvasJSChart;
const mapStateToProps = state => ({
    user: state.auth.user
});

class UserStatisticUser extends Component {    

    constructor(props) {
        super(props);

        this.state = {
            startDate: props.startDate,
            endDate: props.endDate,
            joinUsers: [],
            paidUsers: [],
            joinUserCount: 0,
            paidUserCount: 0
        }
    }

    componentDidMount() {
        //console.log(this.state);
        const { startDate, endDate } = this.state;
        if(startDate !== undefined && startDate !== null && startDate !== '' &&
            endDate !== undefined && endDate !== null && endDate !== ''
        ) {
            this.fetchSelectUserJoinStatistic({
                startDate: startDate,
                endDate: endDate
            })
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.startDate !== prevState.startDate ||
            nextProps.endDate !== prevState.endDate
            ) {            
            return { 
                startDate: nextProps.startDate,
                endDate: nextProps.endDate
            };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {    
        const { startDate, endDate } = this.state;     
        if(startDate !== prevState.startDate || endDate !== prevState.endDate
            ) {
            this.fetchSelectUserJoinStatistic({
                startDate: startDate,
                endDate: endDate
            })            
        }
    }

    fetchSelectUserJoinStatistic = async (params) => {

        const user = this.props.user;
        const statisticInfo = await service.selectUserJoinStatistic(params); 

        //console.log(statisticInfo);

        if(user !== undefined && user !== null && user.grade === 0) {
            if(statisticInfo !== null) {            
                if(statisticInfo.status === 200) {
                    if(statisticInfo.data) {
                        this.showUserStatistic(statisticInfo.data);    
                    } else {
                        
                    }             
                } else {
                    Toastr.error(statisticInfo.data.message);
                }
            } else {
                Toastr.error('사용자 통계 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
            }  
        }
    }

    showUserStatistic(data) {
        //console.log(data);
        let joinUsers = [];
        let paidUsers = [];

        if(data.datas.length > 0) {            
            data.datas.map((item, index) => {
                joinUsers.push({label: item.INS_DATE, y: item.JOIN_CNT});
                paidUsers.push({label: item.INS_DATE, y: item.PAY_CNT !== null ? item.PAY_CNT : 0});
            });            
        }

        this.setState({
            joinUsers: joinUsers,
            paidUsers: paidUsers,
            joinUserCount: data.totalUserCount,
            paidUserCount: data.totalPaidUserCount
        });
    }
    
    render() {        

        const { joinUsers, paidUsers, joinUserCount, paidUserCount } = this.state;
        const options = {
			theme: "light2",
			animationEnabled: true,
			title: {
				text: "회원(무료/유료) 가입수"
			},
			subtitles: [{
				text: ""
            }],            
			axisY: {
				includeZero: false,
				prefix: ""
			},
			toolTip: {
				shared: true
			},
			data: [
			{
				type: "area",
				name: "가입회원",
				showInLegend: true,
				yValueFormatString: "#,##0.##",
				dataPoints: joinUsers
			},
			{
				type: "area",
				name: "유료회원",
				showInLegend: true,
				yValueFormatString: "#,##0.##",
				dataPoints: paidUsers
			}
			]
		}

        return (           
            <Fragment>
                <div className='row mt-4'>
                    <div className='col-12 mb-4'>
                        <Segment size='mini'>
                            <Grid columns={2} stackable textAlign='center'>
                            <Divider vertical>And</Divider>

                            <Grid.Row verticalAlign='middle'>
                                <Grid.Column>
                                    <Statistic>
                                        <Statistic.Label>총 회원수</Statistic.Label>
                                        <Statistic.Value>{joinUserCount}</Statistic.Value>
                                    </Statistic>
                                </Grid.Column>

                                <Grid.Column>
                                    <Statistic>
                                        <Statistic.Label>총 유료 회원수</Statistic.Label>
                                        <Statistic.Value>{paidUserCount}</Statistic.Value>
                                    </Statistic>
                                </Grid.Column>
                            </Grid.Row>
                            </Grid>
                        </Segment>
                        <CanvasJSChart className='mt-3' options = {options} />
                    </div>
                </div>
            </Fragment>      
        );
    }
}

export default connect(mapStateToProps, null)(withRouter(UserStatisticUser));