import axios from 'axios';

import * as types from 'actions/ActionTypes';

export const selectUserItems = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/sys/selectUserItems',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateUserConnectInfo = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/user/updateUserConnectInfo',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateUserItem = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/sys/updateUserItem',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const selectUserOrderItems = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/sys/selectUserOrderItems',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const insertUserOrderItem = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/sys/insertUserOrderItem',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const selectPaymentItems = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/sys/selectPaymentItems',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const checkCouponGroupOverlap = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/sys/checkCouponOverlap',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const insertCouponGroupItem = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/sys/insertCouponGroupItem',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const selectCouponGroupItems = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/sys/selectCouponGroupItems',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const selectCouponItems = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/sys/selectCouponItems',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateCouponItem = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/sys/updateCouponItem',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteCouponItem = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/sys/deleteCouponItem',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const insertCouponItems = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/sys/insertCouponItems',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const presentCouponItem = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/sys/presentCouponItem',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const insertUserCancelInfo = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/user/insertUserCancelInfo',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const checkRecommendUserInfo = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/user/checkRecommendUserInfo',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const searchPrevUserInfo = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/user/searchPrevUserInfo',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const combinePrevUserInfo = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/user/combinePrevUserInfo',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const selectUserJoinStatistic = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/sys/selectUserJoinStatistic',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const selectUserConnectStatistic = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/sys/selectUserConnectStatistic',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const selectSearchStatistic = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/sys/selectSearchStatistic',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const selectSearchWordStatistic = (params) => {
  return axios
    .post(
      types.LOCAL_API_URL + '/api/sys/selectSearchWordStatistic',
      { params },
      { withCredentials: true }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const cancelPay = (datas) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        types.LOCAL_API_URL + '/api/sys/order/cancel',
        { data: datas },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};
