import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Icon } from 'semantic-ui-react';
import Validator, { Validation, Message } from 'es-validator';
import Toastr from 'modern-toastr';

import * as actions from 'actions';
import * as commonUtil from 'utils/common';

Toastr.setPosition('toast-top-center');

const mapDispatchToProps = dispatch => ({
    find: user => dispatch(actions.find(user)),
});

class findEmail extends Component {
    
    constructor(props) {
        super(props);

        this.errorPrint = v => (
			<div key={v.key} className="valid-feedback">
				<span>{v.message}</span>
			</div>
        );
        
        this.validatorForFind = new Validator({
			findEmail: {
				_name: '이메일',
				_hasText: { message: '이메일을 입력해 주세요.' },
				_email: { message: '이메일 형식이 올바르지 않습니다.' },
				_minLength: {
					min: 5,
					message: '이메일 형식이 올바르지 않습니다.',
				},
			},
        });
        
        const localEmail = commonUtil.getLoginEmail();
        
        const find = {
			findEmail: localEmail,
		};
        
        this.state = {			
			...find,		
			vFind: this.validatorForFind.prepare(find),
			open: false,			
        };        
    }

    onInputTextChange = input => e => {
		this.setState({
			[input]: e.target.value,
		});
	};

    onSubmit = (e) => {
        e.preventDefault();

        const { vFind, ...state } = this.state;
        const { find } = this.props;
        
        this.setState({
            vFind: this.validatorForFind.submit(state),
        });

        if (!this.validatorForFind.getError()) {
            find({ email: this.state.findEmail });
        } 
    }

    render() {
        const { vFind } = this.state;
        const { findEmail } = vFind;

        return (
            <div className="container my-5">
                <div className='row'>
                    <div className="col">
                        <Link to='/login' className='btn btn-sm text-secondary'>
                            로그인
                        </Link>
                    </div>                                       
                </div>
                <div className="row mb-3">
                    <div className="col-12 align-self-center text-center">
                        <h5>비밀번호 재설정</h5>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className="col-12 align-self-center text-center">
                        <div className="ui negative message">
                            <ul className="list">
                                <li className="content">
                                    입력된 이메일 주소로 변경 가능한 접속주소를 보내드립니다.
                                </li>
                                <li className="content">
                                    메일을 못 받으신 경우 스팸 메일함도 확인하시길 바랍니다.
                                </li>                                        
                            </ul>                                       
                        </div>
                    </div>
                </div>
                <div className="row flex-colum">                    
                    <div className="col-12 mx-auto login-row">
                        <div className="align-self-center text-center"> 
                            <form className="form-signin">
                                <div className="row">
                                    <div className="col-auto align-self-center">
                                        <Icon name='mail' />
                                    </div>
                                    <div className="col pl-0">
                                        <Validation isValid={findEmail.error}>
                                            <div className="form-group float-label active">
                                                <input 
                                                    id="inputEmail"
                                                    name="email"
                                                    type="email" 
                                                    className="form-control" 
                                                    onChange={this.onInputTextChange('findEmail')}
                                                    value={this.state.findEmail}
                                                    required autoFocus 
                                                />
                                                <label htmlFor="inputEmail" className="form-control-label">Email address</label>
                                            </div>
                                            <Message once valid={findEmail} render={this.errorPrint} />
                                        </Validation>
                                    </div>
                                </div>                                    
                            </form>
                        </div>
                    </div>
                    <div className="col-12 mt-auto pb-4 mx-auto login-footer">                                                              
                        <div className="btn btn-lg btn-block btn-default position-relative" onClick={this.onSubmit}>
                            <span>메일 발송하기</span><Icon name='arrow right' className="right-absoute" />
                        </div>                        
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(findEmail);