import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	Header,
	Button,
	Grid,
	Segment,
	Table,
	Responsive,
	Icon,
	Modal,
	Form,
	TextArea,
	Dimmer,
	Loader,
	Divider,
} from 'semantic-ui-react';
import { PaginationRoundBtn } from 'components';
import Toastr from 'modern-toastr';
import payService from 'services/payments';
import * as moment from 'moment';
const queryString = require('query-string');

Toastr.setPosition('toast-top-center');

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

class OrderList extends Component {
	state = {
		total: 0,
		activePage: 1,
		pageSize: 10,
		payments: null,
		selectedItem: null,
		selectedIndex: 0,
		open: false,
		loading: false,
		used: null,
	};

	constructor(props) {
		super(props);

		if (this.props.location.search != null && this.props.location.search.trim() !== '') {
			const parsed = queryString.parse(this.props.location.search);
			const user = JSON.parse(window.localStorage.getItem('wordnet_user'));

			if (parsed.imp_success === 'true') {
				payService
					.payOrderComplete({
						imp_uid: parsed.imp_uid,
						merchant_uid: parsed.merchant_uid,
					})
					.then((data) => {
						// 가맹점 서버 결제 API 성공시 로직
						switch (data.status) {
							case 'success':
								// 결제 성공 시 로직
								payService
									.usedSuccess({
										merchant_uid: parsed.merchant_uid,
										user,
									})
									.then((result) => {
										//console.log(result);
										if (result.status === 'redirect') {
											this.props.history.push({
												pathname: '/order',
											});
										}
									});
								break;
							default:
								break;
						}
					})
					.catch((e) => {
						//결재 실패
						console.log('fail', e);
					});
			} else if (parsed.imp_success === 'false') {
				payService
					.payOrderFail({
						imp_uid: parsed.imp_uid,
						merchant_uid: parsed.merchant_uid,
						error_msg: parsed.error_msg,
					})
					.then((data) => {
						switch (data.status) {
							case 'success':
								this.props.history.push({ pathname: '/order' });
								break;
							default:
								break;
						}
					})
					.catch((e) => {
						console.log('fail', e);
					});
			}
		}
		this.state = {
			...this.state,
			fetching: false,
		};
	}

	componentDidMount() {
		//const user = JSON.parse(window.localStorage.getItem('wordnet_user'));
		const user = this.props.user;
		if (user) {
			this.setState({ user: this.props.user });
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ user: nextProps.user });
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.user != this.state.user) {
			const user = this.state.user;
			this.fetchUsed(user.id);
			this.fetchListAll({ ...this.state, offset: this.state.activePage, user });
		}
	}

	fetchUsed = (id) => {
		payService.isUsedService(id).then((data) => {
			//console.log(data.used);
			if (data.status) {
				this.setState({ used: data.used });
			}
		});
	};

	fetchListAll = (datas) => {
		//console.log(datas);
		payService.usedPaymentsList(datas).then((data) => {
			if (data) {
				let totalPages = Math.ceil(parseInt(data.total) / this.state.pageSize);
				this.setState({ totalPages: totalPages, payments: data.datas, total: data.total });
			}
		});
	};

	paginationChange = (e, { activePage }) => {
		//const user = JSON.parse(window.localStorage.getItem('wordnet_user'));
		const user = this.props.user;
		if (user) {
			this.setState({ activePage: activePage, selectedIndex: 0 });
			this.fetchListAll(Object.assign({}, this.state, { offset: activePage, user }));
		}
	};

	open = () => this.setState({ open: true });
	close = () => this.setState({ open: false });

	onClickModal = (e, item, index) => {
		this.setState({ selectedItem: item, open: true, selectedIndex: index + 1 });
	};

	onChangeReason = (e, data) => {
		this.setState({ [e.target.name]: data.value });
	};

	onPayCancel = (e) => {
		e.preventDefault();
		const { selectedItem, reason, selectedIndex, payments } = this.state;
		this.setState({ loading: true });
		payService
			.cancelPay({ merchant_uid: selectedItem.MERCHANT_UID, reason })
			.then((data) => {
				if (data.status === 'success' && selectedIndex > 0) {
					const newPayments = payments.map((item, key) => {
						if (key === selectedIndex - 1) {
							return data.item;
						}
						return item;
					});
					this.setState({ payments: newPayments, open: false, loading: false });
				} else {
					this.setState({ errorMsg: data.message, loading: false });
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({ errorMsg: '처리 불가', loading: false });
			});
	};

	render() {
		const { used, payments, activePage, totalPages, selectedItem, open, loading, errorMsg } = this.state;

		return (
			<div className="container my-5">
				<div className="row mb-4">
					<div className="col text-uppercase">
						<h4 className="mb-0">결제내역</h4>
					</div>
					<div className="col-auto align-self-end"></div>
				</div>
				<div className="row">
					<div className="col-12 mb-4">
						<div className="row">
							<div className="col">
								<div className="ui negative message">
									<ul className="list">
										<li className="content">
											결제 취소는 사용 이력이 없는 당일 결제 건에 한해 가능합니다.
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/*}
				<div className="row">
					<div className="col-12 mb-4">
						<div className="row">
							<div className="col">
								<div className="ui info message">
								{used &&
									<ul className="list">
										<li className="content">
											현재{' '}<span className='font-700'>{this.state.used.WordnetItem.NAME}</span>을 사용하고 있습니다.
										</li>
										<li className="content">
											서비스 기간:{' '}<span className='font-600'>{moment(this.state.used.START_DATE).format('YYYY-MM-DD')} ~ {moment(this.state.used.END_DATE).format('YYYY-MM-DD')}</span>
										</li>
									</ul>
								}
								{!used &&
									<ul className="list">
										<li className="content">현재 사용 중인 이용권이 없습니다.</li>
									</ul>
								}
								</div>
							</div>
						</div>
					</div>
				</div>
				*/}
				<div className="list-group list-group-flush my-0">
					<Grid>
						<Grid.Row style={{ marginLeft: '0px' }}>
							<Grid.Column>
								<Table
									size="small"
									key="black"
									fixed
									selectable
									singleLine
									textAlign="center"
									style={{ position: 'relative', padding: '0px', margin: '0px' }}>
									<Responsive as={Table.Header} minWidth={768}>
										<Table.Row>
											<Table.HeaderCell width="6">상품명</Table.HeaderCell>
											<Table.HeaderCell width="4">결제일시</Table.HeaderCell>
											<Table.HeaderCell width="3">상태</Table.HeaderCell>
											<Table.HeaderCell width="3" />
										</Table.Row>
									</Responsive>
									<Table.Body>
										{payments &&
											payments.map((item, index) => {
												let method = '';
												if (item.PG.indexOf('kcp') > -1) {
													if (item.PAY_METHOD.trim() === 'card') method = '카드';
													if (item.PAY_METHOD.trim() === 'phone') method = '휴대폰 소액결제';
													if (item.PAY_METHOD.trim() === 'payco') method = 'PAYCO';
												}
												if (item.PG.trim() === 'html5_inicis') {
													if (item.PAY_METHOD.trim() === 'samsung') method = '삼성페이';
												}
												if (item.PG.trim() === 'paypal') {
													method = 'PAYPAL';
												}
												if (item.PG.trim() === 'kakaopay') {
													method = '카카오페이';
												}
												return (
													<Table.Row key={index}>
														<Table.Cell textAlign="center">{item.NAME}</Table.Cell>
														<Table.Cell textAlign="center">
															{moment(item.UPDATE_DATE).format('YYYY-MM-DD HH:MM:SS')}
														</Table.Cell>
														<Table.Cell textAlign="center">{item.STATUS}</Table.Cell>
														<Table.Cell textAlign="center">
															{item.STATUS.trim() === '결제완료' && (
																<span
																	onClick={(e) => this.onClickModal(e, item, index)}>
																	<Icon size="tiny" name="times circle" /> 결제취소
																</span>
															)}
														</Table.Cell>
													</Table.Row>
												);
											})}
										{payments && payments.length === 0 && (
											<Table.Row>
												<Table.Cell colSpan="4">결제 내역이 존재하지 않습니다.</Table.Cell>
											</Table.Row>
										)}
									</Table.Body>
								</Table>
							</Grid.Column>
						</Grid.Row>
						{/*
						<Grid.Row style={{ marginLeft: '0px' }}>
							<Grid.Column textAlign="center">
								{payments && payments.length > 0 && (
									<Pagination
										activePage={activePage}
										ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
										firstItem={{ content: <Icon name="angle double left" />, icon: true }}
										lastItem={{ content: <Icon name="angle double right" />, icon: true }}
										prevItem={{ content: <Icon name="angle left" />, icon: true }}
										nextItem={{ content: <Icon name="angle right" />, icon: true }}
										boundaryRange={0}
										siblingRange={2}
										pointing
										secondary
										onPageChange={this.paginationChange}
										totalPages={totalPages}
									/>
								)}
							</Grid.Column>
						</Grid.Row>
								*/}
					</Grid>
				</div>
				<PaginationRoundBtn
					currentPage={activePage}
					totalPage={totalPages}
					onChangePage={this.paginationChange}
				/>
				<Modal open={open} onClose={this.close} size="small" className="custom-modal">
					<div className="pt-3 pl-3 text-13 font-600">
						<Icon name="payment" />
						<span>아래 주문을 취소하시겠습니까?</span>
					</div>
					<Divider />
					<div className="pl-3">
						{loading && (
							<Segment style={{ height: '200px' }}>
								<Dimmer active inverted>
									<Loader size="medium">처리중</Loader>
								</Dimmer>
							</Segment>
						)}
						{!loading && (
							<Fragment>
								{errorMsg && (
									<div className="alert alert-primary alert-light alert-dismissible" role="alert">
										<i className="color-primary zmdi zmdi-info" /> {errorMsg}
									</div>
								)}
								<div className="ui info message">
									<ul className="list">
										<li className="content">
											주문번호: {selectedItem && selectedItem.MERCHANT_UID}
										</li>
										<li className="content">상품명: {selectedItem && selectedItem.NAME}</li>
									</ul>
								</div>
								<Form>
									<TextArea
										name="reason"
										placeholder="취소 사유를 입력해주세요."
										style={{ fontSize: '13px' }}
										onChange={this.onChangeReason}
									/>
								</Form>
							</Fragment>
						)}
					</div>
					<div className="text-center mb-3">
						<Button color="red" style={{ fontSize: '15px' }} onClick={this.close} disabled={loading}>
							<Icon name="remove" /> 아니오
						</Button>
						<Button
							color="green"
							style={{ fontSize: '15px' }}
							onClick={this.onPayCancel}
							disabled={loading}>
							<Icon name="checkmark" /> 예
						</Button>
					</div>
				</Modal>
			</div>
		);
	}
}

export default connect(mapStateToProps, null)(withRouter(OrderList));
