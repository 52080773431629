import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Icon, Button, Form, Checkbox } from 'semantic-ui-react';
import Validator, { Validation } from 'es-validator';
import Toastr from 'modern-toastr';

import * as service from 'services/order';
import * as message from 'utils/message';

import payService from 'services/payments';

Toastr.setPosition('toast-top-center');

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

class OrderItems extends Component {
  constructor(props) {
    super(props);

    this.errorPrint = (v) => (
      <p key={v.key} className="help-block">
        {v.message}
      </p>
    );

    //유효성 검증
    this.validator = new Validator({
      isCheck: {
        _name: '동의',
        _hasText: { message: '주문 동의를 체크해 주세요' },
        _checked: {},
      },
      methods: {
        _name: '결제수단',
        _hasText: { message: '결제 수단을 선택해 주세요.' },
        _selected: {},
      },
    });

    this.state = {
      isCheck: false,
      methods: '',
      orderItems: [],
      orderPage: 1,
      selectedOrder: null,
    };
  }

  componentDidMount() {
    this.fetchGetOrderItems();
  }

  handleOnClickShowPaymentType(item) {
    const user = this.props.user;
    if (user !== undefined && user !== null) {
      payService.isUsedService(user.id).then((data) => {
        if (data.status) {
          Toastr.info(
            '현재 사용중인 이용권이 있습니다. \n사용중인 이용권의 이용기간이 끝난 후 결제가 가능합니다.'
          );
        } else {
          this.setState({
            orderPage: 2,
            selectedOrder: item,
          });
        }
      });
    } else {
      Toastr.info(message.MSG_MEMBER_SERVICE);
    }
  }

  handleOnClickGoBack = (e) => {
    e.preventDefault();
    this.setState({
      orderPage: 1,
      selectedOrder: null,
      isCheck: false,
      methods: '',
    });
  };

  requestPay = (vState) => {
    return new Promise((resolve) => {
      const IMP = window.IMP; // 생략해도 괜찮습니다.
      let localURL = window.location.protocol + '//' + window.location.host;
      let pg = '',
        pay_method = '',
        amount,
        m_redirect_url = localURL + '/pay/mobile';
      if (vState.methods === 'card' || vState.methods === 'phone') {
        IMP.init('imp24842693');
        pg = 'kcp';
        pay_method = vState.methods;
        amount = vState.selectedOrder.PRICE * vState.selectedOrder.PROPERTY_ID;
      } else if (vState.methods === 'payco') {
        IMP.init('imp24842693');
        //pg = 'kcp.IP0AC';
        pg = 'kcp.A52CY';
        pay_method = 'payco';
        amount = vState.selectedOrder.PRICE * vState.selectedOrder.PROPERTY_ID;
      } else if (vState.methods === 'kakaopay') {
        IMP.init('imp24842693');
        pg = 'kakaopay';
        pay_method = 'card';
        amount = vState.selectedOrder.PRICE * vState.selectedOrder.PROPERTY_ID;
      } else if (vState.methods === 'paypal') {
        IMP.init('imp24842693');
        pg = 'paypal';
        pay_method = 'card';
        amount = Math.ceil(
          (vState.selectedOrder.PRICE * vState.selectedOrder.PROPERTY_ID) / 1000
        );

        m_redirect_url = localURL + '/order';
      } else if (vState.methods === 'samsung') {
        IMP.init('imp24842693');
        pg = 'html5_inicis';
        pay_method = 'card';
        amount = vState.selectedOrder.PRICE * vState.selectedOrder.PROPERTY_ID;
      }

      const user = JSON.parse(window.localStorage.getItem('wordnet_user'));
      if (user != null) {
        payService
          .payOrderCreate({
            id: vState.selectedOrder.ITEM_ID,
            title: vState.selectedOrder.NAME + '_wordnet',
            amount: amount,
            type: vState.selectedOrder.TYPE,
            pg,
            pay_method,
            user,
          })
          .then((x) => {
            // "imp00000000" imp24842693 대신 발급받은 "가맹점 식별코드"를 사용합니다.
            // IMP.request_pay(param, callback) 호출
            if (x) {
              if (vState.selectedOrder != null) {
                IMP.request_pay(
                  {
                    // param kcp: samsung, phone, card
                    pg,
                    pay_method,
                    merchant_uid: x.merchant_uid,
                    name: vState.selectedOrder.NAME + '_wordnet',
                    amount: amount,
                    buyer_email: user.userEmail,
                    buyer_name: user.userName,
                    buyer_tel: '',
                    buyer_addr: '',
                    buyer_postcode: '',
                    m_redirect_url,
                  },
                  (rsp) => {
                    // callback
                    if (rsp.success) {
                      // 결제 성공 시 로직,
                      payService
                        .payOrderComplete({
                          imp_uid: rsp.imp_uid,
                          merchant_uid: rsp.merchant_uid,
                        })
                        .then((data) => {
                          // 가맹점 서버 결제 API 성공시 로직
                          //console.log(data);
                          switch (data.status) {
                            case 'success':
                              // 결제 성공 시 로직
                              payService
                                .usedSuccess({
                                  merchant_uid: rsp.merchant_uid,
                                  user,
                                })
                                .then((result) => {
                                  //console.log(result);
                                  if (result.status === 'redirect') {
                                    this.props.history.push({
                                      pathname: '/order',
                                    });
                                  }
                                });
                              break;
                            default:
                              break;
                          }
                        })
                        .catch((e) => {
                          //결재 실패
                          console.log('fail', e);
                        });
                    } else {
                      // 결제 실패 시 로직,
                      console.log('fail', rsp);
                      this.setState({ error_msg: rsp.error_msg });

                      payService
                        .payOrderFail({
                          imp_uid: rsp.imp_uid,
                          merchant_uid: rsp.merchant_uid,
                          error_msg: rsp.error_msg,
                        })
                        .then((data) => {
                          switch (data.status) {
                            case 'success':
                              break;
                            default:
                              break;
                          }
                        })
                        .catch((e) => {
                          console.log('fail', e);
                        });
                    }
                  }
                );
              } else {
                this.setState({
                  error_msg:
                    '사용자 정보를 찾을 수 없습니다. 다시 로그인 해주세요.',
                });
              }
            }

            Promise.resolve();
          })
          .catch((error) => {
            Promise.resolve();
          });
      } else {
      }
      return Promise.resolve();
    });
  };

  onChangeCheckedToArray = (e, { value }) => {
    this.setState({ methods: value });
  };

  onChangeCheckedToBoolean = (e, { checked }) => {
    this.setState({ isCheck: checked });
  };

  fetchGetOrderItems = async () => {
    let orderItems = await service.selectOrderItems();
    //console.log(orderItems);
    if (orderItems !== undefined && orderItems !== null) {
      if (orderItems.status === 200) {
        this.showOrderItems(orderItems.data);
      } else {
        this.setState({
          resultData: [],
        });
      }
    } else {
      Toastr.error(message.MSG_HAPPEN_TO_LOAD_ERROR);
    }
  };

  showOrderItems(result) {
    this.setState({
      orderItems: result.orderItems,
    });
  }

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  onSubmit(e) {
    e.preventDefault();
    const { methods, isCheck } = this.state;
    if (methods && isCheck) {
      this.requestPay(this.state);
    } else {
      if (!methods) {
        Toastr.error('결제수단을 선택해 주세요.');
      }
      if (!isCheck) {
        Toastr.error('주문동의를 체크해 주세요.');
      }
    }
  }

  render() {
    const { orderItems, orderPage, selectedOrder, methods, isCheck } =
      this.state;
    return (
      <div className="container my-5">
        <div className="row mb-4">
          <div className="col text-uppercase">
            <h4 className="mb-0">이용권 구매</h4>
          </div>
          <div className="col-auto align-self-end"></div>
        </div>
        <div className="row">
          <div className="col-12 mb-4">
            <div className="row">
              <div className="col">
                <div className="ui negative message">
                  <ul className="list">
                    <li className="content">
                      모든 이용권은 부가세(10%)가 포함되어 있습니다.
                    </li>
                    <li className="content">
                      결제 취소는 당일 결제 건만 가능합니다. <br />
                      (단, 사용이력이 없는 경우에 한함.)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {orderPage === 1 && (
          <div className="row">
            {orderItems.length > 0 &&
              orderItems
                .filter((x) => x.ITEM_ID !== 6)
                .map((item, i) => {
                  return (
                    <div className="col-12 col-md-4" key={i}>
                      <div className="card mb-4 overflow-hidden bg-template">
                        <div className="card-body">
                          <span className="h4 mb-2 font-weight-normal">
                            {item.NAME}
                          </span>
                          <div className="text-mute mt-7 text-center">
                            <span className="text-27 font-700">
                              &#8361; {this.numberWithCommas(item.PRICE)}
                            </span>
                            <span>/월</span>
                          </div>
                          <div className="mt-1 mb-0 text-center">
                            <span className="text-09">
                              총 결제금액{' '}
                              {this.numberWithCommas(
                                item.PRICE * item.PROPERTY_ID
                              )}{' '}
                              원
                            </span>
                          </div>
                          <div className="row mt-1 mb-1">
                            <div className="col"></div>
                            <div className="col-auto cursor-pointer">
                              <span
                                className="tag"
                                onClick={() =>
                                  this.handleOnClickShowPaymentType(item)
                                }>
                                구매하기
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        )}
        {orderPage === 2 && (
          <div className="row">
            {/*}
                        <div className="ui info icon message">
                            <i aria-hidden="true" className="info circle"></i>
                            <div className="content">
                                <p>결제 금액을 확인하고 결제 수단을 선택 후 결제를 진행해 주세요.</p>
                            </div>
                        </div>
                        */}
            <div className="col-12 mb-2">
              <div className="row">
                <div className="col">
                  <div className="alert alert-secondary" role="alert">
                    <h4 className="alert-heading">결제정보</h4>
                    <div className="row mt-2">
                      <div className="col">
                        <span>{selectedOrder.NAME}</span>
                      </div>
                      <div className="col-auto cursor-pointer">
                        <span className="badge badge-warning">
                          &#8361;{' '}
                          {this.numberWithCommas(
                            selectedOrder.PRICE * selectedOrder.PROPERTY_ID
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mb-2">
              <div className="row">
                <div className="col">
                  <div className="alert alert-secondary" role="alert">
                    <h4 className="alert-heading">결제수단</h4>
                    <Form>
                      <div className="row mt-2">
                        <div className="col">
                          <span className="badge badge-info">신용카드</span>
                          <Form.Group className="mt-1 ml-2">
                            <Form.Radio
                              name="methods"
                              label="신용카드"
                              value="card"
                              checked={methods === 'card'}
                              onChange={this.onChangeCheckedToArray}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <hr className="mt-0 mb-0" />
                      <div className="row mt-2">
                        <div className="col">
                          <span className="badge badge-info">간편결제</span>
                          <Form.Group inline className="mt-1 ml-2">
                            <Form.Radio
                              name="methods"
                              label="카카오페이"
                              value="kakaopay"
                              checked={methods === 'kakaopay'}
                              onChange={this.onChangeCheckedToArray}
                            />
                            <Form.Radio
                              name="methods"
                              label="PAYCO"
                              value="payco"
                              checked={methods === 'payco'}
                              onChange={this.onChangeCheckedToArray}
                            />
                            <Form.Radio
                              name="methods"
                              label="PAYPAL"
                              value="paypal"
                              checked={methods === 'paypal'}
                              onChange={this.onChangeCheckedToArray}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mb-2">
              <Form>
                <Form.Field>
                  <Checkbox
                    name="isCheck"
                    label="위 상품의 구매 조건을 확인하였으며, 결제 진행에 동의합니다. (전자상거래법 제8조 제2항)"
                    checked={isCheck}
                    onChange={this.onChangeCheckedToBoolean}
                  />
                </Form.Field>
              </Form>
            </div>

            <div className="col-12 text-center">
              <Button
                className="mr-2"
                onClick={(e) => this.handleOnClickGoBack(e)}>
                <span className="mr-1">이전</span>
              </Button>
              <Button color="orange" onClick={(e) => this.onSubmit(e)}>
                <Icon name="payment" />
                <span className="mr-1">결제하기</span>
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(OrderItems);
