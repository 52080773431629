export const LOCAL_API_URL = '';
//export const LOCAL_API_URL = 'http://localhost2';
export const LOCAL_URL = 'https://m.wordnet.co.kr';
export const CLICK_DIC_SEARCH_BAR = 'SEARCH_DIC';

// AUTH
export const REGISTER = 'REGISTER';
export const FIND_USER = 'FIND_USER';
export const CERTIFY_EMAIL = 'CERTIFY_EMAIL';
export const LOGOUT = 'LOGOUT';
export const AUTHENTICATE_THE_USER = 'AUTHENTICATE_THE_USER';
export const NONAUTHENTICATE_THE_USER = 'NONAUTHENTICATE_THE_USER';
export const CHECK_USER = 'CHECK_USER';
export const CHANGE_PW = 'CHANGE_PW';

// COUPON
export const SHOW_COUPON_LIST = 'SHOW_COUPON_LIST';
export const SHOW_COUPON_LIST_PAGE = 'SHOW_COUPON_LIST_PAGE';

// RECOMMEND
export const RECOMMEND_USER = 'RECOMMEND_USER';