import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { validate } from 'actions';
import ReactGA from 'react-ga';

import ReduxThunk from 'redux-thunk';
import ScrollToTop from 'react-router-scroll-top';

import App from './App';
import reducers from './reducers';

import './index.css';

import * as serviceWorker from './serviceWorker';

ReactGA.initialize('UA-147070452-2');

const createStoreWithMiddleware = compose(applyMiddleware(ReduxThunk))(createStore);
const store = createStoreWithMiddleware(reducers);
const appElement = document.getElementById('root');
const USER = JSON.parse(window.localStorage.getItem('wordnet_user'));
const history = createBrowserHistory();

history.listen((location) => {
	ReactGA.set({ page: location.pathname }); // Update the user's current page
	ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

//새로 고침시 로그인 유지
if (USER != null) {
	//console.log('validate');
	ReactGA.set({ userId: USER.id });
	//store.dispatch(validate(USER));
}

const render = (Component) => {
	return ReactDOM.render(
		<Provider store={store}>
			<Router history={history}>
				<ScrollToTop>
					<Route path="/" component={Component} />
				</ScrollToTop>
			</Router>
		</Provider>,
		appElement
	);
};

render(App);

serviceWorker.unregister();

if (module.hot) {
	module.hot.accept('./App', () => {
		const NextApp = require('./App').default;
		render(NextApp);
	});
}
