import * as types from 'actions/ActionTypes';

const initialState = {
    couponGroupItem: null,
    couponStatus: '',
    activePage: 1
}

const couponInfo = function(state = initialState, action) {
    switch(action.type) {
        case types.SHOW_COUPON_LIST:
            return {
                couponGroupItem: action.couponGroupItem,
                activePage: action.activePage                
            }
        case types.SHOW_COUPON_LIST_PAGE:
            return {
                couponStatus: action.couponStatus,
                activePage: action.activePage                
            }
        default:
            return state;
    }
}

export default couponInfo;