import React, { Fragment, Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, withRouter, Switch } from 'react-router-dom';
import { Loader, Sidebar, Footer, Header, Searchbar } from './components';
import Home from './pages/home';
import { DicContainer, CompareSyn } from './pages/dictionary';
import {
  Login,
  Join,
  FindEmail,
  AskCertEmail,
  ChangeCode,
  CertifyEmail,
  UserInfo,
  ChangePassword,
  Withdraw,
} from './pages/login';
import { AskUs } from './pages/qna';
import { NoticeList, MakeIcon } from './pages/notice';
import Company from './pages/company';
import Recommend from './pages/recommend';
import { UsedList } from './pages/usage';
import { OrderItems, OrderList } from './pages/payment';
import { UsedList as TestUsedList } from './pages/TestUsage';
import {
  OrderItems as TestOrderItems,
  OrderList as TestOrderList,
} from './pages/TestPayment';
import { CouponList, CouponGift } from './pages/coupon';
import { MgrUser, MgrUserDetail, MgrUserDetailOrder } from './pages/system';
import {
  MgrCoupon,
  MgrCouponCreate,
  MgrCouponList,
  MgrCouponDetail,
} from './pages/system';
import { MgrPayment, MgrPaymentDetail } from './pages/system';
import { MgrStatistic } from './pages/system';
import RequireAuth from 'components/auth/require_auth';
import RequireAuthTest from 'components/auth/require_auth_test';
import * as actions from 'actions';
/*
import { isMobile } from 'react-device-detect';
import { Route, withRouter } from 'react-router-dom';
import General from './pages/home/general';
import Mobile from './pages/home/mobile';
*/

import './App.css';
import 'modern-toastr/dist/modern-toastr.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchWord: '',
      currentUrl: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const url = prevProps.history.location.pathname;
    if (this.state.currentUrl !== url) {
      this.setState({
        currentUrl: url,
      });
    }
  }

  setSearchWord = (word) => {
    this.props.searchDicPyojaeInfo(word, 1);
    this.props.history.push({ pathname: '/dictionary' });
  };

  render() {
    const { currentUrl } = this.state;
    return (
      <Fragment>
        <Loader />
        <Sidebar />

        <div className="wrapper">
          <Header />
          <Searchbar setSearchWord={this.setSearchWord} />

          <Switch>
            <Route exact path="/" component={RequireAuth(Home)} />
            <Route path="/home" component={RequireAuth(Home)} />
            <Route exact path="/changeCode" component={ChangeCode} />
            <Route exact path="/certifyEmail" component={CertifyEmail} />
            <Route path="/dictionary" component={RequireAuth(DicContainer)} />
            <Route path="/login" component={Login} />
            <Route path="/join" component={Join} />
            <Route path="/find" component={FindEmail} />
            <Route path="/recertify" component={AskCertEmail} />
            <Route path="/qna" component={AskUs} />
            <Route path="/recommend" component={RequireAuth(Recommend)} />
            <Route exact path="/notice" component={NoticeList} />
            <Route path="/notice/MakeIcon" component={MakeIcon} />
            <Route path="/company" component={Company} />
            {}
            <Route
              path="/payment"
              component={RequireAuthTest(OrderItems, TestOrderItems)}
            />
            <Route
              path="/usage"
              component={RequireAuthTest(UsedList, TestUsedList)}
            />
            <Route
              path="/order"
              component={RequireAuthTest(OrderList, TestOrderList)}
            />
            <Route exact path="/coupon" component={RequireAuth(CouponList)} />
            <Route path="/coupon/Gift" component={RequireAuth(CouponGift)} />
            <Route path="/userInfo" component={RequireAuth(UserInfo)} />
            <Route path="/withdraw" component={RequireAuth(Withdraw)} />
            <Route path="/changePw" component={RequireAuth(ChangePassword)} />
            <Route path="/compare" component={CompareSyn} />

            <Route exact path="/mgrUser" component={MgrUser} />
            <Route path="/mgrUser/UserDetail/:id" component={MgrUserDetail} />
            <Route
              path="/mgrUser/OrderDetail/:id"
              component={MgrUserDetailOrder}
            />
            <Route exact path="/mgrPayment" component={MgrPayment} />
            <Route path="/mgrPayment/Detail" component={MgrPaymentDetail} />
            <Route exact path="/mgrCoupon" component={MgrCoupon} />
            <Route path="/mgrCoupon/Create" component={MgrCouponCreate} />
            <Route path="/mgrCoupon/Edit" component={MgrCouponCreate} />
            <Route exact path="/mgrCoupon/List" component={MgrCouponList} />
            <Route path="/mgrCoupon/List/Detail" component={MgrCouponDetail} />
            <Route exact path="/mgrStatistic" component={MgrStatistic} />
          </Switch>

          <Footer currentUrl={currentUrl} />
        </div>
      </Fragment>
    );
  }

  /*
    render() {
        if (!isMobile) {
            return (
                <Fragment>
                    <Route exact path="/" component={Mobile} />
                    <Route path="/mobile" component={Mobile} />
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Route exact path="/" component={Mobile} />
                    <Route path="/mobile" component={Mobile} />
                </Fragment>
            );
        }
    }
    */
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      searchDicPyojaeInfo: actions.searchDicPyojaeInfo,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(App));

//export default withRouter(App);
