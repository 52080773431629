import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { Label, Message, Button } from 'semantic-ui-react';
import { PaginationRoundBtn } from 'components';
import Toastr from 'modern-toastr';

import * as service from 'services/dictionary';
import * as actions from 'actions';

Toastr.setPosition('toast-top-center');

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

class Wordbook extends Component {
	constructor(props) {
		super(props);

		this.state = {
			resultData: [],
			currentPage: 1,
			pageSize: 10,
			totalPage: 0,
			totalElements: 0,
		};
	}

	componentDidMount() {
		const user = this.props.user;
		if (user) {
			this.fetchGetWordbookInfo(user.id, this.state.pageSize, this.state.currentPage);
			this.setState({ user: this.props.user });
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ user: nextProps.user });
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.currentPage !== prevState.currentPage) {
			const user = this.state.user;
			if (user !== undefined && user !== null) {
				this.fetchGetWordbookInfo(user.id, this.state.pageSize, this.state.currentPage);
			}
		}
	}

	handleOnClickWordbookItem(item) {
		this.props.searchDicPyojaeInfo(item.WORD, 0);
	}

	handleOnClickDeleteItem(item) {
		const user = this.state.user;
		if (user !== undefined && user !== null) {
			this.fetchDelWordbookItem(user.id, item.ID);
		}
	}

	fetchGetWordbookInfo = async (userId, pageSize, pageNum) => {
		let wordbookItems = await service.selectWordbookItems({
			userId: userId,
			pageSize: pageSize,
			pageNum: pageNum - 1,
		});

		if (wordbookItems !== null) {
			if (wordbookItems.status === 200) {
				if (wordbookItems.data.totalElements > 0) {
					this.showWordbookInfo(wordbookItems.data);
				} else {
					this.setState({
						resultData: [],
						totalPage: 0,
						totalElements: 0,
					});
				}
			} else {
				Toastr.error('단어장을 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
			}
		} else {
			Toastr.error('단어장을 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
		}
	};

	fetchDelWordbookItem = async (userId, itemId) => {
		let wordbookItem = await service.deleteWordbookItem({
			userId: userId,
			itemId: itemId,
		});

		if (wordbookItem !== null) {
			if (wordbookItem.status === 200) {
				Toastr.info(wordbookItem.data.message);
				this.fetchGetWordbookInfo(userId, this.state.pageSize, this.state.currentPage);
			} else {
				Toastr.error(wordbookItem.data.message);
			}
		} else {
			Toastr.error('데이터를 삭제하는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
		}
	};

	showWordbookInfo(wordbookItems) {
		this.setState({
			resultData: wordbookItems.wordBookItems,
			totalPage: wordbookItems.totalPages,
			totalElements: wordbookItems.totalElements,
		});
	}

	handleOnChangePage = (page) => {
		this.setState({
			currentPage: page,
		});
	};

	render() {
		const { resultData, currentPage, pageSize, totalPage, totalElements } = this.state;
		const { match } = this.props;
		return (
			<div className="container my-5">
				<div className="row mt-2 mb-2">
					<div className="col">
						<span>저장된 단어 목록</span>
						<Label size="tiny" className="ml-2" circular color={'grey'}>
							{' '}
							{totalElements}
						</Label>
					</div>
				</div>

				<div className="list-group list-group-flush my-0">
					{resultData.length === 0 && (
						<Message className="mt-3 ml-2 mr-2" negative>
							<Message.Header className="posi-relative">저장된 단어가 존재하지 않습니다.</Message.Header>
						</Message>
					)}

					{resultData.length > 0 &&
						resultData.map((item, index) => {
							return (
								<div className="list-group-item" key={index}>
									<div className="row">
										<div className="col pl-0 align-self-center">
											<Link
												to={{
													pathname: `${item.LINK_URL}${item.WORD_ID}`,
													state: {
														word: item.WORD,
														hanja: item.HANJA,
														linkUrl: `${item.LINK_URL}`,
													},
												}}
												onClick={() => this.handleOnClickWordbookItem(item)}>
												<span className="font-weight-normal">
													{item.WORD} {item.HANJA}{' '}
												</span>
											</Link>
										</div>
										<div className="col-auto align-self-end">
											<Button size="tiny" onClick={() => this.handleOnClickDeleteItem(item)}>
												삭제
											</Button>
										</div>
									</div>
								</div>
							);
						})}
				</div>

				<PaginationRoundBtn
					currentPage={currentPage}
					totalPage={totalPage}
					onChangePage={this.handleOnChangePage}
				/>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			searchDicPyojaeInfo: actions.searchDicPyojaeInfo,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Wordbook));
