export const SEARCH_OPT_USER_STATUS = {
    ALL: 0,
    FREE: 1,
	BASIC: 2,	
	properties: {
        0: { id: 0, label: '사용등급 - 전체', value: 0 },
        1: { id: 1, label: '무료', value: 1 },
		2: { id: 2, label: '기본', value: 2 },		
	},
};

export const SEARCH_OPT_USER_STATUS_LABEL = [
	SEARCH_OPT_USER_STATUS.ALL,
    SEARCH_OPT_USER_STATUS.FREE,
    SEARCH_OPT_USER_STATUS.BASIC    
];

export const SEARCH_OPT_COLNAME = {
    EMAIL: 0,
    USERNAME: 1,
	properties: {
        0: { id: 0, label: '이메일', value: 0 },
        1: { id: 1, label: '사용자명', value: 1 },		
	},
};

export const SEARCH_OPT_COLNAME_LABEL = [
	SEARCH_OPT_COLNAME.EMAIL,
    SEARCH_OPT_COLNAME.USERNAME
];

export const DETAIL_OPT_CHECK_EMAIL = {
    NONE: 0,
    CHECK: 1,
	properties: {
        0: { id: 0, label: '이메일인증', value: 0 },
        1: { id: 1, label: '인증완료', value: 1 },
	},
};

export const DETAIL_OPT_CHECK_EMAIL_LABEL = [
    DETAIL_OPT_CHECK_EMAIL.NONE,
	DETAIL_OPT_CHECK_EMAIL.CHECK,
];

export const DETAIL_OPT_USER_AUTH = {
    NONE: 0,
    USER: 1,
    ADMIN: 2,
    STAFF: 3,
    MANAGER: 4,
    NON_USER: 5,
    MEMBER: 6,
	properties: {  
        0: { id: 0, label: '사용자권한 변경', value: 0 },    
        1: { id: 1, label: 'USER', value: 1 },	
        2: { id: 2, label: 'ADMIN', value: 2 },	
        3: { id: 3, label: 'STAFF', value: 3 },	
        4: { id: 4, label: 'MANAGER', value: 4 },	
        5: { id: 5, label: 'NON_USER', value: 5 },	
        6: { id: 6, label: 'MEMBER', value: 6 },		
	},
};

export const DETAIL_OPT_USER_AUTH_LABEL = [
    DETAIL_OPT_USER_AUTH.NONE,
	DETAIL_OPT_USER_AUTH.USER,
    DETAIL_OPT_USER_AUTH.ADMIN,
    DETAIL_OPT_USER_AUTH.STAFF,
    DETAIL_OPT_USER_AUTH.MANAGER,
    DETAIL_OPT_USER_AUTH.NON_USER,
    DETAIL_OPT_USER_AUTH.MEMBER
];

export const ORDER_NONE = {
	id: 'none',
	title: '주문상품선택',
	value: 0
};

export const BASIC_ONE_WEEK = {
	id: 'basicOneWeek',
	title: '1주일 이용권',
	div: 'BASIC',
	method: 'ONE_WEEK',
	cateId: 1,
	value: 4,
	payments: '0원'	
};
export const BASIC_ONE_MONTH = {
	id: 'basicOneMonth',
	title: '1개월 이용권',
	div: 'BASIC',
	method: 'ONE_MONTH',
	cateId: 1,
	value: 1,
	payments: '3,800원'	
};
export const BASIC_THREE_MONTH = {
	id: 'basicThreeMonth',
	title: '3개월 이용권',
	div: 'BASIC',
	method: 'THREE_MONTH',
	cateId: 1,
	value: 2,
	payments: '2,500원'	
};
export const BASIC_ONE_YEAR = {
	id: 'basicOneYear',
	title: '12개월 이용권',
	div: 'BASIC',
	method: 'ONE_YEAR',
	cateId: 1,
	value: 3,
	payments: '1,800원'	
};

export const ORDER_TYPES = [
	ORDER_NONE,
    BASIC_ONE_MONTH,
    BASIC_THREE_MONTH,
    BASIC_ONE_YEAR    
];

export const COUPON_STATUS = {
	STANDBY: 1,
	USING: 2,
	COMPLETE: 3,
	PRESENT: 4,
	properties: {
		1: { id: 1, label: '미사용', value: 'STANDBY' },
		2: { id: 2, label: '사용중', value: 'USING' },
		3: { id: 3, label: '사용완료', value: 'COMPLETE' },
		4: { id: 4, label: '선물완료', value: 'PRESENT' },
	},
};

export const COUPON_STATUS_LABEL = [
    COUPON_STATUS.STANDBY,
    COUPON_STATUS.USING,
	COUPON_STATUS.COMPLETE,
	COUPON_STATUS.PRESENT
];


export const COUPON_TYPES = [	
    BASIC_ONE_WEEK,
    BASIC_ONE_MONTH,
    BASIC_THREE_MONTH,
    BASIC_ONE_YEAR   
];


export const ORDER_USED_STATUS = {
	START: 1,
	STANDBY: 2,
	FAIL: 3,
	CANCEL: 4,
	EXPIRED: 5,
	LOCK: 6,
	properties: {
		1: { id: 1, label: '시작', value: 'START' },
		2: { id: 2, label: '대기', value: 'STANDBY' },
		3: { id: 3, label: '실패', value: 'FAIL' },
		4: { id: 4, label: '취소', value: 'CANCEL' },
		5: { id: 5, label: '만료', value: 'EXPIRED' },
		6: { id: 6, label: '잠금', value: 'LOCK' },
	},
};

export const ORDER_USED_STATUS_LABEL = [
    ORDER_USED_STATUS.START,
    ORDER_USED_STATUS.STANDBY,
	ORDER_USED_STATUS.FAIL,
	ORDER_USED_STATUS.CANCEL,
	ORDER_USED_STATUS.EXPIRED,
	ORDER_USED_STATUS.LOCK
];