import * as types from 'actions/ActionTypes';

const initialState = {
    searchWord: '',
    searchPage: 1
}

const dicSearch = function(state = initialState, action) {
    switch(action.type) {
        case types.CLICK_DIC_SEARCH_BAR:
            return {
                searchWord: action.searchWord,
                searchPage: action.searchPage
            }
        default:
            return state;
    }
}

export default dicSearch;