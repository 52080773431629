import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Form,
  Table,
  Button,
  Responsive,
  Pagination,
  Grid,
  Segment,
  Icon,
  Input,
  Dropdown,
  Label,
  Popup,
} from 'semantic-ui-react';

import Toastr from 'modern-toastr';

import * as moment from 'moment';
import * as service from 'services/system';
import * as systemTypes from 'types/systemTypes';

Toastr.setPosition('toast-top-center');

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

class MgrPayment extends Component {
  constructor(props) {
    super(props);

    let searchOptCol = systemTypes.SEARCH_OPT_COLNAME_LABEL.map((key) => {
      let item = systemTypes.SEARCH_OPT_COLNAME.properties[key];
      return {
        key: item.id,
        text: item.label,
        value: item.value,
        style: { fontSize: '0.9em' },
      };
    });

    this.state = {
      searchOptCol,
      userCol: '',
      searchName: '',
      resultData: [],
      currentPage: 1,
      pageSize: 10,
      totalPage: 0,
      totalElements: 0,
    };
  }

  componentDidMount() {
    const user = this.props.user;
    //console.log(this.props);
    if (user !== undefined && user !== null && user.grade === 0) {
      this.fetchSelectPaymentInfo({
        userCol: this.state.userCol,
        searchName: this.state.searchName,
        pageSize: this.state.pageSize,
        pageNum: this.state.currentPage,
      });
    }
  }

  handleOnClickCol = (e, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  searchOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.searchKeywordOnClick(e);
    }
  };

  searchKeywordOnClick = (e) => {
    e.preventDefault();
    this.searchKeywordHandler();
  };

  searchKeywordHandler = () => {
    const { pageSize, userCol, searchName, currentPage } = this.state;

    this.fetchSelectPaymentInfo({
      userCol: userCol,
      searchName: searchName,
      pageSize: pageSize,
      pageNum: currentPage,
    });
  };

  paginationChange = (e, { activePage }) => {
    const { pageSize, userCol, searchName } = this.state;
    this.setState({ currentPage: activePage });
    this.fetchSelectPaymentInfo({
      userCol: userCol,
      searchName: searchName,
      pageSize: pageSize,
      pageNum: activePage,
    });
  };

  onInputTextChange = (e, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  onClickDetail = (e, paymentItem) => {
    e.preventDefault();
    //console.log(userItem);
    let data = { paymentItem };
    this.props.history.push({ pathname: '/mgrPayment/Detail', post: data });
  };

  fetchSelectPaymentInfo = async (params) => {
    const paymentItems = await service.selectPaymentItems(params);
    //console.log(paymentItems);

    if (paymentItems !== null) {
      if (paymentItems.status === 200) {
        if (paymentItems.data) {
          this.showPaymentItems(paymentItems.data);
        } else {
          this.setState({
            resultData: [],
            totalPage: 0,
            totalElements: 0,
          });
        }
      } else {
        Toastr.error(paymentItems.data.message);
      }
    } else {
      Toastr.error(
        '결제 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.'
      );
    }
  };

  showPaymentItems(data) {
    const totalPage = Math.ceil(
      parseInt(data.totalCount) / this.state.pageSize
    );
    this.setState({
      totalElements: data.totalCount,
      totalPages: totalPage,
      resultData: data.datas,
    });
  }

  render() {
    const { resultData, currentPage, totalPages, totalElements } = this.state;
    const { searchName, searchOptCol, userCol } = this.state;

    return (
      <div className="container">
        <div className="row mb-4">
          <div className="col">
            <h4 className="mb-0">결제관리</h4>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 mb-4">
            <Form>
              <Grid>
                <Grid.Row style={{ marginLeft: '0px' }}>
                  <Grid.Column>
                    <Segment color="black" clearing>
                      <Form.Field inline>
                        <Form.Group widths={4} style={{ margin: 'auto' }}>
                          <Form.Field width={4}>
                            <Dropdown
                              selection
                              name="userCol"
                              options={searchOptCol}
                              placeholder="검색대상"
                              onChange={this.handleOnClickCol}
                              value={userCol}
                            />
                          </Form.Field>
                          <Form.Field width={8}>
                            <Input
                              name="searchName"
                              fluid
                              action={{
                                size: 'tiny',
                                color: 'grey',
                                children: '검색',
                                onClick: this.searchKeywordOnClick,
                              }}
                              icon="search"
                              placeholder="검색명"
                              value={searchName}
                              onKeyPress={this.searchOnKeyPress}
                              onChange={this.onInputTextChange}
                              iconPosition="left"
                            />
                          </Form.Field>
                        </Form.Group>
                      </Form.Field>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  style={{
                    marginLeft: '0px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  }}>
                  <Grid.Column>
                    <Grid columns="equal">
                      <Grid.Column>
                        <Button as="div" labelPosition="right">
                          <Button icon>검색결과</Button>
                          <Label as="a" basic pointing="left">
                            {totalElements}
                          </Label>
                        </Button>
                      </Grid.Column>
                      <Grid.Column textAlign="right"></Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ marginLeft: '0px' }}>
                  <Grid.Column>
                    <Table
                      key="black"
                      fixed
                      selectable
                      textAlign="center"
                      style={{
                        position: 'relative',
                        padding: '0px',
                        margin: '0px',
                      }}>
                      <Responsive as={Table.Header} minWidth={768}>
                        <Table.Row>
                          <Table.HeaderCell width="3">
                            주문번호
                          </Table.HeaderCell>
                          <Table.HeaderCell width="3">상품명</Table.HeaderCell>
                          <Table.HeaderCell width="1">결제</Table.HeaderCell>
                          <Table.HeaderCell width="1">주문</Table.HeaderCell>
                          <Table.HeaderCell width="1">사용</Table.HeaderCell>
                          <Table.HeaderCell width="1">환불</Table.HeaderCell>
                          <Table.HeaderCell width="2">이메일</Table.HeaderCell>
                          <Table.HeaderCell width="1">사용자</Table.HeaderCell>
                          <Table.HeaderCell width="2">결제일</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center" width={1} />
                        </Table.Row>
                      </Responsive>
                      <Table.Body>
                        {resultData &&
                          resultData.length > 0 &&
                          resultData.map((payment, index) => {
                            let pStatus = '',
                              oStatus = '',
                              uStatus = '',
                              refund = '';

                            switch (payment.P_STATUS.trim()) {
                              case '결제완료':
                                pStatus = '완료';
                                break;
                              case '결제대기':
                                pStatus = '대기';
                                break;
                              case '결제실패':
                                pStatus = '실패';
                                break;
                              case '결제취소':
                                pStatus = '취소';
                                break;
                              default:
                                pStatus = '';
                                break;
                            }

                            switch (payment.O_STATUS.trim()) {
                              case 'SUCCESS':
                                oStatus = '성공';
                                break;
                              case 'STANDBY':
                                oStatus = '대기';
                                break;
                              case 'FAIL':
                                oStatus = '실패';
                                break;
                              case 'CANCEL':
                                oStatus = '취소';
                                break;
                              default:
                                oStatus = '';
                                break;
                            }

                            switch (payment.U_STATUS.trim()) {
                              case 'START':
                                uStatus = '시작';
                                break;
                              case 'STANDBY':
                                uStatus = '대기';
                                break;
                              case 'FAIL':
                                uStatus = '실패';
                                break;
                              case 'CANCEL':
                                uStatus = '취소';
                                break;
                              case 'EXPIRED':
                                uStatus = '만료';
                                break;
                              case 'LOCK':
                                uStatus = '잠금';
                                break;
                              default:
                                uStatus = '';
                                break;
                            }

                            if (
                              payment.REFUND !== undefined &&
                              payment.REFUND !== null &&
                              payment.REFUND !== ''
                            ) {
                              refund = '환불';
                            }

                            return (
                              <Table.Row
                                key={'payment_' + index}
                                className="cursor-pointer">
                                <Table.Cell textAlign="left">
                                  {payment.MERCHANT_UID}
                                </Table.Cell>
                                <Table.Cell textAlign="left">
                                  {payment.NAME}
                                </Table.Cell>
                                <Table.Cell singleLine>{pStatus}</Table.Cell>
                                <Table.Cell>{oStatus}</Table.Cell>
                                <Table.Cell>{uStatus}</Table.Cell>
                                <Table.Cell>{refund}</Table.Cell>
                                <Table.Cell>{payment.USEREMAIL}</Table.Cell>
                                <Table.Cell>{payment.USERNAME}</Table.Cell>
                                <Table.Cell>
                                  {moment(payment.CREATE_DATE).format(
                                    'YYYY-MM-DD'
                                  )}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  <Popup
                                    content="상세보기"
                                    trigger={
                                      <Button
                                        icon
                                        circular
                                        compact
                                        onClick={(e) =>
                                          this.onClickDetail(e, payment)
                                        }>
                                        <Icon name="search plus" />
                                      </Button>
                                    }
                                  />
                                </Table.Cell>
                              </Table.Row>
                            );
                          })}
                        {(resultData === undefined ||
                          resultData === null ||
                          resultData.length === 0) && (
                          <Table.Row>
                            <Table.Cell colSpan="10">
                              결제 정보가 존재하지 않습니다.{' '}
                            </Table.Cell>
                          </Table.Row>
                        )}
                        <Table.Row>
                          <Table.Cell colSpan="10" className="text-center">
                            {resultData && resultData.length > 0 && (
                              <Pagination
                                activePage={currentPage}
                                ellipsisItem={{
                                  content: <Icon name="ellipsis horizontal" />,
                                  icon: true,
                                }}
                                firstItem={{
                                  content: <Icon name="angle double left" />,
                                  icon: true,
                                }}
                                lastItem={{
                                  content: <Icon name="angle double right" />,
                                  icon: true,
                                }}
                                prevItem={{
                                  content: <Icon name="angle left" />,
                                  icon: true,
                                }}
                                nextItem={{
                                  content: <Icon name="angle right" />,
                                  icon: true,
                                }}
                                boundaryRange={0}
                                siblingRange={2}
                                pointing
                                secondary
                                onPageChange={this.paginationChange}
                                totalPages={totalPages}
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(withRouter(MgrPayment));
