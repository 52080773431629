import { combineReducers } from 'redux';

import dicSearch from './dicSearch';
import auth from './auth';
import couponInfo from './coupon';

const reducers = combineReducers({	
	auth,
	dicSearch,
	couponInfo
});

export default reducers;