import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as actions from 'actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const mapDispatchToProps = (dispatch) => ({
	validate: (user) => dispatch(actions.validate(user)),
	logout: () => dispatch(actions.logout()),
});

export default (ComposedComponent) => {
	// If user not authenticated render out to root

	class Authentication extends Component {
		_isMounted = false;

		static contextTypes = {
			router: PropTypes.object,
		};

		state = {
			authenticated: false,
		};

		componentDidMount() {
			this._isMounted = true;
			this.setState({
				authenticated: this.props.authenticated,
			});
		}

		componentWillMount() {
			const USER = JSON.parse(window.localStorage.getItem('wordnet_user'));
			if (USER != null) {
				//console.log('ComposedComponent');
				this.props.validate(USER);
			}
		}

		componentWillReceiveProps(nextProps) {
			//console.log(nextProps.authenticated);
			this.setState({ authenticated: nextProps.authenticated });
		}

		componentWillUnmount() {
			this._isMounted = false;
		}

		render() {
			if (this._isMounted) {
				return <ComposedComponent {...this.props} />;
			} else {
				return null;
			}
		}
	}

	function mapStateToProps(state) {
		return { authenticated: state.auth.authenticated };
	}

	return connect(mapStateToProps, mapDispatchToProps)(withRouter(Authentication));
};
