import * as types from 'actions/ActionTypes';

const initialState = {
	authenticated: false,
	emailAuthenticated: false,
	registered: false,
	user: null,
	checkUser: false,
	changed: false,
	recommendUser: '',
	recommendType: ''
};

const auth = (state = initialState, action) => {
	//console.log(action);
	switch (action.type) {
		case types.AUTHENTICATE_THE_USER:
			return { authenticated: action.authenticated, user: action.user };
		case types.NONAUTHENTICATE_THE_USER:
			return { authenticated: action.authenticated, user: null };
		case types.LOGOUT:
			return { authenticated: action.authenticated, user: null };
		case types.REGISTER:
			return { registered: action.registered };
		case types.FIND_USER:
			return { authenticated: action.authenticated };
		case types.CERTIFY_EMAIL:
			return { emailAuthenticated: action.emailAuthenticated };
		case types.CHECK_USER:
			return { checkUser: action.checkUser };
		case types.CHANGE_PW:
			return { changed: action.changed };
		case types.RECOMMEND_USER:
			return { recommendUser: action.recommendUser, recommendType: action.recommendType };
		default:
			return state;
	}
};

export default auth;