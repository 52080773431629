import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";

import { Icon } from 'semantic-ui-react';

class AskList extends Component {
    
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container">

            </div>
        )
    }
}

export default AskList;