import request from 'superagent';
import Toastr from 'modern-toastr';
import 'modern-toastr/dist/modern-toastr.css';

import * as types from './ActionTypes';
import * as commonUtil from 'utils/common';

Toastr.setPosition('toast-top-center');

export const loginLocal = (user) => {
	return (dispatch) =>
		request('POST', types.LOCAL_API_URL + '/api/user/login')
			.set('baseURL', types.LOCAL_API_URL)
			.set('X-Requested-With', 'XMLHttpRequest')
			.set('Content-Type', 'application/json')
			.send({
				email: user.email,
				password: user.password,
			})
			.type('json')
			.withCredentials()
			.end(function (err, res) {
				if (res && res.ok) {
					const data = res.body;
					const user = data.user;
					//console.log('loginLocal', user);
					window.parent.postMessage({ loginStatus: true, user }, '*');
					window.localStorage.setItem('wordnet_user', JSON.stringify(user));
					commonUtil.setLoginEmail(user.userEmail);

					dispatch({
						type: types.AUTHENTICATE_THE_USER,
						authenticated: true,
						user: {
							...user,
							status: user.status,
							item: user.item,
						},
					});
				} else {
					window.localStorage.removeItem('wordnet_user');
					window.parent.postMessage({ loginStatus: false }, '*');
					dispatch({ type: types.NONAUTHENTICATE_THE_USER, authenticated: false, user: null });
					if (res.body.error) {
						Toastr.error(res.body.message);
					}
				}
			});
};

export const logout = () => {
	window.localStorage.removeItem('wordnet_user');
	window.parent.postMessage({ loginStatus: false }, '*');
	return (dispatch) =>
		request('GET', types.LOCAL_API_URL + '/api/user/logout')
			.set('baseURL', types.LOCAL_API_URL)
			.set('X-Requested-With', 'XMLHttpRequest')
			.set('Content-Type', 'application/json')
			.type('json')
			.withCredentials()
			.end(function (err, res) {
				console.log('test', res);
				dispatch({ type: types.NONAUTHENTICATE_THE_USER, authenticated: false, user: null });

				//	window.location.href = '/';
			});
};

export const join = (user) => {
	return (dispatch) =>
		request('POST', types.LOCAL_API_URL + '/api/user/join')
			.set('baseURL', types.LOCAL_API_URL)
			.set('X-Requested-With', 'XMLHttpRequest')
			.set('Content-Type', 'application/json')
			.send({
				name: user.name,
				phone: user.phone,
				email: user.email,
				password: user.password,
				recommendUserId: user.recommendUserId,
				recommendUser: user.recommendUser,
				recommendType: user.recommendType,
			})
			.type('json')
			.withCredentials()
			.end(function (err, res) {
				const data = res.body;
				if (res.ok) {
					Toastr.success('가입하신 메일로 인증메일을 보내드렸습니다. 확인 후 로그인해 주세요.');
					dispatch({ type: types.REGISTER, registered: true });
				} else {
					dispatch({ type: types.NONAUTHENTICATE_THE_USER, authenticated: false });
					if (data.error) {
						Toastr.error(data.message);
					}
				}
			});
};

export const find = (user) => {
	return (dispatch) =>
		request('POST', types.LOCAL_API_URL + '/api/user/find')
			.set('baseURL', types.LOCAL_API_URL)
			.set('X-Requested-With', 'XMLHttpRequest')
			.set('Content-Type', 'application/json')
			.send({ email: user.email })
			.type('json')
			.withCredentials()
			.end(function (err, res) {
				const data = res.body;
				if (res.ok) {
					Toastr.info(
						user.email +
							' 로 비밀번호 변경을 위한 접속주소를 보냈습니다. 해당 주소 클릭 후 비밀번호를 변경해 주세요.'
					);
					dispatch({ type: types.NONAUTHENTICATE_THE_USER, authenticated: false });
				} else {
					dispatch({ type: types.NONAUTHENTICATE_THE_USER, authenticated: false });
					if (data.error) {
						Toastr.error(data.message);
					}
				}
			});
};

export const updateUserInfo = (user) => {
	//console.log(user);
	return (dispatch) =>
		request('POST', types.LOCAL_API_URL + '/api/user/updateUserInfo')
			.set('baseURL', types.LOCAL_API_URL)
			.set('X-Requested-With', 'XMLHttpRequest')
			.set('Content-Type', 'application/json')
			.send(user)
			.type('json')
			.withCredentials()
			.end(function (err, res) {
				const data = res.body;
				if (res.ok) {
					Toastr.info('비밀번호 변경이 완료되었습니다. 다시 로그인해 주시기 바랍니다.');

					window.localStorage.removeItem('wordnet_user');
					window.parent.postMessage({ loginStatus: false }, '*');
					dispatch({ type: types.NONAUTHENTICATE_THE_USER, authenticated: false });
					dispatch({ type: types.CHANGE_PW, changed: true });
				} else {
					dispatch({ type: types.CHANGE_PW, changed: false });
					if (data.error) {
						Toastr.error(data.message);
					}
				}
			});
};

export const certify = (user) => {
	//console.log(user);
	return (dispatch) =>
		request('POST', types.LOCAL_API_URL + '/api/user/certify')
			.set('baseURL', types.LOCAL_API_URL)
			.set('X-Requested-With', 'XMLHttpRequest')
			.set('Content-Type', 'application/json')
			.send({ email: user.email })
			.type('json')
			.withCredentials()
			.end(function (err, res) {
				const data = res.body;
				if (res.ok) {
					Toastr.info(user.email + ' 로 이메일 인증을 위한 메일을 보냈습니다.');
					dispatch({ type: types.CERTIFY_EMAIL, emailAuthenticated: false });
				} else {
					dispatch({ type: types.CERTIFY_EMAIL, emailAuthenticated: false });
					if (data.error) {
						Toastr.error(data.message);
					}
				}
			});
};

export const confirm = (user) => {
	return (dispatch) =>
		request('POST', types.LOCAL_API_URL + '/api/user/confirm')
			.set('baseURL', types.LOCAL_API_URL)
			.set('X-Requested-With', 'XMLHttpRequest')
			.set('Content-Type', 'application/json')
			.send({ secretId: user.secretId, secretKey: user.secretKey })
			.type('json')
			.withCredentials()
			.end(function (err, res) {
				const data = res.body;
				if (res.ok) {
					Toastr.info(data.message);
					dispatch({ type: types.CERTIFY_EMAIL, emailAuthenticated: true });
				} else {
					dispatch({ type: types.CERTIFY_EMAIL, emailAuthenticated: false });
					if (data.error) {
						Toastr.error(data.message);
					}
				}
			});
};

export const change = (user) => {
	return (dispatch) =>
		request('POST', types.LOCAL_API_URL + '/api/user/change')
			.set('baseURL', types.LOCAL_API_URL)
			.set('X-Requested-With', 'XMLHttpRequest')
			.set('Content-Type', 'application/json')
			.send({ secretId: user.secretId, secretKey: user.secretKey, password: user.password })
			.type('json')
			.withCredentials()
			.end(function (err, res) {
				const data = res.body;
				if (res.ok) {
					Toastr.info('비밀번호 변경이 완료되었습니다. 다시 로그인해 주시기 바랍니다.');
					dispatch({ type: types.CHANGE_PW, changed: true });
				} else {
					dispatch({ type: types.CHANGE_PW, changed: false });
					if (data.error) {
						Toastr.error(data.message);
					}
				}
			});
};

export const setChangedPw = (changed) => ({
	type: types.CHANGE_PW,
	changed: changed,
});

export const setEmailAuthenticated = (changed) => ({
	type: types.CERTIFY_EMAIL,
	emailAuthenticated: changed,
});

export const validate = (user) => {
	return (dispatch) =>
		request('POST', types.LOCAL_API_URL + '/api/user/validate')
			.set('baseURL', types.LOCAL_API_URL)
			.set('X-Requested-With', 'XMLHttpRequest')
			.set('Content-Type', 'application/json')
			.send(user)
			.type('json')
			.withCredentials()
			.end(function (err, res) {
				if (res && res.ok) {
					const data = res.body;
					const re_user = data.user;

					if (re_user) {
						dispatch({
							type: types.AUTHENTICATE_THE_USER,
							authenticated: true,
							user: {
								...re_user,
								status: data.status,
								item: data.item,
							},
						});
					} else {
						dispatch({
							type: types.AUTHENTICATE_THE_USER,
							authenticated: false,
							user: null,
						});
					}
				}
			});
};

export const searchDicPyojaeInfo = (searchWord, searchPage) => ({
	type: types.CLICK_DIC_SEARCH_BAR,
	searchWord: searchWord,
	searchPage: searchPage,
});

export const externalLink = (link) => {
	return (dispatch) =>
		request('POST', types.LOCAL_API_URL + '/api/dic/search/externalLink')
			.set('baseURL', types.LOCAL_API_URL)
			.set('X-Requested-With', 'XMLHttpRequest')
			.set('Content-Type', 'application/json')
			.send(link)
			.type('json')
			.withCredentials()
			.end(function (err, res) {
				const data = res.body;
				if (res.ok) {
				} else {
				}
			});
};

export const setCouponGroupItem = (item, activePage) => ({
	type: types.SHOW_COUPON_LIST,
	couponGroupItem: item,
	activePage: activePage,
});

export const setCouponListActivePage = (activePage, status) => ({
	type: types.SHOW_COUPON_LIST_PAGE,
	activePage: activePage,
	couponStatus: status,
});

export const setRecommendUserInfo = (recommendUser, recommendType) => ({
	type: types.RECOMMEND_USER,
	recommendUser: recommendUser,
	recommendType: recommendType,
});
