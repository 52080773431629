import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { Icon } from 'semantic-ui-react';
import Toastr from 'modern-toastr';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import * as actions from 'actions';

Toastr.setPosition('toast-top-center');

let mapStateToProps = state => {
	return {
		authenticated: state.auth.emailAuthenticated,
	};
};

const mapDispatchToProps = dispatch => ({
    confirm: user => dispatch(actions.confirm(user)),
    confirming: confirmed => dispatch(actions.setEmailAuthenticated(confirmed))
});

class CertifyEmail extends Component {
    
    constructor(props) {
        super(props);

        const parsedSearch = queryString.parse(props.location.search); 
        
        if(parsedSearch.id === undefined || parsedSearch.key === undefined ||
           parsedSearch.id === null || parsedSearch.key === null ||
           parsedSearch.id === '' || parsedSearch.key === '') {

            Toastr.error("허가되지 않는 경로로 접근하였습니다. 로그인 후 이용해 주시기 바랍니다.");
            this.props.history.push("/login");
        }        

		this.state = {
            authenticated: props.authenticated,
            secretId: parsedSearch.id,
            secretKey: parsedSearch.key
		};      
    }

    static getDerivedStateFromProps(nextProps, prevState) {        
        if(nextProps.authenticated !== prevState.authenticated) {
            return { 
                authenticated: nextProps.authenticated               
            };
        }        
        return null;
    }

    onSubmit = (e) => {
        e.preventDefault();

        const { confirm } = this.props;

        confirm({ 
            secretId: this.state.secretId,
            secretKey: this.state.secretKey 
        });                     
    }

    render() {

        const { authenticated } = this.state;
        const { confirming } = this.props;
        
        if(authenticated) {
            confirming(false);
            return <Redirect to={'/login'} />
        }
        
        return (
            <div className="container my-5">   
                <div className="row mb-5">
                    <div className="col-12 align-self-center text-center">
                        <h5>이메일 확인</h5>
                    </div>
                </div>             
                <div className="row flex-colum">                    
                    <div className="col-12 mx-auto login-row">
                        <div className="align-self-center text-center">                            
                            <div className="ui negative message">
                                <h5>환영합니다.</h5><p/>
                                <ul className="list">                                        
                                    <li className="content">
                                        이메일 인증 페이지까지 오시느라 수고하셨습니다.
                                    </li>
                                    <li className="content">
                                        아래의 인증 확인 버튼을 누르시면 최종 확인 후 로그인 페이지로 이동합니다.
                                    </li>                                        
                                </ul>                                       
                            </div>                                
                        </div>
                    </div>
                    <div className="col-12 mt-auto mt-4 pb-4 mx-auto login-footer">                                                              
                        <div className="btn btn-lg btn-block btn-default position-relative" onClick={this.onSubmit}>
                            <span>이메일 인증 확인</span><Icon name='arrow right' className="right-absoute" />
                        </div>                        
                    </div>
                </div>
            </div>
        )
    }
}

CertifyEmail.propTypes = {
	authenticated: PropTypes.bool,
};

CertifyEmail.defaultProps = {
	authenticated: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(CertifyEmail);