import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Table, Responsive, Pagination, Icon } from 'semantic-ui-react';

import Toastr from 'modern-toastr';
import payService from 'services/payments';

import * as moment from 'moment';

Toastr.setPosition('toast-top-center');

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

class UsedList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			usedItem: null,
			usedItems: null,
			pageSize: 10,
			totalPages: 0,
			currentPage: 1,
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ user: nextProps.user });
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.user != this.state.user) {
			const user = this.state.user;
			this.fetchCurrentUsedItem(user.id);
			this.fetchSelectUsedItems({
				userId: user.id,
				pageSize: this.state.pageSize,
				pageNum: this.state.currentPage,
			});
		}
	}

	componentDidMount() {
		const user = this.props.user;
		if (user) {
			this.setState({ user: this.props.user });
		}
	}

	fetchCurrentUsedItem = (id) => {
		payService.isUsedService(id).then((data) => {
			//console.log(data.used);
			if (data.status) {
				this.setState({ usedItem: data.used });
			}
		});
	};

	fetchSelectUsedItems = (params) => {
		payService.usedList(params).then((data) => {
			//console.log(data);
			if (data) {
				let totalPages = Math.ceil(parseInt(data.total) / this.state.pageSize);
				this.setState({ totalPages: totalPages, usedItems: data.datas });
			}
		});
	};

	render() {
		const { usedItem, usedItems, currentPage, totalPages } = this.state;

		return (
			<div className="container">
				<div className="row mb-4">
					<div className="col">
						<h4 className="mb-0">사용내역</h4>
					</div>
				</div>
				<div className="row">
					<div className="col-12 mb-4">
						<div className="row">
							<div className="col">
								<div className="ui info message">
									{usedItem && (
										<ul className="list">
											<li className="content">
												현재 <span className="font-700">{usedItem.WordnetItem.NAME}</span>을
												사용하고 있습니다.
											</li>
											<li className="content">
												서비스 기간:{' '}
												<span className="font-600">
													{moment(usedItem.START_DATE).format('YYYY-MM-DD')} ~{' '}
													{moment(usedItem.END_DATE).format('YYYY-MM-DD')}
												</span>
											</li>
										</ul>
									)}
									{!usedItem && (
										<ul className="list">
											<li className="content">현재 사용 중인 이용권이 없습니다.</li>
										</ul>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-12 mb-4">
						<Grid>
							<Grid.Row style={{ marginLeft: '0px' }}>
								<Grid.Column>
									<Table
										key="black"
										fixed
										selectable
										textAlign="center"
										style={{ position: 'relative', padding: '0px', margin: '0px' }}>
										<Responsive as={Table.Header} minWidth={768}>
											<Table.Row>
												<Table.HeaderCell textAlign="center" width="2">
													상태
												</Table.HeaderCell>
												<Table.HeaderCell textAlign="center" width="2">
													종류
												</Table.HeaderCell>
												<Table.HeaderCell textAlign="center" width="4">
													사용명
												</Table.HeaderCell>
												<Table.HeaderCell textAlign="center" width="2">
													남은 일수
												</Table.HeaderCell>
												<Table.HeaderCell textAlign="center" width="2">
													사용일
												</Table.HeaderCell>
											</Table.Row>
										</Responsive>
										<Table.Body>
											{usedItems &&
												usedItems.map((item, key) => {
													const t = new Date();
													let remain = 0;

													if (item.STATUS.trim() === 'LOCK') {
														const theDate = new Date(item.LOCK_DATE);
														const diffDate = t - theDate;
														const lockRemain = Math.ceil(diffDate / (60 * 1000 * 60 * 24));
														const date = new Date(item.END_DATE);
														date.setDate(date.getDate() + lockRemain);
														const enddate = date;

														const diffDate2 = enddate - t;
														remain = Math.ceil(diffDate2 / (60 * 1000 * 60 * 24));
													} else {
														const enddate = new Date(item.END_DATE);
														const diffDate2 = enddate - t;
														remain = Math.ceil(diffDate2 / (60 * 1000 * 60 * 24));
													}

													return (
														<Table.Row key={'use_' + key} className="cursor-pointer">
															<Table.Cell textAlign="center">
																{item.STATUS.trim() === 'START' && <span>사용중</span>}
																{item.STATUS.trim() === 'LOCK' && <span>일시중지</span>}
																{item.STATUS.trim() === 'EXPIRED' && (
																	<span>사용만료</span>
																)}
															</Table.Cell>
															<Table.Cell textAlign="center">
																{item.WordnetOrder.MERCHANT_UID !== undefined &&
																	item.WordnetOrder.MERCHANT_UID !== null && (
																		<span>이용권</span>
																	)}
																{(item.WordnetOrder.MERCHANT_UID === undefined ||
																	item.WordnetOrder.MERCHANT_UID === null) && (
																	<span>쿠폰</span>
																)}
															</Table.Cell>
															<Table.Cell textAlign="center">
																{item.WordnetItem.NAME}
															</Table.Cell>
															<Table.Cell textAlign="center">{remain} 일</Table.Cell>
															<Table.Cell textAlign="center">
																{moment(item.UPDATE_DATE).format('YYYY-MM-DD')}
															</Table.Cell>
														</Table.Row>
													);
												})}
											{(usedItems === undefined ||
												usedItems === null ||
												usedItems.length === 0) && (
												<Table.Row>
													<Table.Cell colSpan="5">사용내역이 존재하지 않습니다. </Table.Cell>
												</Table.Row>
											)}
											<Table.Row>
												<Table.Cell colSpan="5" className="text-center">
													{usedItems && usedItems.length > 0 && (
														<Pagination
															activePage={currentPage}
															ellipsisItem={{
																content: <Icon name="ellipsis horizontal" />,
																icon: true,
															}}
															firstItem={{
																content: <Icon name="angle double left" />,
																icon: true,
															}}
															lastItem={{
																content: <Icon name="angle double right" />,
																icon: true,
															}}
															prevItem={{
																content: <Icon name="angle left" />,
																icon: true,
															}}
															nextItem={{
																content: <Icon name="angle right" />,
																icon: true,
															}}
															boundaryRange={0}
															siblingRange={2}
															pointing
															secondary
															onPageChange={this.paginationChange}
															totalPages={totalPages}
														/>
													)}
												</Table.Cell>
											</Table.Row>
										</Table.Body>
									</Table>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, null)(withRouter(UsedList));
