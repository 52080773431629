import React, { Component, Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import { SearchList, SearchDetail, Wordbook, History } from '../apps';
import { connect } from 'react-redux';
import * as actions from 'actions';
import RequireAuth from 'components/auth/require_auth';
class DicContainer extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<Route exact path={match.path} component={RequireAuth(SearchList)} />
				<Route path={`${match.path}/Search/:id`} component={RequireAuth(SearchDetail)} />
				<Route path="/dictionary/Wordbook" component={RequireAuth(Wordbook)} />
				<Route path="/dictionary/History" component={RequireAuth(History)} />
			</Fragment>
		);
	}
}

export default withRouter(DicContainer);
