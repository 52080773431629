import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Select, Button, Message, Grid, Divider, Input } from 'semantic-ui-react';
import Toastr from 'modern-toastr';

import * as moment from 'moment';
import * as service from 'services/system';
import * as systemTypes from 'types/systemTypes';

Toastr.setPosition('toast-top-center');

const mapStateToProps = state => ({
    user: state.auth.user
});

class MgrCouponCreate extends Component {
    
    constructor(props) {
        super(props);

        const state = {
			couponTypeItem: null,
			couponTypeId: 0,
			couponType: '',
			couponName: '',
			couponCount: 0,
			createCount: 0,
			note: '',
			chkOverlap: '',
			actionChkOverlap: false
		};

        let v = {
			couponType: {
				error: false,
				message: '쿠폰종류를 선택해 주세요.'
			},			
			couponName: {
				error: false,
				message: '쿠폰 종류명을 입력해 주세요.'				
			},
			chkOverlap: {
				error: false,
				message: '중복확인을 클릭해 주세요.'				
			}
		};

        let couponTypeOption = Object.keys(systemTypes.COUPON_TYPES).map(key => {
			let item = systemTypes.COUPON_TYPES[key];
			return {
				key: item.id,
				text: item.title,
				value: item.value,
				style: { fontSize: '0.9em' },
			};
        });
        
        this.state = {
			v,
            isWarning: false,
            couponTypeOption,
			...state			
		};
    }

    componentDidUpdate(prevProps, prevState) {
		if (this.state.isWarning !== prevState.isWarning) {
			this.validateHandler();
		}
    }
    
    typeHandler = (e, { name, value }) => {

		if (this.state.hasOwnProperty(name)) {		
			this.setState({ [name]: value });
		}
		this.setState({ isWarning: false });
	};

    dataHandler = (e, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {
			this.setState({ [name]: value });
		}
		this.setState({ isWarning: false });
    };
    
    validateHandler = () => {
		let v = this.state.v;

		Object.keys(v).map(key => {
			if (this.state.hasOwnProperty(key) && 
				this.state[key] && 
				this.state[key].toString().trim().length > 0) {
				v[key].error = false;
			} else {				
				v[key].error = true;				
			}

			return v[key];
		});

		let messages = Object.keys(v)
			.filter(key => v[key].error)
			.map(key => {
				return v[key].message;
			});

		if (messages.length > 0) {
			this.setState({ actionChkOverlap: false, v, isWarning: true, errors: messages });
			return false;
		} else {
			this.setState({ actionChkOverlap: false, v, isWarning: false, errors: null });
			return true;
		}
    };

    checkCouponOverlap = e => {
		e.preventDefault();

		if(this.state.couponName !== '') {
			let { couponName } = this.state;
			let datas = { couponName };
			this.fetchCheckCouponOverlap(datas);
		} else {
			this.setState({ actionChkOverlap: true, isWarning: true, errors: ['쿠폰명을 입력해 주세요.'] });
			return false;
		}
	}
    
    previousOnClick = e => {
		e.preventDefault();
		this.props.history.goBack();
	};

    createCouponOnKeyPress = e => {
		if (e.key === 'Enter') {
			this.createCouponOnSumbit(e);
		}
    };

    createCouponOnSumbit = e => {
        e.preventDefault();
        
        const user = this.props.user;

        if (this.validateHandler()) {
            if(user !== undefined && user !== null && user.grade === 0) {
                if(this.state.createCount !== '') {
                    let createCount = parseInt(this.state.createCount);

                    if (createCount > 0) {
                        let { couponType, couponName, note, createCount } = this.state;
                        let datas = { 
                            userId: user.id, 
                            couponType: couponType, 
                            couponName: couponName,
                            note: note,
                            createCount: createCount 
                        };

                        this.fetchCreateSubmit(datas);
                    } else {
                        Toastr.error('생성할 쿠폰 개수는 0보다 커야 합니다.');
                    }
                } else {
                    Toastr.error('생성할 쿠폰 개수를 입력해 주세요.');                
                }
            } else {
                Toastr.error('관리자 권한이 필요합니다.');   
            }
        }
	};
    
    fetchCheckCouponOverlap = async datas => {

		let result = await service.checkCouponGroupOverlap(datas);

		if (result && result.status === 200) {
			if(!result.data.error) {
				Toastr.info(result.data.message);
				this.setState({
					chkOverlap: 'T'
				});
			} else {
				Toastr.error(result.data.message);
				this.setState({
					chkOverlap: ''
				});
			}
		} else {
			if (result && result.status === 500) {
				Toastr.error(result.data.message);
				this.setState({
					chkOverlap: ''
				});
			} else {
				Toastr.error("중복 확인 중에 오류가 발생하였습니다.");
			}
		}
    }
    
    fetchCreateSubmit = async datas => {

        let result = await service.insertCouponGroupItem(datas);
        
		if (result && result.status === 200) {
			Toastr.info(result.data.message);	
			this.setState({
				couponCount: result.data.totalCount
			});		
		} else {
			if(result.data.message) {
				Toastr.error(result.data.message);
			}
		}
	};

    render() {
        const { v, isWarning, errors, couponTypeOption, couponType, couponCount, couponName, note, createCount } = this.state;

        return (
            <div className="container">
                <div className="row mb-4">
                    <div className='col'>
                        <h4 className="mb-0">신규쿠폰생성</h4>
                    </div>
                </div> 
                <div className='row mt-4'>
                    <div className='col-12 mb-4'>
                        <Form warning={isWarning} >                           
                            <Grid>
                                <Grid.Row style={{ marginLeft: '0px' }}>
                                    <Grid.Column>                                       
                                        <Form.Field required width={5} error={v.couponType.error}>
                                            <label>쿠폰종류</label>
                                            <Select
                                                name="couponType"
                                                fluid
                                                options={couponTypeOption}
                                                placeholder="쿠폰종류"
                                                style={{ fontSize: '0.9em' }}
                                                onChange={this.typeHandler}
                                                value={couponType}
                                            />
                                        </Form.Field>									
                                        
                                        <Form.Field required error={v.couponName.error}>
                                            <label>쿠폰 종류명</label>
                                            <Form.Group widths={2}>
                                                <Form.Field>
                                                    <Input
                                                        name="couponName"
                                                        fluid
                                                        placeholder="쿠폰 종류명"
                                                        action={{
                                                            color: 'teal',
                                                            children: '중복확인',
                                                            secondary: true,
                                                            onClick: this.checkCouponOverlap,
                                                        }}
                                                        onChange={this.dataHandler}
                                                        value={couponName}
                                                    />
                                                </Form.Field>
                                            </Form.Group>
                                        </Form.Field>

                                        <Form.Field>
                                            <label>특이사항</label>										
                                            <Form.Group widths={2}>
                                                <Form.Input
                                                    name="note"
                                                    fluid
                                                    width={8}
                                                    onChange={this.dataHandler}
                                                    value={note}
                                                />
                                            </Form.Group>
                                        </Form.Field>
                                                                            
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row style={{ marginLeft: '0px' }}>
                                    <Grid.Column>
                                        <Divider />
                                        <Form.Field >										
                                            <Form.Group widths={4}>
                                                <Form.Input
                                                    icon="barcode"
                                                    label='생성된 쿠폰 총개수'
                                                    name="couponCount"
                                                    value={couponCount}
                                                    iconPosition="left"
                                                    readOnly
                                                />
                                                <Form.Input
                                                    icon="barcode"
                                                    label='생성할 쿠폰개수'
                                                    name="createCount"
                                                    value={createCount}
                                                    onChange={this.dataHandler}
                                                    onKeyPress={this.createCouponOnKeyPress}
                                                    iconPosition="left"
                                                />
                                            </Form.Group>
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row style={{ marginLeft: '0px' }}>
                                    <Grid.Column textAlign="center">
                                        <Button onClick={this.previousOnClick}>
                                            이전
                                        </Button>
                                        <Button color="teal" onClick={this.onSumbit}>
                                            수정
                                        </Button>
                                        <Button className='ml-2' color="teal" onClick={this.createCouponOnSumbit}>
                                            신규 생성
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </div>
                </div>     
            </div>
        )
    }
}

export default connect(mapStateToProps, null)(MgrCouponCreate);