import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Icon, Form, Grid, Card, TextArea, Button } from 'semantic-ui-react';

import Toastr from 'modern-toastr';

Toastr.setPosition('toast-top-center');

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

class Recommend extends Component {
	constructor(props) {
		super(props);

		this.state = {
			recommendReason: '',
		};
	}

	componentDidMount() {
		//const user = JSON.parse(window.localStorage.getItem('wordnet_user'));
		const user = this.props.user;
		if (user) {
			this.setState({ user: this.props.user });
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ user: nextProps.user });
	}

	handleOnChangeText = (e) => {
		this.setState({
			recommendReason: e.target.value,
		});
	};

	handleOnClickSend = () => {
		const user = this.state.user;
		if (user !== undefined && user !== null) {
			//console.log(user);
			let reason = this.state.recommendReason;
			if (this.state.recommendReason === '') {
				reason = '#사전 #유의어 #비슷한말 #반의어 #반대말 #방언 #높임말 #낮춤말 #관련어';
			}
			window.sendLink(user.userEmail, reason);
		} else {
			//Toastr.info('해당 서비스는 정식오픈 이후에 이용 가능합니다.');
			Toastr.info('친구 추천은 회원가입 이후 진행할 수 있습니다.');
		}
	};

	render() {
		const { recommendReason } = this.state;

		return (
			<div className="container">
				<div className="row mb-4">
					<div className="col">
						<h4 className="mb-0">친구 추천하기</h4>
					</div>
				</div>
				<div className="row">
					<div className="col-12 mb-4">
						<div className="row">
							<div className="col">
								<div className="ui negative message">
									<ul className="list">
										<li className="content">카카오톡을 통해 친구에게 추천해 주세요.</li>
										<li className="content">
											추천받은 친구가 가입할 경우{' '}
											<span className="font-600">7일 무료이용쿠폰</span>을 드립니다.
										</li>
										<li className="content">
											추천하신 분께는 <span className="font-600">친구 가입 횟수에 따라</span>{' '}
											아래의 쿠폰을 드립니다.
											<div className="mt-1">
												<span className="text-09">- 5회: 기본유의어사전 1개월 이용권</span>
												<br />
												<span className="text-09">- 10회: 기본유의어사전 3개월 이용권</span>
												<br />
												<span className="text-09">- 20회: 기본유의어사전 12개월 이용권</span>
												<br />
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 mb-4">
						<Form>
							<Grid>
								<Grid.Row style={{ marginLeft: '0px' }}>
									<Grid.Column>
										<Card fluid size="small">
											<Card.Content>
												<Form.Field
													name="recommendReason"
													control={TextArea}
													label="추천말(40자 내외) - 선택사항"
													placeholder="#사전 #유의어 #비슷한말 #반의어 #반대말 #방언 #높임말 #낮춤말 #관련어"
													onChange={this.handleOnChangeText}
													value={recommendReason}
												/>
											</Card.Content>
										</Card>
									</Grid.Column>
								</Grid.Row>

								<Grid.Row style={{ marginLeft: '0px' }}>
									<Grid.Column textAlign="center">
										<Button color="teal" onClick={this.handleOnClickSend}>
											<Icon name="send" />
											친구에게 추천링크 보내기
										</Button>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Form>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, null)(withRouter(Recommend));
