export const setLoginEmail = (email) => {
	window.localStorage.setItem('loginEmailForNatmal', email);
};

export const getLoginEmail = () => {
	const emailData = window.localStorage.getItem('loginEmailForNatmal');
	if (emailData !== undefined && emailData !== null && emailData !== '') {
		return emailData;
	} else {
		return '';
	}
};

export const setAuthKey = (authKey) => {
	window.localStorage.setItem('wordnetUserAuthkey', authKey);
};

export const getAuthKey = () => {
	const authKey = window.localStorage.getItem('wordnetUserAuthkey');
	if (authKey !== undefined && authKey !== null && authKey !== '') {
		return authKey;
	} else {
		return '';
	}
};

export const convertTextToImageForResult = (txt, gubn) => {
	const regExp = /[[\]{}()<>?|`~!@#$%^&*-_+=,.;:"'\\]/g;
	let canvasWidth = 0;

	if (regExp.test(txt)) {
		if (gubn !== '방언') {
			let tempText = txt.split('(');
			txt = tempText[0];
			canvasWidth = 14 * txt.length;
		} else {
			canvasWidth = 12 * txt.length;
		}
	} else {
		canvasWidth = 14 * txt.length;
	}

	const canvas = document.createElement('canvas');
	canvas.width = canvasWidth;
	canvas.height = 18;

	const ctx = canvas.getContext('2d');
	ctx.font = '600 13px Noto Sans KR';
	ctx.fillStyle = '#2e2e2e';
	ctx.fillText(txt, 0, 12);

	return canvas.toDataURL('image/png');
};

export const extractWord = (txt) => {
	let tempText = txt.split('(');
	return tempText[0];
};

export const extractHanja = (txt) => {
	let tempText = txt.split('(');
	let hanja = '';
	if (tempText.length > 1) {
		hanja = '(' + tempText[1];
	}

	return hanja;
};

export const chkAvailableCoupon = (expiredDate) => {
	const today = new Date().toISOString(); // 오늘 날짜 객체를 생성합니다.
	const expired = new Date(expiredDate).toISOString();

	if (today < expired) {
		return true;
	} else {
		return false;
	}
};
