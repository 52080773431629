import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Label } from 'semantic-ui-react';
import { PaginationRoundBtn } from 'components';
import Toastr from 'modern-toastr';

import * as service from 'services/dictionary';
import * as actions from 'actions';

Toastr.setPosition('toast-top-center');

class SearchList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchWord: this.props.searchWord,
			resultData: [],
			currentPage: this.props.searchPage,
			pageSize: 10,
			totalPage: 0,
			totalElements: 0,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.searchWord !== prevState.searchWord || nextProps.searchPage !== prevState.currentPage) {
			return {
				searchWord: nextProps.searchWord,
				currentPage: nextProps.searchPage,
			};
		}
		return null;
	}

	componentDidMount() {
		//console.log('componentDidMount');
		let searchWord = this.state.searchWord;
		if (searchWord !== undefined && searchWord !== null && searchWord !== '') {
			this.fetchSearchWordInfo(searchWord, this.state.pageSize, this.state.currentPage);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		//console.log('componentDidUpdate', prevProps, prevState);
		if (this.state.searchWord !== prevState.searchWord || this.state.currentPage !== prevState.currentPage) {
			this.fetchSearchWordInfo(this.state.searchWord, this.state.pageSize, this.state.currentPage);
		}
	}

	handleOnChangePage = (page) => {
		this.setState({
			currentPage: page,
		});

		this.props.searchDicPyojaeInfo(this.state.searchWord, page);
		/*
        this.setState({
            currentPage: page
        }, () => this.fetchSearchWordInfo(this.state.searchWord, this.state.pageSize, page));
        */
	};

	fetchSearchWordInfo = async (searchWord, pageSize, pageNum) => {
		let _pageNum = 0;
		if (pageNum > 0) {
			_pageNum = pageNum - 1;
		}

		let searchWordItems = await service.getSearchPyojaeItems({
			searchWord: searchWord,
			pageSize: pageSize,
			pageNum: _pageNum,
		});

		if (searchWordItems !== null) {
			if (searchWordItems.status === 200) {
				if (searchWordItems.data.totalElements > 0) {
					this.showSearchWordInfo(searchWordItems.data);
				} else {
					this.setState({
						resultData: [],
						totalPage: 0,
						totalElements: 0,
					});
				}
			} else {
				Toastr.error('사전 검색중에 오류가 발생하였습니다. 다시 시도해 주세요.');
			}
		} else {
			//Toastr.error(message.MSG_HAPPEN_TO_SEARCH_ERROR);
		}
	};

	showSearchWordInfo(searchWordItems) {
		this.setState({
			resultData: searchWordItems.results.wordInfoItems,
			totalPage: searchWordItems.totalPages,
			totalElements: searchWordItems.totalElements,
		});
	}

	render() {
		const { match } = this.props;
		const { totalElements, currentPage, totalPage, resultData } = this.state;
		return (
			<Fragment>
				<div className="container mt-5">
					<div className="row mt-2 mb-2">
						<div className="col">
							<span>사전 검색 결과</span>
							<Label size="tiny" className="ml-2" circular color={'grey'}>
								{' '}
								{totalElements}
							</Label>
						</div>
					</div>

					<div className="list-group list-group-flush my-0">
						{resultData.length > 0 &&
							resultData.map((item, index) => {
								return (
									<div className="list-group-item" key={index}>
										<Link
											to={{
												pathname: `${match.url}/Search/${item.ID}`,
												state: {
													word: item.WORD,
													hanja: item.HANJA,
													linkUrl: `${match.url}/Search/`,
												},
											}}>
											<div className="row">
												<div className="col pl-0 align-self-center">
													<span className="font-weight-normal">
														{item.WORD} {item.HANJA}{' '}
													</span>
												</div>
											</div>
										</Link>
									</div>
								);
							})}
					</div>
				</div>

				<PaginationRoundBtn
					currentPage={currentPage}
					totalPage={totalPage}
					onChangePage={this.handleOnChangePage}
				/>
			</Fragment>
		);
	}
}

function mapStateToProps({ dicSearch }) {
	return {
		searchWord: dicSearch.searchWord,
		searchPage: dicSearch.searchPage,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			searchDicPyojaeInfo: actions.searchDicPyojaeInfo,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchList);
