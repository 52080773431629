import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Icon, Grid, Button, Form } from 'semantic-ui-react';
import Toastr from 'modern-toastr';

Toastr.setPosition('toast-top-center');

class Searchbar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            keyword: '',
            searchWord: ''
        }        
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.searchWord !== prevState.searchWord) {
            return { 
                keyword: nextProps.searchWord,
                searchWord: nextProps.searchWord
            };
        }
        return null;
    }

    handleKeyPress = (e) => {
        if(e.key === 'Enter') {
            this.handleOnClick(e);
        }
    }

    handleOnChange = (e) => {
        this.setState({
            keyword: e.target.value
        });
    }

    handleOnClick = (e) => {   
        e.preventDefault();  

        if(this.state.keyword !== '') {
            this.props.setSearchWord(this.state.keyword);
        } else {
            Toastr.error('검색할 내용을 입력해 주세요.');
        }
    }

    render() {
        const { keyword } = this.state;
        return (
            <div className="container-fluid bg-template mb-4">
                <div className="row hn-96 position-relative">
                    <div className="container align-self-end pb-2">
                        {/*}
                        <Input fluid className='bottom-15' 
                            icon={<Icon name='search' link circular onClick={this.handleOnClick}/>}
                            placeholder='검색할 단어를 입력해 주세요.' 
                            value={keyword}
                            onKeyPress={this.handleKeyPress}
                            onChange={this.handleOnChange}
                        />
                        */}
                            <Grid>
                                <Grid.Column  mobile={10} tablet={12} computer={13} className='pr-1'>
                                    <Input
                                        icon='keyboard' iconPosition='left' fluid
                                        placeholder="검색할 단어를 입력해 주세요."
                                        value={keyword}
                                        onKeyPress={this.handleKeyPress}
                                        onChange={this.handleOnChange}
                                    />
                                </Grid.Column>
                                <Grid.Column  mobile={6} tablet={4} computer={3} className='pl-1'>
                                    <Button color="orange" fluid variant="contained" onClick={this.handleOnClick} > 
                                        <Icon name='search' />검색
                                    </Button>
                                </Grid.Column>
                            </Grid>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps ({dicSearch}) {
    return {
        searchWord: dicSearch.searchWord
    }
}

export default connect(mapStateToProps, null)(Searchbar);