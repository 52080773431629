import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Form, Table, Button, Responsive, Pagination, Grid, Segment, Icon, Input, Dropdown, Label, Popup } from 'semantic-ui-react';
import Toastr from 'modern-toastr';

import * as actions from 'actions';
import * as moment from 'moment';
import * as service from 'services/system';
import * as systemTypes from 'types/systemTypes';

Toastr.setPosition('toast-top-center');

const mapStateToProps = state => ({
    user: state.auth.user,
    couponGroupItem: state.couponInfo.couponGroupItem,
    activePage: state.couponInfo.activePage
});

class MgrCouponList extends Component {
    
    constructor(props) {
        super(props);

        const state = {
            couponGroupItem: null,            
            couponGroupId: 0,
            couponGroupName: '',
            couponType: 0,
            createCount: 1            
        };

        const couponGroupItem = (this.props.couponGroupItem !== undefined && this.props.couponGroupItem !== null) ? this.props.couponGroupItem : null;
        state.couponGroupItem = couponGroupItem;
        state.couponGroupId = (couponGroupItem && couponGroupItem.ID !== null) ? couponGroupItem.ID : 0;
        state.couponGroupName = (couponGroupItem && couponGroupItem.COUPON_NAME !== null) ? couponGroupItem.COUPON_NAME.trim() : '';
        state.couponType = (couponGroupItem && couponGroupItem.CATEGORY_ID !== null) ? couponGroupItem.CATEGORY_ID : '';

        let couponTypeOption = Object.keys(systemTypes.COUPON_TYPES).map(key => {
			let item = systemTypes.COUPON_TYPES[key];
			return {
				key: item.id,
				text: item.title,
				value: item.value,
				style: { fontSize: '0.9em' },
			};
        });

        let searchOptStatus = systemTypes.COUPON_STATUS_LABEL.map(key => {
			let item = systemTypes.COUPON_STATUS.properties[key];
			return {
				key: item.id,
				text: item.label,
				value: item.value,
				style: { fontSize: '0.9em' },
			};
        });

        this.state = {			
            isWarning: false,
            couponTypeOption,  
            searchOptStatus,          
            ...state,
            couponStatus: '',
            couponItems: [],
            currentPage: this.props.activePage,
            pageSize: 10,
            totalPages: 0,
            totalElements: 0    

		};

    }

    componentDidMount() {
        //console.log('componentDidMount');
        //console.log(this.props);
        const user = this.props.user;
        const {couponGroupItem, couponGroupId, couponStatus, pageSize, currentPage } = this.state;
        if(user !== undefined && user !== null && user.grade === 0) {
            if(couponGroupItem !== undefined && couponGroupItem !== null) {
                this.fetchSelectCouponItems({ 
                    couponGroupId: couponGroupId,          
                    couponStatus: couponStatus,                     
                    pageSize: pageSize, 
                    pageNum: currentPage
                });
            }
        }
    }

    handleOnClickGoBack = () => {
        this.props.history.goBack();
    }

    dataHandler = (e, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {
			this.setState({ [name]: value });
		}
		this.setState({ isWarning: false });
    };

    createCouponOnKeyPress = e => {
		if (e.key === 'Enter') {
			this.createCouponItems(e);
		}
    };

    onClickCouponSet = (e, couponItem) => {
        e.preventDefault();
        //console.log(couponItem);
		let data = { 
            couponType: this.state.couponGroupItem.WordnetItem.NAME.trim(), 
            couponName: this.state.couponGroupItem.COUPON_NAME,
            couponItem: couponItem 
        };
		this.props.history.push({ pathname: '/mgrCoupon/List/Detail', post: data });
    };
    
    onClickCouponDelete = (e, couponItem) => {
        e.preventDefault();
        //console.log(couponItem);
        this.fetchDeleteCouponItem({
            couponId: couponItem.ID,
            groupId: couponItem.GROUP_ID
        });
    };

    paginationChange = (e, { activePage }) => {
		const { couponGroupId, couponStatus, pageSize } = this.state;
        this.setState({ currentPage: activePage });
        this.props.setCouponListActivePage(activePage, couponStatus);
		this.fetchSelectCouponItems({ 
            couponGroupId: couponGroupId,          
            couponStatus: couponStatus,                     
            pageSize: pageSize, 
            pageNum: activePage
        });
    };

    createCouponItems = e => {
        e.preventDefault();
        
        const user = this.props.user;

        if(user !== undefined && user !== null && user.grade === 0) {
            if(this.state.createCount !== '') {
                let createCount = parseInt(this.state.createCount);

                if (createCount > 0) {
                    let { couponGroupId } = this.state;
                    let datas = { 
                        userId: user.id, 
                        couponGroupId: couponGroupId, 
                        createCount: createCount 
                    };

                    this.fetchInsertCouponItems(datas);
                } else {
                    Toastr.error('생성할 쿠폰 개수는 0보다 커야 합니다.');
                }
            } else {
                Toastr.error('생성할 쿠폰 개수를 입력해 주세요.');                
            }
        } else {
            Toastr.error('관리자 권한이 필요합니다.');   
        }        
    };
    
    fetchInsertCouponItems = async(params) => {

        const couponItems = await service.insertCouponItems(params);

        if(couponItems !== null) {            
            if(couponItems.status === 200) {
                Toastr.info(couponItems.data.message); 
                const { couponGroupId, couponStatus, pageSize, currentPage } = this.state;
                this.fetchSelectCouponItems({ 
                    couponGroupId: couponGroupId,          
                    couponStatus: couponStatus,                     
                    pageSize: pageSize, 
                    pageNum: currentPage
                });       
            } else {
                Toastr.error(couponItems.data.message);
            }
        } else {
            Toastr.error('신규 쿠폰을 생성하는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
        } 
    }

    fetchDeleteCouponItem = async(params) => {

        const couponItem = await service.deleteCouponItem(params);

        if(couponItem !== null) {            
            if(couponItem.status === 200) {
                Toastr.info(couponItem.data.message); 
                const { couponGroupId, couponStatus, pageSize, currentPage } = this.state;
                this.fetchSelectCouponItems({ 
                    couponGroupId: couponGroupId,          
                    couponStatus: couponStatus,                     
                    pageSize: pageSize, 
                    pageNum: currentPage
                });       
            } else {
                Toastr.error(couponItem.data.message);
            }
        } else {
            Toastr.error('쿠폰 정보를 삭제하는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
        } 
    };

    fetchSelectCouponItems = async(params) => {

        const couponItems = await service.selectCouponItems(params);

        if(couponItems !== null) {            
            if(couponItems.status === 200) {
                if(couponItems.data) {
                    this.showCouponItems(couponItems.data);    
                } else {
                    this.setState({            
                        couponItems: [],
                        totalPage: 0,
                        totalElements: 0
                    });
                }             
            } else {
                Toastr.error('쿠폰 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
            }
        } else {
            Toastr.error('쿠폰 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
        } 
    };

    showCouponItems(data) {
        const totalPages = Math.ceil(parseInt(data.totalCount) / this.state.pageSize);
        //if (this._isMounted) {
            this.setState({ 
                totalElements: data.totalCount,              
                totalPages: totalPages, 
                couponItems: data.datas 
            });
        //}
    }

    render() {
        const { couponTypeOption, searchOptStatus, couponType, couponGroupName, couponStatus, createCount } = this.state;
        const { couponItems, totalElements, currentPage, totalPages } = this.state;
        return (
            <div className="container">
                <div className="row mb-4">
                    <div className='col'>
                        <h4 className="mb-0">쿠폰목록</h4>
                    </div>
                </div>  
                <div className='row mt-4'>
                    <div className='col-12 mb-4'>
                        <Form>
                            <Grid>							
                                <Grid.Row style={{ marginLeft: '0px' }}>
                                    <Grid.Column>
                                        <Segment color="black" clearing>
                                            <Form.Field inline>
                                                <Form.Group widths={4} style={{ margin: 'auto' }}>
                                                    <Form.Field width={4}>
                                                        <Dropdown	
                                                            selection													
                                                            name="couponType"
                                                            placeholder="상품명"
                                                            options={couponTypeOption}
                                                            value={couponType}
                                                            readOnly
                                                        />
                                                    </Form.Field>                                                    
                                                    <Form.Field width={5}>
                                                        <Input
                                                            name="couponGroupName"
                                                            fluid                                                            
                                                            placeholder="쿠폰명"
                                                            value={couponGroupName}   
                                                            readOnly                                                        
                                                        />
                                                    </Form.Field>
                                                    <Form.Field width={4}>
                                                        <Dropdown	
                                                            selection													
                                                            name="couponStatus"
                                                            options={searchOptStatus}
                                                            placeholder="쿠폰상태"
                                                            onChange={this.handleOnClickStatus}
                                                            value={couponStatus}
                                                        />
                                                    </Form.Field>																					
                                                </Form.Group>
                                            </Form.Field>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ marginLeft: '0px', paddingTop: '0px', paddingBottom: '0px' }}>
                                    <Grid.Column>
                                        <Grid columns='equal'>
                                            <Grid.Column>
                                                <Button as='div' labelPosition='right'>
                                                    <Button icon>
                                                        검색결과
                                                    </Button>
                                                    <Label as='a' basic pointing='left'>
                                                        {totalElements}
                                                    </Label>
                                                </Button>    
                                            </Grid.Column>
                                            <Grid.Column>                                                
                                            </Grid.Column>                                            
                                            <Grid.Column textAlign='right'>
                                                <Form.Field >										
                                                    <Form.Group >                                                
                                                        <Input
                                                            name="createCount"                                                            
                                                            action={{
                                                                size: 'tiny',
                                                                color: 'teal',
                                                                children: '신규쿠폰생성',
                                                                onClick: this.createCouponItems,
                                                            }}
                                                            icon="plus"
                                                            value={createCount}
                                                            onKeyPress={this.createCouponOnKeyPress}
                                                            onChange={this.dataHandler}                                                            
                                                            iconPosition="left"
                                                        />
                                                    </Form.Group>
                                                </Form.Field>                                                                                                                                                                 
                                            </Grid.Column>
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ marginLeft: '0px' }}>
                                    <Grid.Column>
                                        <Table
                                            key="black"
                                            fixed
                                            selectable
                                            textAlign="center"
                                            style={{ position: 'relative', padding: '0px', margin: '0px' }}>
                                            <Responsive as={Table.Header} minWidth={768}>
                                                <Table.Row>
                                                    <Table.HeaderCell width="2">No.</Table.HeaderCell>
                                                    <Table.HeaderCell width="4">쿠폰코드</Table.HeaderCell>
                                                    <Table.HeaderCell width="2">사용여부</Table.HeaderCell>
                                                    <Table.HeaderCell width="3">사용자ID</Table.HeaderCell>
                                                    <Table.HeaderCell width="2">사용일</Table.HeaderCell>
                                                    <Table.HeaderCell width="2">만료일</Table.HeaderCell>
                                                    <Table.HeaderCell textAlign="center" width={1} />
                                                </Table.Row>
                                            </Responsive>
                                            <Table.Body>
                                                {couponItems &&
                                                    couponItems.map((coupon, index) => {
                                                        return (
                                                            <Table.Row key={'couponItem_' + index} className="cursor-pointer">
                                                                <Table.Cell onClick={e => this.onClickCouponSet(e, coupon)}>
                                                                    {coupon.ID}
                                                                </Table.Cell>                                                                
                                                                <Table.Cell
                                                                    singleLine
                                                                    onClick={e => this.onClickCouponSet(e, coupon)}>
                                                                    {coupon.COUPON_CODE}
                                                                </Table.Cell>
                                                                <Table.Cell onClick={e => this.onClickCouponSet(e, coupon)}>
                                                                    {coupon.STATUS.trim() === 'STANDBY' && '미사용'}
                                                                    {coupon.STATUS.trim() === 'USING' && '사용중'}
                                                                    {coupon.STATUS.trim() === 'COMPLETE' && '사용완료'}
                                                                </Table.Cell>
                                                                <Table.Cell onClick={e => this.onClickCouponSet(e, coupon)}>
                                                                    {(coupon.WordnetUser !== undefined && coupon.WordnetUser !== null) &&
                                                                        coupon.WordnetUser.USEREMAIL.trim()}
                                                                </Table.Cell>
                                                                <Table.Cell onClick={e => this.onClickCouponSet(e, coupon)}>
                                                                    {(coupon.USED_DATE !== undefined && coupon.USED_DATE !== null) && 
                                                                        moment(coupon.USED_DATE).format('YYYY-MM-DD')}
                                                                </Table.Cell>
                                                                <Table.Cell onClick={e => this.onClickCouponSet(e, coupon)}>
                                                                    {(coupon.EXPIRED_DATE !== undefined && coupon.EXPIRED_DATE !== null) && 
                                                                        moment(coupon.EXPIRED_DATE).format('YYYY-MM-DD')}
                                                                </Table.Cell>
                                                                <Table.Cell textAlign="center">
                                                                    <Button
                                                                        icon
                                                                        circular
                                                                        compact
                                                                        onClick={e =>
                                                                            this.onClickCouponDelete(e, coupon)
                                                                        }>
                                                                        <Icon name="remove" />
                                                                    </Button>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        );
                                                    })}
                                                {!couponItems && (
                                                    <Table.Row>
                                                        <Table.Cell colSpan="7">생성된 쿠폰이 없습니다. </Table.Cell>
                                                    </Table.Row>
                                                )}
                                                <Table.Row>
                                                    <Table.Cell colSpan="7" className="text-center">
                                                        {couponItems && (
                                                            <Pagination
                                                                activePage={currentPage}
                                                                ellipsisItem={{
                                                                    content: <Icon name="ellipsis horizontal" />,
                                                                    icon: true,
                                                                }}
                                                                firstItem={{
                                                                    content: <Icon name="angle double left" />,
                                                                    icon: true,
                                                                }}
                                                                lastItem={{
                                                                    content: <Icon name="angle double right" />,
                                                                    icon: true,
                                                                }}
                                                                prevItem={{
                                                                    content: <Icon name="angle left" />,
                                                                    icon: true,
                                                                }}
                                                                nextItem={{
                                                                    content: <Icon name="angle right" />,
                                                                    icon: true,
                                                                }}
                                                                boundaryRange={0}
                                                                siblingRange={2}
                                                                pointing
                                                                secondary
                                                                onPageChange={this.paginationChange}
                                                                totalPages={totalPages}
                                                            />
                                                        )}
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row style={{ marginLeft: '0px' }}>
                                    <Grid.Column textAlign="center">
                                        <Button size="huge" onClick={this.handleOnClickGoBack}>
                                            이전
                                        </Button>                                        							
                                    </Grid.Column>
                                </Grid.Row>
						
                            </Grid>
                        </Form>
                    </div>    
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setCouponListActivePage: actions.setCouponListActivePage   
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MgrCouponList));