import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
	Icon,
	Modal,
	Header,
	Button,
	Divider,
	Card,
	Label,
	Grid,
	Form,
	Input,
	Table,
	Responsive,
	Message,
	Checkbox,
} from 'semantic-ui-react';
import Toastr from 'modern-toastr';

import * as actions from 'actions';
import * as moment from 'moment';
import * as service from 'services/system';

Toastr.setPosition('toast-top-center');

let mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	};
};

const mapDispatchToProps = (dispatch) => ({
	logout: () => dispatch(actions.logout()),
});

class UserInfo extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			user: props.user,
			name: props.user !== undefined && props.user !== null ? props.user.userName : '',
			email: props.user !== undefined && props.user !== null ? props.user.userEmail : '',
			phone: props.user !== undefined && props.user !== null ? props.user.userPhone : '',
			isChecking: false,
			prevUserId: '',
			prevUserPw: '',
			payHistoryItems: [],
			remainedDay: 0,
			chkAgreement: false,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.user !== prevState.user) {
			return {
				user: nextProps.user,
				name: nextProps.user !== undefined && nextProps.user !== null ? nextProps.user.userName : '',
				email: nextProps.user !== undefined && nextProps.user !== null ? nextProps.user.userEmail : '',
				phone: nextProps.user !== undefined && nextProps.user !== null ? nextProps.user.userPhone : '',
			};
		}
		return null;
	}

	onInputTextChange = (e, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {
			this.setState({ [name]: value });
		}
	};

	searchOnKeyPress = (e) => {
		if (e.key === 'Enter') {
			this.searchKeywordOnClick(e);
		}
	};

	searchKeywordOnClick = (e) => {
		e.preventDefault();

		const { prevUserId, prevUserPw } = this.state;
		if (prevUserId === '') {
			Toastr.error('(구)낱말창고의 아이디를 입력해 주세요.');
			return;
		}

		if (prevUserPw === '') {
			Toastr.error('(구)낱말창고의 비밀번호를 입력해 주세요.');
			return;
		}

		this.fetchSearchPrevUserInfo({
			userId: prevUserId,
			userPw: prevUserPw,
		});
	};

	handleClickOpen = () => {
		this.setState({
			open: true,
		});
	};

	handleClickClose = () => {
		this.setState({
			open: false,
		});
	};

	handleClickLogout = () => {
		this.props.logout();
		this.setState({
			open: false,
		});
		window.location.href = '/';
		//this.props.history.push('/');
	};

	handleClickChangePw = () => {
		this.props.history.push('/changePw');
	};

	handleOnChangeChkAgreement = (e, { name, checked }) => {
		this.setState({
			chkAgreement: checked,
		});
	};

	handleOnChangeText = (e, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {
			this.setState({ [name]: value });
		}
	};

	fetchSearchPrevUserInfo = async (params) => {
		const prevUserInfo = await service.searchPrevUserInfo(params);
		//console.log(prevUserInfo);
		if (prevUserInfo !== null) {
			if (prevUserInfo.status === 200) {
				if (prevUserInfo.data) {
					this.showPrevUserInfo(prevUserInfo.data);
				} else {
					this.setState({
						isChecking: true,
						payHistoryItems: [],
					});
				}
			} else {
				Toastr.error(prevUserInfo.data.message);
			}
		} else {
			Toastr.error('(구)낱말창고의 사용자 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
		}
	};

	handleOnClickCombineUserInfo = (e) => {
		e.preventDefault();
		const { payHistoryItems, chkAgreement } = this.state;
		let remainedDay = 0;

		if (payHistoryItems.length === 0) {
			Toastr.error('조회된 데이터가 존재하지 않습니다.');
			return;
		} else {
			payHistoryItems.map((item) => {
				if (item.CURSTAT === 'Y' && item.TRANSFER === null) {
					remainedDay += this.getRemainedDay(item.SDATE, item.EDATE);
				}
			});
		}

		if (remainedDay === 0) {
			Toastr.error('전환할 기간이 없거나 이미 전환이 완료된 상태입니다.');
			return;
		}

		if (!chkAgreement) {
			Toastr.error('승인을 위한 사용자 동의 확인이 필요합니다.');
			return;
		}

		this.fetchCombinePrevUserInfo(this.state.prevUserId, remainedDay);
	};

	fetchCombinePrevUserInfo = async (prevUserId, remainedDay) => {
		const user = this.props.user;
		const startDate = moment().format('YYYY-MM-DD');
		const endDate = moment().add(remainedDay, 'days').format('YYYY-MM-DD');

		if (user !== undefined && user !== null) {
			const combineUser = await service.combinePrevUserInfo({
				userId: user.id,
				prevUserId: prevUserId,
				remainedDay: remainedDay,
				startDate: startDate,
				endDate: endDate,
			});

			if (combineUser !== null) {
				if (combineUser.status === 200) {
					Toastr.info('사용자 정보 통합처리가 완료되었습니다. 처리 결과는 [사용정보] 에서 확인해 주세요.');
					if (combineUser.data) {
						this.showPrevUserInfo(combineUser.data);
					} else {
						this.setState({
							isChecking: true,
							payHistoryItems: [],
						});
					}
				} else {
					Toastr.error(combineUser.data.message);
				}
			} else {
				Toastr.error('사용자 정보를 통합하는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
			}
		} else {
			//Toastr.info('해당 서비스는 정식오픈 이후에 이용 가능합니다.');
			Toastr.error('사용자 정보가 존재하지 않습니다. 로그인 후 이용해 주세요.');
			return;
		}
	};

	showPrevUserInfo(data) {
		this.setState({
			isChecking: true,
			payHistoryItems: data.results.payHistoryItems,
		});
	}

	getRemainedDay(sdate, edate) {
		let curDate = moment();
		let lastDate = moment(edate).add(1, 'M');
		return lastDate.diff(curDate, 'days');
	}

	render() {
		const { open, user, name, phone, email } = this.state;
		const { isChecking, prevUserId, prevUserPw, payHistoryItems, chkAgreement } = this.state;
		return (
			<Fragment>
				<div className="container">
					<div className="row">
						<div className="col align-self-start">
							<span className="btn btn-sm text-secondary" onClick={this.handleClickOpen}>
								<Icon name="log out" />
								로그아웃
							</span>
						</div>
						<div className="col-auto align-self-end">
							<span className="btn btn-sm text-secondary" onClick={this.handleClickChangePw}>
								<Icon name="key" />
								비밀번호변경
							</span>
						</div>
					</div>
					<div className="row flex-colum">
						<div className="col-12 mx-auto login-row">
							<div className="row">
								<div className="col-12 align-self-center text-center">
									<div className="logo mb-5">
										<h5>사용자 정보</h5>
									</div>

									{user !== undefined && user !== null && user.status === 'none' && (
										<div className="row">
											<div className="col-12 mb-4">
												<div className="row">
													<div className="col">
														<div className="ui negative message">
															<ul className="list">
																<li className="content">
																	현재 사용중인 이용권이 없습니다.
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									)}

									<form className="form-signin">
										<div className="row">
											<div className="col-auto align-self-center">
												<Icon name="mail" />
											</div>
											<div className="col pl-0">
												<div className="form-group float-label active">
													<input
														id="inputEmail"
														name="email"
														type="text"
														className="form-control"
														value={email}
														readOnly
													/>
													<label htmlFor="inputEmail" className="form-control-label">
														Email Address
													</label>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col-auto align-self-center">
												<Icon name="user circle" />
											</div>
											<div className="col pl-0">
												<div className="form-group float-label active">
													<input
														id="inputusername"
														name="name"
														type="text"
														className="form-control"
														value={name}
														onChange={this.handleOnChangeText}
													/>
													<label htmlFor="inputusername" className="form-control-label">
														User Name
													</label>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col-auto align-self-center">
												<Icon name="phone" />
											</div>
											<div className="col pl-0">
												<div className="form-group float-label active">
													<input
														id="inputuserphone"
														name="phone"
														type="text"
														className="form-control"
														value={phone || ''}
														onChange={this.handleOnChangeText}
													/>
													<label htmlFor="inputuserphone" className="form-control-label">
														User Phone
													</label>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-12 mb-4">
							<Divider horizontal>
								<Label className="text-11">(구)낱말창고 통합하기</Label>
							</Divider>
							<Card fluid>
								<Card.Content>
									<Form>
										<Grid>
											<Grid.Row style={{ marginLeft: '0px' }}>
												<Grid.Column>
													<div className="ui info message">
														<ul className="list">
															<li className="content">
																(구)낱말창고 유료회원을 위한 통합 서비스입니다.
															</li>
															<li className="content">
																(구)낱말창고에 유료 사용기간이 남아 있는 경우{' '}
																<span className="font-700">
																	남은 기간(+1개월)만큼 기본유의어사전
																</span>
																을 사용하실 수 있습니다.
															</li>
															<li className="content">
																(구)낱말창고의{' '}
																<span className="font-700">
																	아이디와 비밀번호로 남은 기간을 조회
																</span>
																하신 후 통합을 진행해 주세요.
															</li>
														</ul>
													</div>
												</Grid.Column>
											</Grid.Row>

											<Grid.Row style={{ marginLeft: '0px' }}>
												<Grid.Column>
													<Form.Field inline>
														<Form.Group widths={4} style={{ margin: 'auto' }}>
															<Form.Field width={8}>
																<Input
																	name="prevUserId"
																	fluid
																	placeholder="(구)낱말창고 아이디"
																	onChange={this.onInputTextChange}
																	value={prevUserId}
																/>
															</Form.Field>
															<Form.Field width={8}>
																<Input
																	name="prevUserPw"
																	type="password"
																	fluid
																	action={{
																		size: 'tiny',
																		color: 'grey',
																		children: '조회',
																		onClick: this.searchKeywordOnClick,
																	}}
																	placeholder="(구)낱말창고 비밀번호"
																	value={prevUserPw}
																	onKeyPress={this.searchOnKeyPress}
																	onChange={this.onInputTextChange}
																/>
															</Form.Field>
														</Form.Group>
													</Form.Field>
												</Grid.Column>
											</Grid.Row>

											<Grid.Row style={{ marginLeft: '0px' }}>
												<Grid.Column>
													<Table
														size="small"
														key="black"
														fixed
														selectable
														singleLine
														textAlign="center"
														style={{ position: 'relative', padding: '0px', margin: '0px' }}>
														<Responsive as={Table.Header} minWidth={768}>
															<Table.Row>
																<Table.HeaderCell width="6">시작일</Table.HeaderCell>
																<Table.HeaderCell width="6">종료일</Table.HeaderCell>
																<Table.HeaderCell width="4">
																	현재남은기간(+1개월)
																</Table.HeaderCell>
															</Table.Row>
														</Responsive>
														<Table.Body>
															{isChecking && payHistoryItems.length === 0 && (
																<Table.Row>
																	<Table.Cell className="text-13" colSpan="3">
																		<Message className="mt-3 ml-2 mr-2" negative>
																			(구)낱말창고의 사용기간이 만료되었거나
																			결제한 이력이 존재하지 않습니다.
																		</Message>
																	</Table.Cell>
																</Table.Row>
															)}

															{isChecking &&
																payHistoryItems.length > 0 &&
																payHistoryItems.map((item, index) => {
																	let sdate = moment(item.SDATE).format('YYYY-MM-DD');
																	let edate = moment(item.EDATE).format('YYYY-MM-DD');

																	return (
																		<Table.Row key={index}>
																			<Table.Cell className="text-13">
																				{sdate}
																			</Table.Cell>
																			<Table.Cell className="text-13">
																				{edate}
																			</Table.Cell>
																			<Table.Cell className="text-13">
																				{item.CURSTAT === 'N' && (
																					<span>만료</span>
																				)}
																				{item.CURSTAT === 'Y' &&
																					item.TRANSFER === null && (
																						<span>
																							{this.getRemainedDay(
																								item.SDATE,
																								item.EDATE
																							)}
																							일
																						</span>
																					)}
																				{item.CURSTAT === 'Y' &&
																					item.TRANSFER === 'Y' && (
																						<span>통합완료</span>
																					)}
																			</Table.Cell>
																		</Table.Row>
																	);
																})}
														</Table.Body>
													</Table>

													<Form.Field
														name="agreement"
														className="mt-2"
														control={Checkbox}
														label=" 위 내용을 모두 확인하였으며, 승인 시 (구)낱말창고의 정보가 신규사이트에 적용되는 것에 동의합니다."
														onChange={this.handleOnChangeChkAgreement}
														checked={chkAgreement}
													/>
												</Grid.Column>
											</Grid.Row>

											<Grid.Row style={{ marginLeft: '0px' }}>
												<Grid.Column textAlign="center">
													<Button color="teal" onClick={this.handleOnClickCombineUserInfo}>
														<Icon name="plus" />
														통합하기
													</Button>
												</Grid.Column>
											</Grid.Row>
										</Grid>
									</Form>
								</Card.Content>
							</Card>
						</div>
					</div>
				</div>
				<Modal size="small" open={open} onClose={this.handleClickClose} className="custom-modal">
					<div className="pt-3 pl-3 text-13 font-600">
						<Icon name="log out" />
						<span>나가기</span>
					</div>
					<Divider />
					<div className="pl-3">
						<p>
							<span className="text-11">로그아웃 하시겠습니까?</span>
						</p>
					</div>
					<Divider />
					<div className="text-center mb-3">
						<Button color="red" onClick={this.handleClickClose}>
							<Icon name="remove" /> 아니요
						</Button>
						<Button color="green" onClick={this.handleClickLogout}>
							<Icon name="checkmark" /> 네
						</Button>
					</div>
					{/*}
                    <Header icon='log out' content='나가기' className='custom-header'/>
                    <Modal.Content className='colorBlack'>
                        <p>
                            <span >로그아웃 하시겠습니까?</span>
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={this.handleClickClose}>
                            <Icon name='remove' /> 아니요
                        </Button>
                        <Button color='green' onClick={this.handleClickLogout}>
                            <Icon name='checkmark' /> 네
                        </Button>
                    </Modal.Actions>
                                                        */}
				</Modal>
			</Fragment>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserInfo));
