import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Grid, Button, Icon, Image, Modal, Label, Accordion, Divider } from 'semantic-ui-react';
import Toastr from 'modern-toastr';

import * as service from 'services/dictionary';
import * as commonUtil from 'utils/common';
import * as actions from 'actions';

Toastr.setPosition('toast-top-center');

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

class SearchDetail extends Component {
	constructor(props) {
		super(props);

		this.state = {
			pyojaeId: props.match.params.id,
			word: props.location.state.word,
			hanja: props.location.state.hanja,
			linkUrl: props.location.state.linkUrl,
			gubnItems: [],
			wordSynItems: [],
			synItems: [],
			searchCount: 0,
			activeIndex: 0,
			openJoin: false,
			openPayment: false,
			openInfo: false,
			user: null,
		};
	}

	componentDidMount() {
		const user = this.props.user;
		if (user) {
			this.setState({ user: this.props.user });
		} else {
			this.fetchGetWordSynInfo(this.state.pyojaeId);
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.match.params.id !== prevState.pyojaeId) {
			return {
				pyojaeId: nextProps.match.params.id,
				word: nextProps.location.state.word,
				hanja: nextProps.location.state.hanja,
				linkUrl: nextProps.location.state.linkUrl,
				user: nextProps.user,
			};
		}
		return null;
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ user: nextProps.user });
	}

	async componentDidUpdate(prevProps, prevState) {
		if (this.state.pyojaeId !== prevState.pyojaeId) {
			if (this.state.user == null) {
				this.fetchGetWordSynInfo(this.state.pyojaeId);
				this.props.searchDicPyojaeInfo(this.state.word, 0);
			}
		}
		if (prevState.user !== this.state.user) {
			this.fetchGetWordSynInfo(this.state.pyojaeId);
			this.props.searchDicPyojaeInfo(this.state.word, 0);
		}
	}

	handleOnClickSaveWord = () => {
		const user = this.state.user;

		if (user !== undefined && user !== null) {
			this.fetchSaveToWordbook(this.state.pyojaeId, this.state.word, this.state.hanja, this.state.linkUrl);
		} else {
			Toastr.error('회원 가입 후 이용해 주세요.');
		}
	};

	handleOnClickShareResult = () => {
		const user = this.state.user;

		if (user !== undefined && user !== null) {
			let tempSyn = '';
			let i = 0;
			if (this.state.synItems.length > 0) {
				while (i < 10) {
					if (tempSyn === '') {
						tempSyn = this.state.synItems[i].WORD;
					} else {
						tempSyn += ', ' + this.state.synItems[i].WORD;
					}
					i++;
				}
			}

			window.sendResultLink("'" + this.state.word + "'의 유의어", tempSyn);
		} else {
			Toastr.error('회원 가입 후 이용해 주세요.');
		}
	};

	handleOnClickGoBack = () => {
		this.props.history.goBack();
	};

	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;

		this.setState({ activeIndex: newIndex });
	};

	handleClickOpenJoin = () => {
		this.setState({
			openJoin: true,
		});
	};

	handleClickCloseJoin = () => {
		this.setState({
			openJoin: false,
		});
	};

	handleClickOpenInfo = () => {
		this.setState({
			openInfo: true,
		});
	};

	handleClickCloseInfo = () => {
		this.setState({
			openInfo: false,
		});
	};

	handleClickOpenPayment = () => {
		this.setState({
			openPayment: true,
		});
	};

	handleClickClosePayment = () => {
		this.setState({
			openPayment: false,
		});
	};

	handleClickJoin = () => {
		this.setState({
			openJoin: false,
		});
		this.props.history.push('/join');
	};

	handleClickPayment = () => {
		this.setState({
			openPayment: false,
		});
		this.props.history.push('/payment');
	};

	handleClickOpenUrl(linkType) {
		const user = this.state.user;
		let url = '';
		let userId = '';

		if (user === undefined || user === null) {
			userId = 99999999;
		} else {
			userId = user.id;
		}

		switch (linkType) {
			case 'natmal':
				url = 'https://natmal.com/views/dictionary/syn';
				break;
			case 'opendic':
				url = 'https://opendict.korean.go.kr/main';
				break;
			default:
				break;
		}

		if (url !== '') {
			window.open(url, '_blank');
			this.props.externalLink({
				userId: userId,
				linkType: linkType,
				wordId: this.state.pyojaeId,
			});
		}
	}

	fetchGetWordSynInfo = async (id) => {
		const user = this.state.user;
		const authKey = commonUtil.getAuthKey();

		let userId = '';
		console.log(user);
		if (user == undefined || user == null) {
			userId = 99999999;
			console.log(userId);
		} else {
			userId = user.id;
		}

		let wordSynItems = await service.getSearchWordSynInfo({
			userId: userId,
			pyojaeId: id,
			userAuthKey: authKey,
		});
		//console.log(wordSynItems);
		if (wordSynItems !== null) {
			if (wordSynItems.status === 200) {
				commonUtil.setAuthKey(wordSynItems.data.authKey);
				if (wordSynItems.data.wordSynItems.length > 0) {
					this.showSearchWordSynInfo(wordSynItems);
					this.fetchSetSearchHistory(id, this.state.word, this.state.hanja, this.state.linkUrl);
				}
			} else {
				Toastr.error(wordSynItems.data.message);
				if (wordSynItems.data.authKeyError) {
					this.props.logout();
				}
			}
		} else {
			Toastr.error('사전 검색중에 오류가 발생하였습니다. 다시 시도해 주세요.');
		}
	};

	fetchSaveToWordbook = async (id, word, hanja, url) => {
		const user = this.state.user;
		//console.log(user);

		if (user !== undefined && user !== null) {
			let wordbookItem = await service.insertWordbookItem({
				userId: user.id,
				pyojaeId: id,
				word: word,
				hanja: hanja,
				linkUrl: url,
			});
			//console.log(wordbookItem);
			if (wordbookItem !== null) {
				if (wordbookItem.status === 200) {
					Toastr.info(wordbookItem.data.message);
				} else {
					Toastr.error(wordbookItem.data.message);
				}
			} else {
				Toastr.error('저장중에 오류가 발생하였습니다. 다시 시도해 주세요.');
			}
		} else {
			Toastr.error('회원 가입 후 이용해 주세요.');
		}
	};

	fetchSetSearchHistory(id, word, hanja, url) {
		const historyName = 'baseDicSyn';
		let historyItems = window.sessionStorage.getItem(historyName);

		if (historyItems !== null) {
			historyItems = JSON.parse(historyItems);
		} else {
			historyItems = [];
		}

		const selectedItem = {
			id: id,
			word: word,
			hanja: hanja,
			linkUrl: url,
		};

		historyItems = historyItems.filter((item) => item.id !== selectedItem.id);

		if (historyItems.length > 9) {
			historyItems.splice(0, 1);
		}

		historyItems.push(selectedItem);
		window.sessionStorage.setItem(historyName, JSON.stringify(historyItems));
	}

	showSearchWordSynInfo(result) {
		const synFirstItems = result.data.wordSynItems.filter((item) => item.GUBN === '비슷한말');

		this.setState({
			activeIndex: 0,
			gubnItems: result.data.gubnItems,
			wordSynItems: result.data.wordSynItems,
			synItems: synFirstItems,
			searchCount: result.data.wordSynItems.length,
		});
	}

	render() {
		const {
			gubnItems,
			wordSynItems,
			searchCount,
			word,
			hanja,
			linkUrl,
			activeIndex,
			openJoin,
			openPayment,
			openInfo,
			user,
		} = this.state;
		return (
			<Fragment>
				<div className="container my-5">
					<div className="row py-3 border-top border-bottom mb-4">
						<div className="col">
							<h5 className="font-weight-normal mb-0">
								{word} {hanja}
							</h5>
						</div>
						<div className="col-auto align-self-end">
							<Button size="tiny" basic onClick={this.handleOnClickSaveWord}>
								<Icon name="plus" /> 단어장
							</Button>
							<Button size="tiny" basic onClick={this.handleOnClickShareResult}>
								<Icon name="share" /> 공유하기
							</Button>
						</div>
					</div>
					<div className="row mt-2 mb-3">
						<div className="col">
							<Button
								size="tiny"
								className="btn-border-grey"
								circular
								icon="arrow left"
								onClick={this.handleOnClickGoBack}
							/>
							<span className="ml-3 font-500 dic-search-result-count">
								검색된 유의어 목록 (총 {searchCount}개)
							</span>
						</div>
					</div>

					<Accordion className="ml-2">
						{gubnItems.length > 0 &&
							wordSynItems.length > 0 &&
							gubnItems.map((gubn, index) => {
								const synFirstItems = wordSynItems.filter((item) => gubn.GUBN === item.GUBN);
								//const limitCount = synFirstItems.length > 15 ? 5 : (synFirstItems.length > 8 ? 3 : 2);
								const limitCount = 2;
								return (
									<Fragment key={index}>
										<Accordion.Title
											active={activeIndex === index}
											index={index}
											onClick={this.handleClick}>
											{activeIndex === index && (
												<Label circular size="tiny" className="btn-group-label-color">
													<Icon
														size="tiny"
														name="minus"
														className="m-0 btn-group-icon-color"
													/>
												</Label>
											)}
											{activeIndex !== index && (
												<Label circular size="tiny" className="btn-group-label-color">
													<Icon
														size="tiny"
														name="plus"
														className="m-0 btn-group-icon-color"
													/>
												</Label>
											)}
											<span className="ml-2 text-11 font-600">{gubn.GUBN}</span>
											<Label circular size="small" className="ml-2">
												&nbsp;&nbsp;{synFirstItems.length}&nbsp;&nbsp;
											</Label>
											{/*}
                                            <Button as='div' labelPosition='right' size='small'>
                                                <Button icon basic color='blue' className='pl-3 pr-3'>
                                                    {gubn.GUBN}
                                                </Button>
                                                <Label as='a' basic color='blue' pointing='left'>
                                                    {synFirstItems.length}
                                                </Label>
                                            </Button>
                                            */}
										</Accordion.Title>
										<Accordion.Content active={activeIndex === index}>
											<div className="dp-flex-wrap">
												{synFirstItems.map((firstSyn, i) => {
													if ((user === undefined || user === null) && i < 1) {
														return (
															<Fragment key={'item_' + i}>
																{firstSyn.WORD_ID !== undefined &&
																	firstSyn.WORD_ID !== null && (
																		<Link
																			to={{
																				pathname: `${linkUrl}${firstSyn.WORD_ID}`,
																				state: {
																					word: firstSyn.WORD,
																					hanja: '',
																					linkUrl: linkUrl,
																				},
																			}}
																			className="cursor-pointer">
																			<Image
																				alt=""
																				className="label-result-box"
																				src={commonUtil.convertTextToImageForResult(
																					firstSyn.WORD
																				)}
																			/>
																		</Link>
																	)}
																{(firstSyn.WORD_ID === undefined ||
																	firstSyn.WORD_ID === null) && (
																	<span className="label-result-box text-mute">
																		{firstSyn.WORD}
																	</span>
																)}
															</Fragment>
														);
													} else if (
														user !== undefined &&
														user !== null &&
														user.status !== 'valid' &&
														i < limitCount
													) {
														return (
															<Fragment key={'item_' + i}>
																{firstSyn.WORD_ID !== undefined &&
																	firstSyn.WORD_ID !== null && (
																		<Link
																			to={{
																				pathname: `${linkUrl}${firstSyn.WORD_ID}`,
																				state: {
																					word: firstSyn.WORD,
																					hanja: '',
																					linkUrl: linkUrl,
																				},
																			}}
																			className="cursor-pointer">
																			<Image
																				alt=""
																				className="label-result-box"
																				src={commonUtil.convertTextToImageForResult(
																					firstSyn.WORD
																				)}
																			/>
																		</Link>
																	)}
																{(firstSyn.WORD_ID === undefined ||
																	firstSyn.WORD_ID === null) && (
																	<span className="label-result-box text-mute">
																		{firstSyn.WORD}
																	</span>
																)}
															</Fragment>
														);
													} else if (
														user !== undefined &&
														user !== null &&
														user.status === 'valid'
													) {
														return (
															<Fragment key={'item_' + i}>
																{firstSyn.WORD_ID !== undefined &&
																	firstSyn.WORD_ID !== null && (
																		<Link
																			to={{
																				pathname: `${linkUrl}${firstSyn.WORD_ID}`,
																				state: {
																					word: firstSyn.WORD,
																					hanja: '',
																					linkUrl: linkUrl,
																				},
																			}}
																			className="cursor-pointer">
																			<Image
																				alt=""
																				className="label-result-box"
																				src={commonUtil.convertTextToImageForResult(
																					firstSyn.WORD
																				)}
																			/>
																		</Link>
																	)}
																{(firstSyn.WORD_ID === undefined ||
																	firstSyn.WORD_ID === null) && (
																	<span className="label-result-box text-mute">
																		{firstSyn.WORD}
																	</span>
																)}
															</Fragment>
														);
													}
												})}
												{(user === undefined || user === null) && synFirstItems.length > 1 && (
													<Label
														className="ml-2 label-radius-7"
														as="a"
														basic
														size="tiny"
														color="orange"
														onClick={this.handleClickOpenJoin}>
														<Icon name="add" />
														<span className="text-09">{synFirstItems.length - 1}</span>
													</Label>
												)}
												{user !== undefined &&
													user !== null &&
													user.status !== 'valid' &&
													synFirstItems.length - limitCount > 0 && (
														<Label
															className="ml-2 label-radius-7"
															as="a"
															basic
															size="tiny"
															color="orange"
															onClick={this.handleClickOpenPayment}>
															<Icon name="add" />
															<span className="text-09">
																{synFirstItems.length - limitCount}
															</span>
														</Label>
													)}
											</div>
										</Accordion.Content>
									</Fragment>
								);
							})}
					</Accordion>

					<div className="row mt-5 mb-2">
						<div className="col">
							<div className="text-center">
								<Grid centered>
									<Grid.Column mobile={12} tablet={5} computer={5} className="pb-0">
										<button
											className="btn-border-radius-none"
											onClick={() => this.handleClickOpenInfo()}>
											낱말창고 사전보기
										</button>
									</Grid.Column>
									<Grid.Column mobile={12} tablet={5} computer={5} className="pb-0">
										<button
											className="btn-border-radius-none"
											onClick={() => this.handleClickOpenUrl('opendic')}>
											<span>우리말샘 뜻풀이 보기</span>
										</button>
									</Grid.Column>
								</Grid>
							</div>
						</div>
					</div>
				</div>
				<Modal size="small" open={openJoin} onClose={this.handleClickCloseJoin} className="custom-modal">
					<div className="pt-3 pl-3 text-13 font-600">
						<Icon name="user" />
						<span>회원가입</span>
					</div>
					<Divider />
					<div className="pl-3">
						<p>
							<span className="text-11">
								회원가입하시면 더 많은 유의어를 확인하실 수 있습니다. 지금 가입하시겠습니까?
							</span>
						</p>
					</div>
					<Divider />
					<div className="text-center mb-3">
						<Button color="red" onClick={this.handleClickCloseJoin}>
							<Icon name="remove" /> 취소
						</Button>
						<Button color="green" onClick={this.handleClickJoin}>
							<Icon name="checkmark" /> 회원가입
						</Button>
					</div>
				</Modal>
				<Modal size="small" open={openPayment} onClose={this.handleClickClosePayment} className="custom-modal">
					<div className="pt-3 pl-3 text-13 font-600">
						<Icon name="payment" />
						<span>이용권구매</span>
					</div>
					<Divider />
					<div className="pl-3">
						<p>
							<span className="text-11">
								유료회원이 되시면 더 많은 유의어를 확인하실 수 있습니다. 지금 유료회원으로
								전환하시겠습니까?
							</span>
						</p>
					</div>
					<Divider />
					<div className="text-center mb-3">
						<Button color="red" onClick={this.handleClickClosePayment}>
							<Icon name="remove" /> 취소
						</Button>
						<Button color="green" onClick={this.handleClickPayment}>
							<Icon name="checkmark" /> 이용권구매
						</Button>
					</div>
				</Modal>
				<Modal size="small" open={openInfo} onClose={this.handleClickCloseInfo} className="custom-modal">
					<div className="pt-3 pl-3 text-13 font-600">
						<Icon name="users" />
						<span>알림</span>
					</div>
					<Divider />
					<div className="pl-3">
						<p>
							<span className="text-14">
								'기본유의어사전' 사이트는 별개의 사이트이므로, 서비스 이용 시 별도의 회원가입이
								필요합니다.
							</span>
						</p>
						<p>
							<span className="text-14">페이지를 이동하시겠습니까?</span>
						</p>
					</div>
					<Divider />
					<div className="text-center mb-3">
						<Button color="red" onClick={this.handleClickCloseInfo}>
							<Icon name="remove" /> 아니오
						</Button>
						<Button color="green" onClick={() => this.handleClickOpenUrl('natmal')}>
							<Icon name="checkmark" /> 예
						</Button>
					</div>
				</Modal>
			</Fragment>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			searchDicPyojaeInfo: actions.searchDicPyojaeInfo,
			externalLink: actions.externalLink,
			logout: actions.logout,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchDetail);
