import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import { Icon } from 'semantic-ui-react';
import Validator, { Validation, Message } from 'es-validator';
import Toastr from 'modern-toastr';

import * as actions from 'actions';
import * as commonUtil from 'utils/common';

Toastr.setPosition('toast-top-center');

let mapStateToProps = state => {
	return {
		authenticated: state.auth.authenticated
	};
};

const mapDispatchToProps = dispatch => ({
	certify: user => dispatch(actions.certify(user)),
});

class AskCertEmail extends Component {
    
    constructor(props) {
        super(props);

        this.errorPrint = v => (
			<div key={v.key} className="valid-feedback">
				<span>{v.message}</span>
			</div>
        );
        
        this.validatorForCertify = new Validator({
			certifyEmail: {
				_name: '이메일',
				_hasText: { message: '이메일을 입력해 주세요.' },
				_email: { message: '이메일 형식이 올바르지 않습니다.' },
				_minLength: {
					min: 5,
					message: '이메일 형식이 올바르지 않습니다.',
				},
			}
        });
        
        const localEmail = commonUtil.getLoginEmail();
        const certify = {
			certifyEmail: localEmail,
		};
        //console.log(props.location.state);
        this.state = {			
			...certify,		
            vCertify: this.validatorForCertify.prepare(certify),
            path: props.location.state != null ? props.location.state.from.pathname : '/',							
        };        
    }

    onInputTextChange = input => e => {
		this.setState({
			[input]: e.target.value,
		});
    };
    
    handleKeyPress = (e) => {
        if(e.key === 'Enter') {
            this.onSubmit(e);
        }
    }

    onSubmit = (e) => {
        e.preventDefault();

        const { vCertify, ...state } = this.state;
        const { certify } = this.props;
        
        this.setState({
            vCertify: this.validatorForCertify.submit(state),
        });

        if (!this.validatorForCertify.getError()) {
            certify({ email: this.state.certifyEmail });
        } 
    }

    render() {
        const { vCertify, path } = this.state;
        const { certifyEmail } = vCertify;
        const { authenticated } = this.props;

        if (authenticated) {
            //console.log('로그인 되었습니다.');
			return <Redirect to={path} />;
		}

        return (
            <div className="container my-5">
                <div className='row'>
                    <div className="col">
                        <Link to='/join' className='btn btn-sm text-secondary'>
                            회원가입
                        </Link>
                    </div>
                    <div className="col-auto align-self-end">                        
                    </div>                        
                </div>
                <div className="row mb-5">
                    <div className="col-12 align-self-center text-center">
                        <h5>인증메일요청</h5>
                    </div>
                </div>
                <div className='row mb-2'>
                        <div className="col-12 align-self-center text-center">
                            <div className="ui negative message">
                                <ul className="list">                                    
                                    <li className="content">
                                        인증을 받기 위한 이메일 주소를 입력 후 요청하기 버튼을 눌러주세요.
                                    </li>
                                    <li className="content">
                                        인증 메일을 못 받으신 경우 스팸 메일함도 확인하시길 바랍니다.
                                    </li>
                                </ul>                                       
                            </div>
                        </div>
                    </div>
                <div className="row flex-colum">                    
                    <div className="col-12 mx-auto login-row">
                        <div className="align-self-center text-center">                                
                            <form className="form-signin">
                                <div className="row">
                                    <div className="col-auto align-self-center">
                                        <Icon name='mail' />
                                    </div>
                                    <div className="col pl-0">
                                        <Validation isValid={certifyEmail.error}>
                                            <div className="form-group float-label active">
                                                <input 
                                                    id="inputEmail"
                                                    name="email"
                                                    type="email" 
                                                    className="form-control" 
                                                    onChange={this.onInputTextChange('certifyEmail')}
                                                    value={this.state.certifyEmail}
                                                    required autoFocus 
                                                />
                                                <label htmlFor="inputEmail" className="form-control-label">Email address</label>
                                            </div>
                                            <Message once valid={certifyEmail} render={this.errorPrint} />
                                        </Validation>
                                    </div>
                                </div>                                    
                            </form>
                        </div>
                    </div>
                    <div className="col-12 mt-auto pb-4 mx-auto login-footer">                                                     
                        <div className="btn btn-lg btn-block btn-default position-relative" onClick={this.onSubmit}>
                            <span>인증메일 요청하기</span><Icon name='arrow right' className="right-absoute" />
                        </div>                        
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AskCertEmail);