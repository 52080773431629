import axios from 'axios';

import * as types from 'actions/ActionTypes';

export const selectMyCouponItems = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/coupon/list',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}

export const useMyCouponItem = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/coupon/use',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}