import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Icon, Form, Checkbox } from 'semantic-ui-react';
import Validator, { Validation, Message } from 'es-validator';
import Toastr from 'modern-toastr';

import Terms from './Terms';

import * as actions from 'actions';
import * as service from 'services/system';

Toastr.setPosition('toast-top-center');

let mapStateToProps = (state) => {
	return {
		registered: state.auth.registered,
		recommendUser: state.auth.recommendUser,
		recommendType: state.auth.recommendType,
	};
};

const mapDispatchToProps = (dispatch) => ({
	join: (user) => dispatch(actions.join(user)),
});

class Join extends Component {
	constructor(props) {
		super(props);

		this.errorPrint = (v) => (
			<div key={v.key} className="valid-feedback">
				<span>{v.message}</span>
			</div>
		);

		this.validatorForJoin = new Validator({
			name: {
				_name: '이름',
				_hasText: { message: '이름을 입력해 주세요.' },
			},
			joinEmail: {
				_name: '이메일',
				_hasText: { message: '이메일을 입력해 주세요.' },
				_email: { message: '이메일 형식이 올바르지 않습니다.' },
				_minLength: {
					min: 5,
					message: '이메일 형식이 올바르지 않습니다.',
				},
			},
			phone: {
				_name: '연락처',
				_hasText: { message: '연락처를 입력해 주세요.' },
				_int: { message: '숫자만 입력해 주세요.' },
				_minLength: {
					min: 9,
					message: '9자 이상의 연락처를 입력해 주세요.',
				},
			},
			password1: {
				_name: '암호',
				_hasText: { message: '비밀번호를 입력해주세요.' },
				_minLength: {
					min: 8,
					message: '8자 이상의 비밀번호를 입력해 주세요.',
				},
			},
			password2: {
				_name: '암호확인',
				_hasText: { message: '비밀번호를 입력해주세요.' },
				_makeFunc: {
					func: (value, state) => value === state.password1,
					message: '암호가 일치하지 않습니다.',
				},
			},
		});

		const join = {
			name: '',
			joinEmail: '',
			phone: '',
			password1: '',
			password2: '',
			checkedAgree: false,
			checkedTerms: false,
		};

		this.state = {
			...join,
			vJoin: this.validatorForJoin.prepare(join),
			open: false,
			recommendCheck: false,
			recommendUserId: 0,
			recommendUser: props.recommendUser,
			recommendType: props.recommendType,
		};
	}

	handleClickCheckRecommender = () => {
		//console.log(this.state.recommendUser);
		//Toastr.info('해당 서비스는 정식오픈 이후에 이용 가능합니다.');
		if (
			this.state.recommendUser !== undefined &&
			this.state.recommendUser !== null &&
			this.state.recommendUser.trim() !== ''
		) {
			this.fetchCheckRecommendUserInfo({
				recommendUser: this.state.recommendUser,
			});
		} else {
			Toastr.error(
				'추천인을 입력하고 추천인 확인 버튼을 클릭해 주세요. \n 추천인이 없다면 추천인 이메일 입력란을 공란으로 두고 \n 가입하기 버튼을 클릭해 주세요.'
			);
		}
	};

	fetchCheckRecommendUserInfo = async (params) => {
		const recommendInfo = await service.checkRecommendUserInfo(params);

		if (recommendInfo !== null) {
			if (recommendInfo.status === 200) {
				//console.log(recommendInfo);
				Toastr.info('추천인 확인이 정상적으로 완료되었습니다.');
				this.setState({
					recommendUserId: recommendInfo.data.userInfo,
					recommendCheck: true,
				});
			} else {
				Toastr.error(recommendInfo.data.message);
				this.setState({
					recommendUserId: 0,
					recommendCheck: false,
				});
			}
		} else {
			Toastr.error('추천인 정보가 올바르지 않습니다. 확인 후 다시 시도해 주세요.');
			this.setState({
				recommendUserId: 0,
				recommendCheck: false,
			});
		}
	};

	onSubmit = (e) => {
		e.preventDefault();

		const { vJoin, ...state } = this.state;
		const { join } = this.props;

		this.setState({
			vJoin: this.validatorForJoin.submit(state),
		});

		if (!this.validatorForJoin.getError()) {
			//console.log(this.state.recommendUser);
			//console.log(this.state.recommendCheck);

			if (
				this.state.recommendUser !== undefined &&
				this.state.recommendUser !== null &&
				this.state.recommendUser.trim() !== ''
			) {
				if (!this.state.recommendCheck) {
					Toastr.error('추천인을 입력한 경우 추천인 확인을 클릭해 주세요.');
					return;
				}
			}

			if (this.state.checkedAgree) {
				if (this.state.checkedTerms) {
					join({
						name: this.state.name,
						email: this.state.joinEmail,
						phone: this.state.phone,
						password: this.state.password1,
						recommendUserId: this.state.recommendUserId,
						recommendUser: this.state.recommendUser,
						recommendType: this.state.recommendType !== '' ? this.state.recommendType : 'NORMAL',
					});
				} else {
					Toastr.error('이용 약관을 확인해 주세요.');
				}
			} else {
				Toastr.error('서비스 제공을 위한 개인정보 이용에 동의해 주세요.');
			}
		}
	};

	handleClickOpen = () => {
		this.setState({
			open: true,
			checkedTerms: true,
		});
	};

	handleClose = () => {
		this.setState({
			open: false,
		});
	};

	onInputTextChange = (input) => (e) => {
		this.setState({
			[input]: e.target.value,
		});
	};

	onChangeCheckedToBoolean = (e, { checked }) => {
		this.setState({ checkedAgree: checked });
	};

	render() {
		const { vJoin, recommendUser } = this.state;
		const { name, joinEmail, phone, password1, password2, checkedAgree } = vJoin;
		const { registered } = this.props;

		if (registered) {
			//console.log('등록완료');
			return <Redirect to={'/login'} />;
		}
		return (
			<Fragment>
				<div className="container my-5">
					<div className="row mb-3">
						<div className="col-12 align-self-center text-center">
							<h5>회원가입 (Register)</h5>
						</div>
					</div>
					<div className="row mb-2">
						<div className="col-12 align-self-center text-center">
							<div className="ui negative message">
								<ul className="list">
									<li className="content">비밀번호는 8자 이상입니다.</li>
									<li className="content">사용자 확인을 위해 이메일 인증이 필요합니다.</li>
									<li className="content">
										인증 메일을 못 받으신 경우 스팸 메일함도 확인하시길 바랍니다.
									</li>
									<li className="content">
										추천인 이메일이 있는 경우에만 추천인 이메일 기입 후 추천인 확인 버튼을 클릭해
										주세요.
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row flex-colum">
						<div className="col-12 mx-auto login-row">
							<div className="align-self-center text-center">
								<form className="form-signin">
									<div className="row">
										<div className="col-auto align-self-center">
											<Icon name="user circle" />
										</div>
										<div className="col pl-0">
											<Validation isValid={name.error}>
												<div className="form-group float-label active">
													<input
														id="inputusername"
														name="name"
														type="text"
														className="form-control"
														onChange={this.onInputTextChange('name')}
														value={this.state.name}
														required
														autoFocus
													/>
													<label htmlFor="inputusername" className="form-control-label">
														사용자명
													</label>
												</div>
												<Message once valid={name} render={this.errorPrint} />
											</Validation>
										</div>
									</div>
									<div className="row">
										<div className="col-auto align-self-center">
											<Icon name="mail" />
										</div>
										<div className="col pl-0">
											<Validation isValid={joinEmail.error}>
												<div className="form-group float-label">
													<input
														id="inputEmail"
														name="joinEmail"
														type="email"
														className="form-control"
														onChange={this.onInputTextChange('joinEmail')}
														value={this.state.joinEmail}
														required
													/>
													<label htmlFor="inputEmail" className="form-control-label">
														사용자 이메일
													</label>
												</div>
												<Message once valid={joinEmail} render={this.errorPrint} />
											</Validation>
										</div>
									</div>
									<div className="row">
										<div className="col-auto align-self-center">
											<Icon name="phone" />
										</div>
										<div className="col pl-0">
											<Validation isValid={phone.error}>
												<div className="form-group float-label">
													<input
														id="inputPhone"
														name="phone"
														type="text"
														className="form-control"
														onChange={this.onInputTextChange('phone')}
														value={this.state.phone}
														required
													/>
													<label htmlFor="inputEmail" className="form-control-label">
														사용자 연락처 ('-' 제외)
													</label>
												</div>
												<Message once valid={phone} render={this.errorPrint} />
											</Validation>
										</div>
									</div>
									<div className="row">
										<div className="col-auto align-self-center">
											<Icon name="lock" />
										</div>
										<div className="col pl-0">
											<Validation isValid={password1.error}>
												<div className="form-group float-label">
													<input
														id="inputPassword1"
														name="password1"
														type="password"
														className="form-control"
														autoComplete="on"
														onChange={this.onInputTextChange('password1')}
														value={this.state.password1}
														required
													/>
													<label htmlFor="inputPassword1" className="form-control-label">
														비밀번호
													</label>
												</div>
												<Message once valid={password1} render={this.errorPrint} />
											</Validation>
										</div>
									</div>
									<div className="row">
										<div className="col-auto align-self-center">
											<Icon name="key" />
										</div>
										<div className="col pl-0">
											<Validation isValid={password2.error}>
												<div className="form-group float-label">
													<input
														id="inputPassword2"
														name="password2"
														type="password"
														className="form-control"
														autoComplete="on"
														onChange={this.onInputTextChange('password2')}
														value={this.state.password2}
														required
													/>
													<label htmlFor="inputPassword2" className="form-control-label">
														비밀번호확인
													</label>
												</div>
												<Message once valid={password2} render={this.errorPrint} />
											</Validation>
										</div>
									</div>

									<div className="row">
										<div className="col-auto align-self-center">
											<Icon name="comments" />
										</div>

										<div className="col pl-0">
											<div className="form-group float-label">
												<input
													id="inputRecommendUser"
													name="recommendUser"
													type="text"
													className="form-control"
													autoComplete="on"
													onChange={this.onInputTextChange('recommendUser')}
													value={this.state.recommendUser}
													required
												/>
												<label htmlFor="inputRecommendUser" className="form-control-label">
													추천인 이메일
												</label>
											</div>
										</div>
										<div className="col-auto">
											<div
												className="btn btn-default position-relative"
												onClick={this.handleClickCheckRecommender}>
												<span>추천인 확인</span>
											</div>
										</div>
									</div>
								</form>
								<Form>
									<Form.Field>
										<Checkbox
											name="checkedAgree"
											label="사이트 공지 및 서비스 제공과 관련한 목적으로 개인정보를 이용하는데 동의합니다."
											checked={checkedAgree}
											onChange={this.onChangeCheckedToBoolean}
										/>
									</Form.Field>
								</Form>
								<p className="mt-1 text-center">
									<span className="text-mute">이용약관을 꼭 확인해 주세요.</span>{' '}
									<span className="ml-1 text-underline" onClick={this.handleClickOpen}>
										약관보기
									</span>
								</p>
							</div>
						</div>
						<div className="col-12 mt-5 pb-1 mx-auto">
							<div
								className="btn btn-lg btn-block btn-default text-uppercase position-relative"
								onClick={this.onSubmit}>
								<span>가입하기</span>
								<Icon name="arrow right" className="right-absoute" />
							</div>
						</div>
						<div className="col-12 mt-5">
							<Link to="/login" className="btn btn-lg btn-block btn-link text-secondary">
								<span>이미 회원가입 하셨나요? </span>{' '}
								<span className="ml-1 text-underline">로그인하기</span>
							</Link>
						</div>
					</div>
				</div>
				<Terms isOpen={this.state.open} handleClose={this.handleClose} />
			</Fragment>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Join);
