import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Tab, Label, Image } from 'semantic-ui-react'

class MakeIcon extends Component {    
    render() {
        const panes = [
            { menuItem: '아이폰용', render: () => 
                <Tab.Pane>
                    <div className='row'>
                        <div className='col'>
                            <Label color='teal'>1</Label> <span className='ml-1'>사파리 어플을 실행하여 m.wordnet.co.kr에 접속하기</span>
                            <Image className='mt-2' src='http://m.wordnet.co.kr/assets/images/makeIcon/makeIcon_iPhone01.png' size='medium' bordered />
                        </div>
                    </div>

                    <div className='row mt-5'>
                        <div className='col'>
                            <Label color='teal'>2</Label> <span className='ml-1'>화면 하단의 화살표가 그려진 아이콘 클릭하기</span>
                            <Image className='mt-2' src='http://m.wordnet.co.kr/assets/images/makeIcon/makeIcon_iPhone02.png' size='medium' bordered />
                        </div>
                    </div>

                    <div className='row mt-5'>
                        <div className='col'>
                            <Label color='teal'>3</Label> <span className='ml-1'>'홈 화면에 추가' 메뉴 클릭하기</span>
                            <Image className='mt-2' src='http://m.wordnet.co.kr/assets/images/makeIcon/makeIcon_iPhone03.png' size='medium' bordered />
                        </div>
                    </div>

                    <div className='row mt-5'>
                        <div className='col'>
                            <Label color='teal'>4</Label> <span className='ml-1'>기기 화면에 생성된 아이콘 확인하기</span>
                            <Image className='mt-2' src='http://m.wordnet.co.kr/assets/images/makeIcon/makeIcon_iPhone04.png' size='medium' bordered />
                        </div>
                    </div>                     
                </Tab.Pane>
            },
            { menuItem: '안드로이드용', render: () => 
                <Tab.Pane>
                    <div className='row'>
                        <div className='col'>
                            <Label color='teal'>1</Label> <span className='ml-1'>크롬 어플을 실행하여 m.wordnet.co.kr에 접속한 후 오른쪽 상단의 메뉴 누르기</span>
                            <Image className='mt-2' src='http://m.wordnet.co.kr/assets/images/makeIcon/makeIcon_Android01.png' size='medium' bordered />
                        </div>
                    </div>

                    <div className='row mt-5'>
                        <div className='col'>
                            <Label color='teal'>2</Label> <span className='ml-1'>'홈 화면에 추가' 메뉴 클릭하기</span>
                            <Image className='mt-2' src='http://m.wordnet.co.kr/assets/images/makeIcon/makeIcon_Android02.png' size='medium' bordered />
                        </div>
                    </div>

                    <div className='row mt-5'>
                        <div className='col'>
                            <Label color='teal'>3</Label> <span className='ml-1'>아이콘 이름 설정 및 화면에 추가하기</span>
                            <Image className='mt-2' src='http://m.wordnet.co.kr/assets/images/makeIcon/makeIcon_Android03.png' size='medium' bordered />
                        </div>
                    </div>

                    <div className='row mt-5'>
                        <div className='col'>
                            <Label color='teal'>4</Label> <span className='ml-1'>기기 화면에 생성된 아이콘 확인하기</span>
                            <Image className='mt-2' src='http://m.wordnet.co.kr/assets/images/makeIcon/makeIcon_Android04.png' size='medium' bordered />
                        </div>
                    </div>
                </Tab.Pane> 
            },
        ]
        return (
            <div className='container my-5'>
                <div className="row mb-4">
                    <div className="col text-uppercase">
                        <h4 className="mb-0">모바일 바로가기 아이콘 만들기</h4>
                    </div>
                    <div className="col-auto align-self-end"></div>
                </div>

                <div className='row mt-4'>
                    <div className='col-12 mb-4'>
                        <div className="ui info message">
                            <ul className="list">										
                                <li className="content">
                                    각 모바일기기에 맞는 <span className='font-700'>바로가기 아이콘</span> 만들어 보세요.
                                </li>	
                                <li className="content">
                                    검색창에 <span className='font-700'>주소 입력 없이 언제든지 편리하게</span> 이용하실 수 있습니다.
                                </li>
                                <li className="content">
                                    사용하시는 기기 및 OS버전에 따라 생성 방법이 다를 수 있습니다.
                                </li>	                                								
                            </ul>
                        </div>
                    </div>
                </div>

                <Tab panes={panes} />                                 
            </div>
                
        );
    }
}

export default withRouter(MakeIcon);