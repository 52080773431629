import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import { Icon } from 'semantic-ui-react';
import Validator, { Validation, Message } from 'es-validator';
import Toastr from 'modern-toastr';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import * as actions from 'actions';

Toastr.setPosition('toast-top-center');

let mapStateToProps = state => {
	return {
		changed: state.auth.changed,
	};
};

const mapDispatchToProps = dispatch => ({
	change: user => dispatch(actions.change(user)),
	changing: changed => dispatch(actions.setChangedPw(changed)),
});

class ChangeCode extends Component {
    
    constructor(props) {
        super(props);

        const parsedSearch = queryString.parse(props.location.search);

        if (
			parsedSearch.id === undefined ||
			parsedSearch.key === undefined ||
			parsedSearch.id === null ||
			parsedSearch.key === null ||
			parsedSearch.id === '' ||
			parsedSearch.key === ''
		) {
			Toastr.error('허가되지 않는 경로로 접근하였습니다. 로그인 후 이용해 주시기 바랍니다.');
			this.props.history.push('/login');
		}

        this.errorPrint = v => (
			<div key={v.key} className="valid-feedback">
				<span>{v.message}</span>
			</div>
        );
        
        this.validatorForChangePassword = new Validator({
			password1: {
				_name: '암호',
				_hasText: { message: '비밀번호를 입력해주세요.' },
				_minLength: {
					min: 8,
					message: '8자 이상의 비밀번호를 입력해 주세요.',
				}				
			},
			password2: {
				_name: '암호확인',
				_hasText: { message: '비밀번호를 입력해주세요.' },
				_makeFunc: {
					func: (value, state) => value === state.password1,
					message: '암호가 일치하지 않습니다.',
				}
			},
		});
        
        const change = {
			password1: '',
			password2: '',
        };
        
        this.state = {			
            ...change,	
            changed: props.changed,	
            secretId: parsedSearch.id,
			secretKey: parsedSearch.key,	
            vChange: this.validatorForChangePassword.prepare(change),
        };        
    }

    static getDerivedStateFromProps(nextProps, prevState) {        
        if(nextProps.changed !== prevState.changed) {
            //console.log(nextProps.changed, prevState.changed);
            return { 
                changed: nextProps.changed               
            };
        }        
        return null;
    }

    onInputTextChange = input => e => {
		this.setState({
			[input]: e.target.value,
		});
    };
    
    handleKeyPress = (e) => {
        if(e.key === 'Enter') {
            this.onSubmit(e);
        }
    }

    onSubmit = (e) => {
        e.preventDefault();

        const { vChange, ...state } = this.state;
		const { change } = this.props;

		this.setState({
			vChange: this.validatorForChangePassword.submit(state),
		});

		if (!this.validatorForChangePassword.getError()) {
			change({
				secretId: this.state.secretId,
				secretKey: this.state.secretKey,
				password: this.state.password1,
			});
		} 
    }

    render() {
        const { vChange,changed } = this.state;
        const { password1, password2 } = vChange;
        const { changing } = this.props;
        
        if(changed) {
            changing(false);
            return <Redirect to={'/login'} />
        }

        return (
            <div className="container my-5">
                <div className='row'>
                    <div className="col-auto align-self-start">
                        <Link to='/login' className='btn btn-sm btn-block btn-link text-secondary'>
                            로그인
                        </Link>
                    </div>
                    <div className="col"></div>
                    <div className="col-auto align-self-end">
                        <Link to='/recertify' className="btn btn-sm btn-block btn-link text-secondary">
                            인증메일요청
                        </Link>
                    </div>                        
                </div>
                <div className="row mb-5">
                    <div className="col-12 align-self-center text-center">
                        <h5>비밀번호 변경하기</h5>
                    </div>
                </div>
                <div className="row flex-colum">                    
                    <div className="col-12 mx-auto login-row">
                        <div className="align-self-center text-center">                                
                            <form className="form-signin">
                                <div className="row">
                                    <div className="col-auto align-self-center">
                                        <Icon name='lock' />
                                    </div>
                                    <div className="col pl-0">
                                        <Validation isValid={password1.error}>
                                            <div className="form-group float-label">
                                                <input 
                                                    id="inputPassword1"
                                                    name="password1"
                                                    type="password" 
                                                    className="form-control" 
                                                    autoComplete="on" 
                                                    onChange={this.onInputTextChange('password1')}
                                                    value={this.state.password1}
                                                    required />
                                                <label htmlFor="inputPassword1" className="form-control-label">Password</label>
                                            </div>
                                            <Message once valid={password1} render={this.errorPrint} />
                                        </Validation>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto align-self-center">
                                        <Icon name='key' />
                                    </div>
                                    <div className="col pl-0">
                                        <Validation isValid={password2.error}>
                                            <div className="form-group float-label">
                                                <input 
                                                    id="inputPassword2" 
                                                    name="password2"
                                                    type="password" 
                                                    className="form-control" 
                                                    autoComplete="on" 
                                                    onChange={this.onInputTextChange('password2')}
                                                    value={this.state.password2}
                                                    required 
                                                />
                                                <label htmlFor="inputPassword2" className="form-control-label">Repassword</label>
                                            </div>
                                            <Message once valid={password2} render={this.errorPrint} />
                                        </Validation>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-12 mt-auto pb-4 mx-auto login-footer">                                 
                        <div className="btn btn-lg btn-block btn-default position-relative" onClick={this.onSubmit}>
                            <span>비밀번호 변경하기</span><Icon name='arrow right' className="right-absoute" />
                        </div>                        
                    </div>
                </div>
            </div>
        )
    }
}

ChangeCode.propTypes = {
	changed: PropTypes.bool,
};

ChangeCode.defaultProps = {
	changed: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeCode);