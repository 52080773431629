import axios from 'axios';

import * as types from 'actions/ActionTypes';

export const selectOrderItems = (params) => {
    return axios.post(
        types.LOCAL_API_URL + '/api/dic/order/selectOrderItems',
        { params }, { withCredentials: true}
    ).then((res) => {
        return res;
    }).catch((err) => {
        return err.response;
    });
}