import React from 'react'
import { Button, Icon, Modal, Header } from 'semantic-ui-react'

const Terms = ({isOpen, handleClose}) => (
    <Modal
        open={isOpen}
        onClose={handleClose}
        closeIcon>
        <Header content='(주)낱말 사이트 이용 약관' className='custom-header'/>
        <Modal.Content scrolling className='colorBlack'>
            <div className="tabCnt open">
                <article className="prodCnt">
                    <h3 className="tit">제 1 장 총칙</h3>
                    <h4 className="subTit">제 1 조 (목적)</h4><br/>
                    <p>본 약관은 낱말이 제공하는 웹사이트 낱말의 인터넷서비스(이하 "서비스"라 함)의 이용에 관한 조건 및 절차와 기타 필요한 사항을 규정하는 것을 목적으로 합니다.</p>
                    <h4 className="subTit">제 2 조 (약관의 효력과 변경)</h4><br/>
                    <p>이 약관의 내용은 변경할 수 있으며, 약관을 변경한 경우에는 공지사항을 통하여 공지함으로써 효력이 발생됩니다.</p>
                    <h4 className="subTit">제 3 조 (약관외 준칙)</h4><br/>
                    <p>이 약관에 명시되지 않은 사항이 관계 법령에 규정되어 있을 경우 그 규정에 따르며, 그렇지 않은 경우에는 일반적인 관례에 따릅니다.</p>
                    <h4 className="subTit">제 4 조 (용어의 정의)</h4><br/>
                    <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p><br/>
                    <ol>
                        <li> 회원 : 낱말과 서비스 사용에 관한 계약을 체결한 자.</li>
                        <li> 아이디(ID) : 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 낱말이 승인하는 문자와 숫자의 조합</li>
                        <li> 비밀번호 : 회원의 비밀 보호를 위해 회원 자신이 설정한 문자와 숫자의 조합</li>
                        <li> 해지 : 낱말 또는 회원이 서비스 개통 후 이용계약을 해약하는 것</li>
                    </ol>
                    
                    <h3 className="tit">제 2 장 서비스 이용 계약</h3>
                    <h4 className="subTit">제 5 조 (이용계약의 성립)</h4><br/>
                    <p>이용계약은 서비스 이용 신청자의 이용신청에 대하여 낱말이 승낙을 함으로써 성립합니다.</p>
                    <h4 className="subTit">제 6조 (이용신청)</h4><br/>
                    <ol>
                        <li> 서비스를 이용하고자 하는 자(개인 또는 단체)는 낱말이 지정한 소정의 가입신청 양식에 따라 온라인 신청을 이용하여 가입 신청을 해야 합니다.</li>
                        <li> 이용신청자가 14세 미만인 자(민법상의 행위무능력자 포함)일 경우에는 친권자(부모, 법정대리인 등)의 동의를 얻어 이용신청을 해야 합니다.</li>
                    </ol>
                    <h4 className="subTit">제 7 조 (회원탈퇴 및 자격의 상실)</h4><br/>
                    <ol>
                        <li> 회원이 이용계약을 해지하고자 하는 경우에는 회원 본인이 온라인 혹은 전자메일을 통해 낱말에 해지 신청을 하여야 합니다.</li>
                        <li> 회원이 다음 각호의 사유에 해당하는 경우, 낱말은 회원자격을 상실시킬 수 있습니다.</li>                        
                        <ul>
                            <li> 다른 사람의 명의를 사용하여 이용신청을 하였을 때</li>
                            <li> 이용계약 신청서의 내용을 허위로 기재하였을 때</li>
                            <li> 사회의 안녕, 질서 혹은 미풍양속을 저해할 목적으로 이용신청을 하였을 때</li>
                            <li> 본 약관에 위반한 경우</li>
                        </ul>                        
                    </ol>
                    
                    <h3 className="tit">제 3 장 서비스의 이용</h3>
                    <h4 className="subTit">제 8조 (서비스의 이용 개시)</h4><br/>
                    <ol>
                        <li> 낱말은 회원의 이용신청을 승낙한 시점부터 즉시 서비스를 개시합니다.</li>
                        <li> 회사의 업무상 또는 기술상의 장애로 인하여 서비스를 개시하지 못하는 경우에는 공지사항에 이를 즉시 공지합니다.</li>
                    </ol>
                    <h4 className="subTit">제 9 조 (서비스의 이용요금 및 사용시간)</h4><br/>
                    <p>낱말이 제공하는 서비스는 무료 또는 유료로 구분하여 제공합니다.</p>
                    <h4 className="subTit">제 10 조 (서비스 제공의 중지 및 제한)</h4><br/>
                    <ol>
                        <li> 낱말은 다음 각 호에 해당하는 경우 서비스를 중지할 수 있습니다.</li>                        
                        <ul>
                            <li> 서비스용 설비의 보수 또는 공사로 인한 부득이한 경우</li>
                            <li> 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 때</li>
                            <li> 천재지변에 의해 부득이한 경우</li>
                        </ul>                        
                        <li> 낱말은 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주등으로 서비스 이용에 지장이 있는 때에는 서비스 제공을 중지하거나 제한할 수 있습니다.</li>
                        <li> 낱말은 이용자가 다음 각 호에 해당하는 경우 사전통지없이 이용계약을 해지하거나 전부 또는 일부의 서비스 제공을 중지할 수 있습니다.</li>                        
                        <ul>
                            <li> 타인의 이용자번호를 사용한 경우</li>
                            <li> 다량의 정보를 전송하여 서비스의 안정적 운영을 방해하는 경우</li>
                            <li> 수신자의 의사에 반하는 광고성 정보, 전자우편을 전송하는 경우</li>
                            <li> 정보통신설비의 오작동이나 정보등의 파괴를 유발하는 컴퓨터 바이러스 프로그램 등을 유포하는 경우</li>
                            <li> 정보통신윤리위원회로부터의 이용제한 요구 대상인 경우</li>
                            <li> 선거관리위원회의 유권해석 상의 불법선거운동을 하는 경우</li>
                            <li> 서비스를 이용하여 얻은 정보를 교육정보원의 동의 없이 상업적으로 이용하는 경우</li>
                            <li> 전기통신관련법령등을 위반하는 행위를 하는 경우</li>
                            <li> 기타 교육정보원이 부적당하다고 판단한 경우</li>
                        </ul>
                        
                        <li> 전항의 규정에 의하여 이용자의 이용을 제한하는 경우와 제한의 종류 및 기간 등 구체적인 기준은 낱말의 공지, 서비스 이용안내 등에서 별도로 정한 바에 의합니다.</li>
                    </ol>
                    <h3 className="tit">제 4 장 권리와 의무</h3>
                    <h4 className="subTit">제 11조 (낱말의 의무)</h4><br/>
                    <ol>
                        <li> 낱말은 서비스를 항상 사용 가능한 상태로 유지해야 하며, 안정적으로 최상의 서비스를 제공할 수 있도록 노력해야 합니다.</li>
                        <li> 관계 법령에 의하여 수사상의 목적으로 관계기관으로부터 요구받은 경우, 정보통신 윤리위원회의 요청이 있는 경우, 기타 관계법령에 의한 경우 회사는 개인정보를 제공할 수 있습니다.</li>
                        <li> 회사는 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를 즉시 처리하여야하며, 즉시 처리가 곤란한 경우 그 사유와 처리일정을 서비스 또는 전자우편을 통하여 동 회원에게 통지하여야 합니다.</li>
                        <li> 회사는 서비스에 지장이 없도록, 관련 장비들을 정기적으로 유지, 보수하며, 장애가 발생할 경우 즉시 이를 수리,복구해야 합니다.</li>
                    </ol>
                    <h4 className="subTit">제 12 조 (회원의 의무)</h4><br/>
                    <ol>
                        <li> 회원은 관계 법령, 본 약관의 규정, 사용안내 및 서비스상에 공지한 주의사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.</li>
                        <li> 회원은 (주)낱말의 사전 동의 없이 서비스를 사용한 어떠한 영리행위도 할 수 없습니다.</li>
                        <li> 회원은 자신의 아이디와 비밀번호를 유지 관리할 책임이 있으며 자신의 아이디와 비밀번호를 사용하여 발생하는 모든 결과에 대해 전적인 책임이 있습니다. 또한 자신의 아이디와 비밀번호가 자신의 승낙 없이 사용되었을 경우 즉시 회사에 신고하여야 합니다.</li>
                        <li> 회원은 사용신청시의 기재내용에 대해 진실하고 정확하며 현재의 사실과 일치하도록 유지하고 갱신하여야 합니다.</li>
                        <li> 회원은 음란물 게재, 유포 등 사회질서를 해치는 행위를 할 수 없습니다.</li>
                        <li> 회원은 해킹 또는 컴퓨터 바이러스를 유포하는 일, 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위를 할 수 없습니다.</li>
                        <li> 회원은 서비스의 운영에 지장을 주거나 줄 우려가 있는 일체의 행위, 기타 관계 법령에 위배되는 일을 할 수 없습니다.</li>
                    </ol>
                    <h4 className="subTit">제 13 조 (손해배상)</h4><br/>
                    <p>(주)낱말이 제공하는 무료 서비스와 관련하여 회원에게 어떠한 손해가 발생하더라도 회사의 중대한 과실에 의한 경우를 제외하고 이에 대하여 책임을 부담하지 아니합니다.</p>
                    <h4 className="subTit">제 14 조 (면책 조항)</h4><br/>
                    <ol>
                        <li> (주)낱말은 회원이 서비스에 게재한 정보, 자료, 사실의 정확성, 신뢰성 등 내용에 관하여는 어떠한 책임도 부담하지 아니하고 서비스 자료에 대한 취사선택 또는 사용으로 발생하는 손해 등에 대해서는 책임이 면제됩니다.</li>
                        <li> 회원 아이디(ID)와 비밀번호의 관리 및 사용상의 부주의로 인하여 발생되는 손해 또는 제3자에 의한 부정사용 등에 대한 책임은 모두 회원에게 있습니다. </li>
                        <li> (주)낱말은 회원이 서비스를 사용하여 기대하는 손익이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임이 면제됩니다.</li>
                        <li> (주)낱말은 회원 상호간 또는 회원과 제 3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.</li>
                        <li> 회사는 회원의 귀책사유로 인하여 서비스 사용의 장애가 발생한 경우에는 책임이 면제됩니다.</li>
                        <li> 회원이 이 약관의 규정을 위반하여 (주)낱말이 회원 또는 제3자에 대하여 책임을 부담하게 되고, 이로써 회사에게 손해가 발생하게 되는 경우, 이 약관을 위반한 회원은 회사에게 발생하는 모든 손해를 배상하여야 하며, 동 손해로부터 회사를 면책시켜야 합니다.</li>
                    </ol>
                    <h4 className="subTit">제 15 조 (분쟁의 해결)</h4><br/>
                    <ol>
                        <li> 회사와 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.</li>
                        <li> 위 항의 규정에도 불구하고 소송이 제기될 경우, 소송은 회사가 관할하는 법원의 관할로 합니다.</li>
                    </ol>
                    <h3 className="tit">부 칙</h3>
                    <h4 className="subTit">제 1 조 (시행일)</h4><br/>
                    <p>이 약관은 2007년 7월 1일부터 시행합니다.</p>
                </article>
            </div>
        </Modal.Content> 
        <Modal.Actions>
            <Button primary onClick={handleClose}>
                닫기 <Icon name='chevron right' />
            </Button>
        </Modal.Actions>  
    </Modal>
)

export default Terms