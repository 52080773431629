import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Segment, Form, Input, Table, Responsive } from 'semantic-ui-react'

import Toastr from 'modern-toastr';

import * as moment from 'moment';
import * as service from 'services/system';

Toastr.setPosition('toast-top-center');

const mapStateToProps = state => ({
    user: state.auth.user
});

class UserStatisticSearchWord extends Component {       

    constructor(props) {
        super(props);

        this.state = {            
            topCount: props.topCount,
            resultData: []
        }
    }

    componentDidMount() {      
        const { topCount } = this.state;   
        this.fetchSelectSearchWordStatistic({
            searchCount: topCount,
        });        
    }
    
    /*
    static getDerivedStateFromProps(nextProps, prevState) {
        console.log(nextProps.topCount);
        if(nextProps.topCount !== prevState.topCount) {            
            return { 
                topCount: nextProps.topCount
            };
        }
        return null;
    }    
    */

    /*
    componentDidUpdate(prevProps, prevState) {    
        const { topCount } = this.state;     
        if(topCount !== prevState.topCount) {
            this.fetchSelectSearchWordStatistic({
                searchCount: topCount
            });          
        }
    }
    */

    searchOnKeyPress = e => {
		if (e.key === 'Enter') {
			this.handleClickSearchWordStatistic(e);
		}
    };

    onInputTextChange = (e, { name, value }) => {
        //console.log(name, value);
		//if (this.state.hasOwnProperty(name)) {
			this.setState({ topCount: value });
		//}		
    };

    handleClickSearchWordStatistic = (e) => {
        e.preventDefault();

        const { topCount } = this.state;   
        this.fetchSelectSearchWordStatistic({
            searchCount: topCount,
        });
    }

    fetchSelectSearchWordStatistic = async (params) => {

        const user = this.props.user;
        const statisticInfo = await service.selectSearchWordStatistic(params); 

        //console.log(statisticInfo);

        if(user !== undefined && user !== null && user.grade === 0) {
            if(statisticInfo !== null) {            
                if(statisticInfo.status === 200) {
                    if(statisticInfo.data) {
                        this.showSearchWordStatistic(statisticInfo.data.datas);    
                    } else {
                        
                    }             
                } else {
                    Toastr.error(statisticInfo.data.message);
                }
            } else {
                Toastr.error('사용자 통계 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
            }  
        }
    }

    showSearchWordStatistic(data) {
        //console.log(data);
        if(data.length > 0) {
            this.setState({
                resultData: data
            });
        }
    }
    
    render() {

        const { topCount, resultData } = this.state;

        return (           
            <Fragment>
                <div className='row mt-4'>
                    <div className='col-12 mb-4'>
                        <Form>
                            <Grid>							
                                <Grid.Row style={{ marginLeft: '0px' }}>
                                    <Grid.Column>
                                        <Segment>
                                            <Form.Field inline>
                                                <Form.Group widths={4} style={{ margin: 'auto' }}>                                                    
                                                    <Form.Field width={4}>
                                                        <Input
                                                            name="topCount"
                                                            fluid                                                   
                                                            icon="sort numeric down"
                                                            iconPosition="left"	
                                                            placeholder="검색순위"
                                                            value={topCount}
                                                            onKeyPress={this.searchOnKeyPress}
                                                            onChange={this.onInputTextChange}
                                                            action={{
                                                                size: 'tiny',
                                                                color: 'grey',
                                                                children: '검색',
                                                                onClick: this.handleClickSearchWordStatistic,
                                                            }}                                                    													
                                                        />
                                                    </Form.Field>                                                    
                                                </Form.Group>
                                            </Form.Field>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ marginLeft: '0px' }}>
                                    <Grid.Column>
                                        <Table
                                            key="black"
                                            fixed
                                            selectable
                                            textAlign="center"
                                            style={{ position: 'relative', padding: '0px', margin: '0px' }}>
                                            <Responsive as={Table.Header} minWidth={768}>
                                                <Table.Row>
                                                    <Table.HeaderCell width="2">No.</Table.HeaderCell>
                                                    <Table.HeaderCell width="8">단어</Table.HeaderCell>
                                                    <Table.HeaderCell width="6">검색수</Table.HeaderCell>                                                    
                                                </Table.Row>
                                            </Responsive>
                                            <Table.Body>
                                                { (resultData && resultData.length > 0) &&
                                                    resultData.map((item, index) => {
                                                        let searchWord = item.HANJA !== null ? item.WORD + item.HANJA : item.WORD;
                                                        return (
                                                            <Table.Row key={'word_' + index} className="cursor-pointer">
                                                                <Table.Cell>
                                                                    {index + 1}
                                                                </Table.Cell>
                                                                <Table.Cell textAlign='left'>
                                                                    {searchWord}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {item.CNT}
                                                                </Table.Cell>                                                                                                                          
                                                            </Table.Row>
                                                        );
                                                    })}
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </div>
                </div>
            </Fragment>      
        );
    }
}

export default connect(mapStateToProps, null)(withRouter(UserStatisticSearchWord));