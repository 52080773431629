import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { Message } from 'semantic-ui-react';

import * as actions from 'actions';

class History extends Component {

    constructor(props) {
        super(props);        

        this.state = {
            historyData: []            
        }
    }

    componentDidMount() {
        this.fetchGetSearchHistory();
    }

    handleOnClickHistoryItem(item) {     
        this.props.searchDicPyojaeInfo(item.word, 0);
    }

    fetchGetSearchHistory() {

        const historyName = 'baseDicSyn';
        let historyItems = window.sessionStorage.getItem(historyName);

        if(historyItems !== null) {
            historyItems = JSON.parse(historyItems);
            this.setState({
                historyData: historyItems.reverse()
            });
        } else {
            historyItems = [];
        }        
    }

    render() {
        const { historyData } = this.state;
        return (
            <div className='container my-5'>
                <div className="row mt-2 mb-2">
                    <div className='col'>
                        <span>최근 검색 이력 (최대 10개 까지)</span>                        
                    </div>
                </div>

                <div className="list-group list-group-flush my-0">
                    {
                        historyData.length === 0 &&
                        <Message className='mt-3 ml-2 mr-2' negative>
                            <Message.Header className='posi-relative'>검색한 이력이 존재하지 않습니다.</Message.Header>
                        </Message>
                    }

                    { historyData.length > 0 &&
                        historyData.map((item, index) => {
                            return(                                            
                                <div className="list-group-item" key={index}>
                                    <Link to={{
                                            pathname: `${item.linkUrl}${item.id}`,
                                            state: {
                                                word: item.word,
                                                hanja: item.hanja,
                                                linkUrl: item.linkUrl
                                            }
                                    }} onClick={() => this.handleOnClickHistoryItem(item)}>
                                        <div className="row">                                        
                                            <div className="col pl-0 align-self-center">
                                                <span className="font-weight-normal">{item.word} {item.hanja} </span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>                                            
                            );
                        })
                    }

                </div>
            </div>
                
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        searchDicPyojaeInfo: actions.searchDicPyojaeInfo   
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(withRouter(History));