import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Button, Form, Grid, Divider, Table, Icon, Responsive, Pagination, Segment, Label, Input } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import Toastr from 'modern-toastr';

import * as moment from 'moment';
import * as service from 'services/system';
import * as systemTypes from 'types/systemTypes';

Toastr.setPosition('toast-top-center');

const mapStateToProps = state => ({
	user: state.auth.user,
});

class CouponGift extends Component {
    
    constructor(props) {
        super(props);

        const coupon = {
            couponItem: null,             
            couponName: '',
            couponCode: '',
            couponIssue: '',
            couponExpired: ''                                 
        };

        const couponItem = (props.location.post !== undefined && props.location.post !== null) ? props.location.post.couponItem : null;
        //console.log(couponItem);
        coupon.couponItem = couponItem;
        coupon.couponName = (couponItem && couponItem.COUPON_CODE !== null) ? couponItem.COUPON_CODE : '';
        coupon.couponCode = (couponItem && couponItem.WordnetCouponGroup !== undefined && couponItem.WordnetCouponGroup !== null) ? couponItem.WordnetCouponGroup.COUPON_NAME.trim() : '';
        coupon.couponIssue = (couponItem && couponItem.ISSUE_DATE !== null) ? moment(couponItem.ISSUE_DATE).format('YYYY-MM-DD') : '';
        coupon.couponExpired = (couponItem && couponItem.EXPIRED_DATE !== null) ? moment(couponItem.EXPIRED_DATE).format('YYYY-MM-DD') : '';

        let searchColumnOption = systemTypes.SEARCH_OPT_COLNAME_LABEL.map(key => {
			let item = systemTypes.SEARCH_OPT_COLNAME.properties[key];
			return {
				key: item.id,
				text: item.label,
				value: item.value,
				style: { fontSize: '0.9em' },
			};
        });

        this.state = {			
            searchColumnOption,                    
            ...coupon, 
            userCol: 0,
            searchName: '',
            targetUserId: 0,
            targetUserEmail: '',
            targetUserName: '',
            resultData: [],
            currentPage: 1,
            pageSize: 5,
            totalPages: 0,
            totalElements: 0         
		};
    }

    handleOnClickGoBack = () => {
        this.props.history.goBack();
    }

    handleOnClickCol = (e, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {		
			this.setState({ [name]: value });
		}
    };

    searchOnKeyPress = e => {
		if (e.key === 'Enter') {
			this.searchKeywordOnClick(e);
		}
    };

    searchKeywordOnClick = e => {
        e.preventDefault();
        if(this.state.searchName !== '') {
            this.searchKeywordHandler();
        } else {
            Toastr.error('검색명을 입력해 주세요.');
            return;
        }        	
    };
    
    searchKeywordHandler = () => {
		const { pageSize, userCol, searchName, currentPage } = this.state;
		this.fetchSelectUsersInfo({ 
            userStatus: '', 
            userCol: userCol,
            searchName: searchName,
            pageSize: pageSize, 
            pageNum: currentPage, 
        });
    };

    paginationChange = (e, { activePage }) => {
		const { pageSize, userCol, searchName } = this.state;
		this.setState({ currentPage: activePage });
		this.fetchSelectUsersInfo({             
            userStatus: '', 
            userCol: userCol, 
            searchName: searchName,
            pageSize: pageSize, 
            pageNum: activePage, 
        });
    };
    
    onInputTextChange = (e, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {
			this.setState({ [name]: value });
		}
		this.setState({ isWarning: false });
    };

    handleOnClickUser = (e, userItem) => {
        e.preventDefault();        
        this.setState({
            targetUserId: userItem.ID,
            targetUserEmail: userItem.USEREMAIL,
            targetUserName: userItem.USERNAME,
        });
    }

    handleOnClickPresent = () => {
        const user = this.props.user;
        const { couponItem, targetUserId } = this.state;

        if(user !== undefined && user !== null) {
            if(couponItem !== undefined && couponItem !== null && targetUserId !== 0) {                
                this.fetchPresentCoupon({
                    couponId: couponItem.ID,
                    userId: user.id,
                    targetUserId: targetUserId,
                    status: 'PRESENT'
                });
            } else {
                Toastr.error('쿠폰 및 대상자의 정보가 올바르지 않습니다. 확인 후 다시 시도해 주세요.');
            }
        } else {

        }
    }

    fetchPresentCoupon = async(params) => {

        const couponItem = await service.presentCouponItem(params);

        if(couponItem !== null) {            
            if(couponItem.status === 200) {
                Toastr.info('쿠폰 선물하기가 완료되었습니다.');  
                this.handleOnClickGoBack();
            } else {
                Toastr.error('쿠폰을 선물하는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
            }
        } else {
            Toastr.error('쿠폰을 선물하는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
        } 
    }

    fetchSelectUsersInfo = async(params) => {

        const userItems = await service.selectUserItems(params);

        if(userItems !== null) {            
            if(userItems.status === 200) {
                if(userItems.data) {
                    this.showUserItems(userItems.data);    
                } else {
                    this.setState({            
                        resultData: [],
                        totalPage: 0,
                        totalElements: 0
                    });
                }             
            } else {
                Toastr.error('대상자 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
            }
        } else {
            Toastr.error('대상자 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
        } 
    }

    showUserItems(data) {
        const totalPage = Math.ceil(parseInt(data.searchTotal) / this.state.pageSize);
        this.setState({ 
            totalElements: data.searchTotal,             
            totalPages: totalPage, 
            resultData: data.datas 
        });
    }

    render() {

        const { couponName, couponCode, couponIssue, couponExpired } = this.state;
        const { searchColumnOption, userCol, searchName } = this.state;
        const { resultData, pageSize, totalPages, currentPage } = this.state;
        const { targetUserEmail, targetUserName } = this.state;

        let targetUser = targetUserName !== '' ? targetUserEmail + ' (' + targetUserName + ')' : targetUserEmail;

        return (
            <div className="container">
                <div className="row mb-4">
                    <div className='col'>
                        <h4 className="mb-0">쿠폰 선물하기</h4>
                    </div>
                </div>

                <div className="row">
					<div className="col-12 mb-4">
						<div className="row">
							<div className="col">
								<div className="ui negative message">
									<ul className="list">
                                        <li className="content">사용자 조회를 이용하여 선물하실 사용자를 검색하신 후 선택해 주세요.</li>
                                        <li className="content">쿠폰 선물하기는 완료 이후에 취소 및 변경이 불가합니다.</li>
                                        <li className="content">선물하기로 인해 쿠폰의 만료일은 변동되지 않습니다.</li> 
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>

                <div className='row mt-4'>
                    <div className='col-12 mb-4'>
                        <Form>
                            <Grid>
                                <Grid.Row style={{ marginLeft: '0px' }}>
                                    <Grid.Column>
                                        <Form.Group width={16}>
                                            <Form.Field width={8}>
                                                <Form.Input
                                                    label='쿠폰명'	
                                                    fluid											
                                                    name="couponName"
                                                    value={couponName}
                                                    readOnly
                                                />
                                            </Form.Field>
                                            <Form.Field width={8}>                                            
                                                <Form.Input	
                                                    label='쿠폰코드'
                                                    fluid											
                                                    name="couponCode"
                                                    value={couponCode}
                                                    readOnly
                                                />                                            
                                            </Form.Field>                                            
                                        </Form.Group>

                                        <Form.Group width={16}>
                                            <Form.Field width={8}>                                            
                                                <Form.Input	
                                                    label='발행일'
                                                    fluid											
                                                    name="couponIssue"
                                                    value={couponIssue}
                                                    readOnly
                                                />                                            
                                            </Form.Field>	
                                            
                                            <Form.Field width={8}>                                            
                                                <Form.Input	
                                                    label='만료일'
                                                    fluid											
                                                    name="couponExpired"
                                                    value={couponExpired}
                                                    readOnly
                                                />                                            
                                            </Form.Field>
                                        </Form.Group>

                                        <Form.Group width={16}>
                                            <Form.Field width={8}> 
                                                <Form.Input
                                                    label='선물받을 대상자'
                                                    name="targetUser"
                                                    fluid
                                                    action={{
                                                        size: 'tiny',
                                                        color: 'teal',
                                                        children: '선물하기',
                                                        onClick: this.handleOnClickPresent,
                                                    }}
                                                    icon="user"
                                                    placeholder="대상자를 선택해 주세요."
                                                    value={targetUser}                                                                                                               
                                                    iconPosition="left"														
                                                    readOnly
                                                />                                                                                                                                 
                                            </Form.Field>
                                            <Form.Field width={8}>                                                                                            	                                            
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid.Row>

                                <Divider horizontal>
                                    <h4>
                                        <Icon name='user'/>
                                        대상자 검색하기
                                    </h4>
                                </Divider>

                                <Grid.Row style={{ marginLeft: '0px' }}>
                                    <Grid.Column>
                                        <Segment clearing>
                                            <Form.Field inline>
                                                <Form.Group widths={4} style={{ margin: 'auto' }}>                                                    
                                                    <Form.Field width={4}>
                                                        <Dropdown	
                                                            selection													
                                                            name="userCol"
                                                            options={searchColumnOption}
                                                            placeholder="검색대상"
                                                            onChange={this.handleOnClickCol}
                                                            value={userCol}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field width={8}>
                                                        <Input
                                                            name="searchName"
                                                            fluid
                                                            action={{
                                                                size: 'tiny',
                                                                color: 'grey',
                                                                children: '검색',
                                                                onClick: this.searchKeywordOnClick,
                                                            }}
                                                            icon="search"
                                                            placeholder="검색명"
                                                            value={searchName}
                                                            onKeyPress={this.searchOnKeyPress}
                                                            onChange={this.onInputTextChange}                                                            
                                                            iconPosition="left"														
                                                        />
                                                    </Form.Field>																					
                                                </Form.Group>
                                            </Form.Field>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ marginLeft: '0px' }}>
                                    <Grid.Column>
                                        <Table
                                            key="black"
                                            fixed
                                            selectable
                                            textAlign="center"
                                            style={{ position: 'relative', padding: '0px', margin: '0px' }}>
                                            <Responsive as={Table.Header} minWidth={768}>
                                                <Table.Row>
                                                    <Table.HeaderCell width="2">No.</Table.HeaderCell>
                                                    <Table.HeaderCell width="8">이메일</Table.HeaderCell>
                                                    <Table.HeaderCell width="6">사용자명</Table.HeaderCell>                                                    
                                                </Table.Row>
                                            </Responsive>
                                            <Table.Body>
                                                { (resultData && resultData.length > 0) &&
                                                    resultData.map((user, index) => {
                                                        return (
                                                            <Table.Row 
                                                                key={'user_' + index} className="cursor-pointer"
                                                                onClick={e => this.handleOnClickUser(e, user)}
                                                            >
                                                                <Table.Cell>
                                                                    {user.ID}
                                                                </Table.Cell>
                                                                <Table.Cell textAlign='left'>
                                                                    {user.USEREMAIL}
                                                                </Table.Cell>
                                                                <Table.Cell	singleLine>
                                                                    {user.USERNAME}
                                                                </Table.Cell>                                                                                                                          
                                                            </Table.Row>
                                                        );
                                                    })}
                                                {(resultData === undefined || resultData === null || resultData.length === 0) && (
                                                    <Table.Row>
                                                        <Table.Cell colSpan="3">회원 정보가 존재하지 않습니다. </Table.Cell>
                                                    </Table.Row>
                                                )}
                                                <Table.Row>
                                                    <Table.Cell colSpan="3" className="text-center">
                                                        {(resultData && resultData.length > 0) && (
                                                            <Pagination
                                                                activePage={currentPage}
                                                                ellipsisItem={{
                                                                    content: <Icon name="ellipsis horizontal" />,
                                                                    icon: true,
                                                                }}
                                                                firstItem={{
                                                                    content: <Icon name="angle double left" />,
                                                                    icon: true,
                                                                }}
                                                                lastItem={{
                                                                    content: <Icon name="angle double right" />,
                                                                    icon: true,
                                                                }}
                                                                prevItem={{
                                                                    content: <Icon name="angle left" />,
                                                                    icon: true,
                                                                }}
                                                                nextItem={{
                                                                    content: <Icon name="angle right" />,
                                                                    icon: true,
                                                                }}
                                                                boundaryRange={0}
                                                                siblingRange={2}
                                                                pointing
                                                                secondary
                                                                onPageChange={this.paginationChange}
                                                                totalPages={totalPages}
                                                            />
                                                        )}
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                </Grid.Row>                            				

                                <Grid.Row style={{ marginLeft: '0px' }}>
                                    <Grid.Column textAlign="center">
                                        <Button size="huge" onClick={this.handleOnClickGoBack}>
                                            이전
                                        </Button>                                        								
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>                             
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, null)(withRouter(CouponGift));