import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Router } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Dropdown, Button, Form, Grid, Divider, Table, Icon, Responsive, Pagination, Segment, Label, Input } from 'semantic-ui-react';
import Validator, { Validation, Message } from 'es-validator';

import Toastr from 'modern-toastr';

import * as moment from 'moment';
import * as service from 'services/system';
import * as systemTypes from 'types/systemTypes';

Toastr.setPosition('toast-top-center');

const mapStateToProps = state => ({
    user: state.auth.user
});

class MgrCouponDetail extends Component {
    
    constructor(props) {
        super(props);

        this.errorPrint = v => (
			<div key={v.key} className="valid-feedback">
				<span>{v.message}</span>
			</div>
        );

        const coupon = {
            couponItem: null, 
            couponType: '',           
            couponName: '',
            couponCode: '',
            couponStatus: '',
            couponUserId: 0,
            couponUser: '',
            couponIssueDate: '',
            couponUsedDate: ''                      
        };

        const couponItem = (props.location.post !== undefined && props.location.post !== null) ? props.location.post.couponItem : null;
        const couponType = (props.location.post !== undefined && props.location.post !== null) ? props.location.post.couponType : '';
        const couponName = (props.location.post !== undefined && props.location.post !== null) ? props.location.post.couponName : '';

        coupon.couponItem = couponItem;
        coupon.couponType = couponType;
        coupon.couponName = couponName;
        coupon.couponCode = (couponItem && couponItem.COUPON_CODE !== null) ? couponItem.COUPON_CODE : '';
        coupon.couponStatus = (couponItem && couponItem.STATUS !== null) ? couponItem.STATUS.trim() : '';
        coupon.couponUser = (couponItem && couponItem.USER_ID !== null) ? couponItem.USER_ID : '';
        coupon.couponIssueDate = (couponItem && couponItem.ISSUE_DATE !== null) ? couponItem.ISSUE_DATE : '';
        coupon.couponUsedDate = (couponItem && couponItem.USED_DATE !== null) ? couponItem.USED_DATE : '';

        let couponTypeOption = Object.keys(systemTypes.COUPON_TYPES).map(key => {
			let item = systemTypes.COUPON_TYPES[key];
			return {
				key: item.id,
				text: item.title,
				value: item.value,
				style: { fontSize: '0.9em' },
			};
        });

        let couponStatusOption = systemTypes.COUPON_STATUS_LABEL.map(key => {
			let item = systemTypes.COUPON_STATUS.properties[key];
			return {
				key: item.id,
				text: item.label,
				value: item.value,
				style: { fontSize: '0.9em' },
			};
        });

        let searchColumnOption = systemTypes.SEARCH_OPT_COLNAME_LABEL.map(key => {
			let item = systemTypes.SEARCH_OPT_COLNAME.properties[key];
			return {
				key: item.id,
				text: item.label,
				value: item.value,
				style: { fontSize: '0.9em' },
			};
        });
        
        this.validator = new Validator({
			couponType: {
				_name: '쿠폰상품명',
				_hasText: { message: '쿠폰상품명을 입력해 주세요.' },				
            },
            couponName: {
				_name: '쿠폰명',
				_hasText: { message: '쿠폰상품명을 입력해 주세요.' },				
            },
            couponCode: {
				_name: '쿠폰코드',
				_hasText: { message: '쿠폰코드를 입력해 주세요.' },				
            },
            couponStatus: {
				_name: '쿠폰상태',
				_hasText: { message: '쿠폰상태를 입력해 주세요.' },				
            },
            couponUser: {
				_name: '사용자',
				_hasText: { message: '사용자를 입력해 주세요.' },				
            },
            couponIssueDate: {
				_name: '발행일자',
				_hasText: { message: '발행일자를 입력해 주세요.' },				
			},			
        });

        this.state = {			
            couponTypeOption,  
            couponStatusOption,  
            searchColumnOption,                    
            ...coupon, 
            vCoupon: this.validator.prepare(coupon),
            userCol: '',
            searchName: '',
            resultData: [],
            currentPage: 1,
            pageSize: 10,
            totalPages: 0,
            totalElements: 0         
		};

    }

    handleOnClickGoBack = () => {
        this.props.history.goBack();
    }

    handleOnClickCol = (e, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {		
			this.setState({ [name]: value });
		}
    };
    
    searchOnKeyPress = e => {
		if (e.key === 'Enter') {
			this.searchKeywordOnClick(e);
		}
    };

    searchKeywordOnClick = e => {
		e.preventDefault();
		this.searchKeywordHandler();
    };
    
    searchKeywordHandler = () => {
		const { pageSize, userCol, searchName, currentPage } = this.state;

		this.fetchSelectUsersInfo({ 
            userStatus: '', 
            userCol: userCol,
            searchName: searchName,
            pageSize: pageSize, 
            pageNum: currentPage, 
        });
    };

    paginationChange = (e, { activePage }) => {
		const { pageSize, userCol, searchName } = this.state;
		this.setState({ currentPage: activePage });
		this.fetchSelectUsersInfo({             
            userStatus: '', 
            userCol: userCol, 
            searchName: searchName,
            pageSize: pageSize, 
            pageNum: activePage, 
        });
    };
    
    onInputTextChange = (e, { name, value }) => {
		if (this.state.hasOwnProperty(name)) {
			this.setState({ [name]: value });
		}
		this.setState({ isWarning: false });
    };

    handleOnClickUser = (e, userItem) => {
        e.preventDefault();
        //console.log(userItem);
        let currentTime = moment().format('YYYY-MM-DD')
        this.setState({
            couponStatus: 'USING',
            couponUserId: userItem.ID,
            couponUser: userItem.USEREMAIL,
            couponIssueDate: currentTime,
        });
    }

    fetchSelectUsersInfo = async(params) => {

        const userItems = await service.selectUserItems(params);

        if(userItems !== null) {            
            if(userItems.status === 200) {
                if(userItems.data) {
                    this.showUserItems(userItems.data);    
                } else {
                    this.setState({            
                        resultData: [],
                        totalPage: 0,
                        totalElements: 0
                    });
                }             
            } else {
                Toastr.error('사용자 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
            }
        } else {
            Toastr.error('사용자 정보를 불러오는 중에 오류가 발생하였습니다. 다시 시도해 주세요.');
        } 
    }

    showUserItems(data) {
        const totalPage = Math.ceil(parseInt(data.searchTotal) / this.state.pageSize);
        this.setState({ 
            totalElements: data.searchTotal,             
            totalPages: totalPage, 
            resultData: data.datas 
        });
    }

    onSumbitSetCoupon = e => {
        e.preventDefault();
        
        const user = this.props.user;
        const { vCoupon, ...state } = this.state;
        const { couponItem, couponUserId, couponStatus, couponIssueDate } = this.state;

        if(user !== undefined && user !== null && user.grade === 0) {
            this.setState({
                vCoupon: this.validator.submit(state),
            });

            if (!this.validator.getError()) {
                this.fetchUpdateCouponInfo({
                    couponId: couponItem.ID,
                    userId: couponUserId,
                    status: couponStatus,
                    issueDate: couponIssueDate
                });
            }
        }
    }

    fetchUpdateCouponInfo = async(params) => {
        //console.log(params);
        const couponItem = await service.updateCouponItem(params);

        if(couponItem !== null) {            
            if(couponItem.status === 200) {
                Toastr.info('쿠폰정보가 변경되었습니다.');
                this.handleOnClickGoBack();      
            } else {
                Toastr.error('쿠폰정보 변경중에 오류가 발생하였습니다. 다시 시도해 주세요.');
            }
        } else {
            Toastr.error('쿠폰정보 변경중에 오류가 발생하였습니다. 다시 시도해 주세요.');
        } 
    }

    render() {

        const { vCoupon } = this.state;
        const { couponStatusOption, searchColumnOption, resultData } = this.state;
        const { couponType, couponName, couponCode, couponStatus, couponUser, couponIssueDate, couponUsedDate } = this.state;
        const { userCol, searchName, currentPage, totalPages, totalElements } = this.state;

        return (
            <div className="container">
                <div className="row mb-4">
                    <div className='col'>
                        <h4 className="mb-0">쿠폰상세정보</h4>
                    </div>
                </div>                 
                <div className='row mt-4'>
                    <div className="col-12 mb-4">
                        <Divider />
                        <Form>
                            <Grid>							
                                <Grid.Row style={{ marginLeft: '0px' }}>
                                    <Grid.Column>
                                        <Form.Group width={16}>
                                            <Form.Field width={8}>                                            
                                                <Form.Input	
                                                    label='쿠폰상품명'
                                                    fluid											
                                                    name="couponType"
                                                    error={vCoupon.couponType.error}
                                                    value={couponType}
                                                    readOnly
                                                />                                            
                                            </Form.Field>	
                                            
                                            <Form.Field width={8}>
                                                <Form.Input
                                                    label='쿠폰명'	
                                                    fluid											
                                                    name="couponName"
                                                    error={vCoupon.couponName.error}
                                                    value={couponName}
                                                    readOnly
                                                />
                                            </Form.Field>
                                        </Form.Group>

                                        <Form.Group width={16}>
                                            <Form.Field width={8}>                                            
                                                <Form.Input	
                                                    label='쿠폰코드'
                                                    fluid											
                                                    name="couponCode"
                                                    error={vCoupon.couponCode.error}
                                                    value={couponCode}
                                                    readOnly
                                                />                                            
                                            </Form.Field>	
                                            
                                            <Form.Field width={8}>
                                                <label>쿠폰상태</label>
                                                <Dropdown	
                                                    selection													
                                                    name="couponStatus"
                                                    error={vCoupon.couponStatus.error}
                                                    options={couponStatusOption}
                                                    placeholder="쿠폰상태"
                                                    onChange={this.handleOnClickStatus}
                                                    value={couponStatus}
                                                />
                                            </Form.Field>
                                        </Form.Group>

                                        <Form.Group width={16}>
                                            <Form.Field width={8}> 
                                                <Form.Input	
                                                    label='사용자'
                                                    fluid
                                                    error={vCoupon.couponUser.error}									
                                                    name="couponUser"
                                                    value={couponUser}
                                                    readOnly
                                                />                                                                                            
                                            </Form.Field>	
                                            
                                            <Form.Field width={8}>
                                                <Form.Input
                                                    label='발행일자'	
                                                    fluid											
                                                    name="couponIssueDate"
                                                    error={vCoupon.couponIssueDate.error}
                                                    value={
                                                        couponIssueDate &&
                                                        moment(couponIssueDate).format('YYYY-MM-DD')
                                                    }
                                                    readOnly
                                                />
                                            </Form.Field>
                                        </Form.Group>

                                        <Form.Group width={16}>
                                            <Form.Field width={8}>                                            
                                                <Form.Input	
                                                    label='사용일자'
                                                    fluid											
                                                    name="couponUsedDate"
                                                    value={
                                                        couponUsedDate &&
                                                        moment(couponUsedDate).format('YYYY-MM-DD')
                                                    }
                                                    readOnly
                                                />                                            
                                            </Form.Field>
                                        </Form.Group>                                        
                                                                    
                                    </Grid.Column>
                                </Grid.Row>			
                                <Divider horizontal>
                                    <h4>
                                        <Icon name='user'/>
                                        쿠폰 사용자 조회
                                    </h4>
                                </Divider>
                                <Grid.Row style={{ marginLeft: '0px' }}>
                                    <Grid.Column>
                                        <Segment clearing>
                                            <Form.Field inline>
                                                <Form.Group widths={4} style={{ margin: 'auto' }}>                                                    
                                                    <Form.Field width={4}>
                                                        <Dropdown	
                                                            selection													
                                                            name="userCol"
                                                            options={searchColumnOption}
                                                            placeholder="검색대상"
                                                            onChange={this.handleOnClickCol}
                                                            value={userCol}
                                                        />
                                                    </Form.Field>
                                                    <Form.Field width={8}>
                                                        <Input
                                                            name="searchName"
                                                            fluid
                                                            action={{
                                                                size: 'tiny',
                                                                color: 'grey',
                                                                children: '검색',
                                                                onClick: this.searchKeywordOnClick,
                                                            }}
                                                            icon="search"
                                                            placeholder="검색명"
                                                            value={searchName}
                                                            onKeyPress={this.searchOnKeyPress}
                                                            onChange={this.onInputTextChange}                                                            
                                                            iconPosition="left"														
                                                        />
                                                    </Form.Field>																					
                                                </Form.Group>
                                            </Form.Field>
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ marginLeft: '0px' }}>
                                    <Grid.Column>
                                        <Table
                                            key="black"
                                            fixed
                                            selectable
                                            textAlign="center"
                                            style={{ position: 'relative', padding: '0px', margin: '0px' }}>
                                            <Responsive as={Table.Header} minWidth={768}>
                                                <Table.Row>
                                                    <Table.HeaderCell width="2">No.</Table.HeaderCell>
                                                    <Table.HeaderCell width="4">이메일</Table.HeaderCell>
                                                    <Table.HeaderCell width="2">사용자명</Table.HeaderCell>
                                                    <Table.HeaderCell width="2">사용등급</Table.HeaderCell>
                                                    <Table.HeaderCell width="2">가입일</Table.HeaderCell>
                                                    <Table.HeaderCell width="4"></Table.HeaderCell>
                                                </Table.Row>
                                            </Responsive>
                                            <Table.Body>
                                                { (resultData && resultData.length > 0) &&
                                                    resultData.map((user, index) => {

                                                        let userType ;
                                                        
                                                        if(user.STATUS && user.STATUS.trim() === 'START') {
                                                            userType = '기본';
                                                        } else {
                                                            userType = '무료';
                                                        } 

                                                        return (
                                                            <Table.Row 
                                                                key={'user_' + index} className="cursor-pointer"
                                                                onClick={e => this.handleOnClickUser(e, user)}
                                                            >
                                                                <Table.Cell>
                                                                    {user.ID}
                                                                </Table.Cell>
                                                                <Table.Cell textAlign='left'>
                                                                    {user.USEREMAIL}
                                                                </Table.Cell>
                                                                <Table.Cell	singleLine>
                                                                    {user.USERNAME}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {userType === '무료' && 
                                                                        <Label color='grey'>{userType}</Label>
                                                                    }
                                                                    {userType === '기본' && 
                                                                        <Label color='orange'>{userType}</Label>
                                                                    }                                                                    
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {moment(user.CREATED_DATE).format('YYYY-MM-DD')}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                </Table.Cell>                                                              
                                                            </Table.Row>
                                                        );
                                                    })}
                                                {(resultData === undefined || resultData === null || resultData.length === 0) && (
                                                    <Table.Row>
                                                        <Table.Cell colSpan="6">회원 정보가 존재하지 않습니다. </Table.Cell>
                                                    </Table.Row>
                                                )}
                                                <Table.Row>
                                                    <Table.Cell colSpan="6" className="text-center">
                                                        {(resultData && resultData.length > 0) && (
                                                            <Pagination
                                                                activePage={currentPage}
                                                                ellipsisItem={{
                                                                    content: <Icon name="ellipsis horizontal" />,
                                                                    icon: true,
                                                                }}
                                                                firstItem={{
                                                                    content: <Icon name="angle double left" />,
                                                                    icon: true,
                                                                }}
                                                                lastItem={{
                                                                    content: <Icon name="angle double right" />,
                                                                    icon: true,
                                                                }}
                                                                prevItem={{
                                                                    content: <Icon name="angle left" />,
                                                                    icon: true,
                                                                }}
                                                                nextItem={{
                                                                    content: <Icon name="angle right" />,
                                                                    icon: true,
                                                                }}
                                                                boundaryRange={0}
                                                                siblingRange={2}
                                                                pointing
                                                                secondary
                                                                onPageChange={this.paginationChange}
                                                                totalPages={totalPages}
                                                            />
                                                        )}
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                </Grid.Row>
                            				

                                <Grid.Row style={{ marginLeft: '0px' }}>
                                    <Grid.Column textAlign="center">
                                        <Button size="huge" onClick={this.handleOnClickGoBack}>
                                            이전
                                        </Button>
                                        <Button size="huge" color="teal" onClick={this.onSumbitSetCoupon}>
                                            수정
                                        </Button>									
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        
                        </Form>
                    </div>     
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, null)(withRouter(MgrCouponDetail));