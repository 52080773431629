import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Icon, Modal, Header, Button, Divider } from 'semantic-ui-react';
import Validator, { Validation, Message } from 'es-validator';
import Toastr from 'modern-toastr';

import * as actions from 'actions';
import * as service from 'services/system';
import * as commonUtil from 'utils/common';

Toastr.setPosition('toast-top-center');

let mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  login: (user) => dispatch(actions.loginLocal(user)),
  logout: () => dispatch(actions.logout()),
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.errorPrint = (v) => (
      <div key={v.key} className="valid-feedback">
        <span>{v.message}</span>
      </div>
    );

    this.validatorForLogin = new Validator({
      email: {
        _name: '이메일',
        _hasText: { message: '이메일을 입력해 주세요.' },
        _email: { message: '이메일 형식이 올바르지 않습니다.' },
        _minLength: {
          min: 5,
          message: '이메일 형식이 올바르지 않습니다.',
        },
      },
      password: {
        _name: '암호',
        _hasText: { message: '비밀번호를 입력해주세요.' },
      },
    });

    const localEmail = commonUtil.getLoginEmail();
    const userAuthKey = commonUtil.getAuthKey();

    const login = {
      authKey: userAuthKey,
      email: localEmail,
      password: '',
    };
    //console.log(props.location.state);
    this.state = {
      ...login,
      vLogin: this.validatorForLogin.prepare(login),
      path:
        props.location.state != null ? props.location.state.from.pathname : '/',
      user: props.user,
      openUserCheck: false,
      authenticated: props.authenticated,
    };
  }

  /*
	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.authenticated !== prevState.authenticated) {
			return {
				authenticated: nextProps.authenticated,
			};
		}
		return null;
	}
*/
  componentWillReceiveProps(nextProps) {
    this.setState({
      user: nextProps.user,
      authenticated: nextProps.authenticated,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log('componentDidUpdate', prevProps, prevState);
    if (this.state.authenticated !== prevState.authenticated) {
      if (this.state.authenticated) {
        this.chkAuthKey();
        //console.log(this.state.authenticated);
      }
    }
  }

  onInputTextChange = (input) => (e) => {
    this.setState({
      [input]: e.target.value,
    });
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onSubmit(e);
    }
  };

  handleClickOpenUserCheck = () => {
    this.setState({
      openUserCheck: true,
    });
  };

  handleClickCloseUserCheck = () => {
    this.setState({
      openUserCheck: false,
    });
    this.props.logout();
  };

  handleClickChangeUser = () => {
    //console.log('Change User');
    const user = this.state.user;
    const authKey = commonUtil.getAuthKey();

    this.fetchUpdateUserConnectInfo({
      userId: user.id,
      userAuthKey: authKey,
    });
  };

  chkAuthKey() {
    const user = this.state.user;
    const authKey = commonUtil.getAuthKey();
    //console.log(user, authKey);
    if (user !== undefined && user !== null) {
      if (
        user.curKey !== undefined &&
        user.curKey !== null &&
        user.curKey !== ''
      ) {
        if (user.curKey === authKey) {
          this.props.history.push(this.state.path);
        } else {
          this.handleClickChangeUser();
          /*
					this.setState({
						openUserCheck: true,
					});
					*/
        }
      } else {
        this.fetchUpdateUserConnectInfo({
          userId: user.id,
          userAuthKey: authKey,
        });
      }
    } else {
      Toastr.error(
        '사용자 정보가 올바르지 않습니다. 웹 종료 후 다시 시도해 주세요.'
      );
    }
  }

  fetchUpdateUserConnectInfo = async (params) => {
    let connectInfo = await service.updateUserConnectInfo(params);

    //console.log(connectInfo);
    if (connectInfo !== null) {
      if (connectInfo.status === 200) {
        commonUtil.setAuthKey(connectInfo.data.authKey);
        this.props.history.push(this.state.path);
      } else {
        Toastr.error(connectInfo.data.message);
      }
    } else {
      Toastr.error('접속 정보가 올바르지 않습니다. 다시 시도해 주세요.');
    }
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { vLogin, ...state } = this.state;
    const { login } = this.props;

    this.setState({
      vLogin: this.validatorForLogin.submit(state),
    });

    if (!this.validatorForLogin.getError()) {
      login({
        email: this.state.email,
        password: this.state.password,
      });
    }
  };

  render() {
    const { vLogin, path, openUserCheck } = this.state;
    const { email, password } = vLogin;
    //const { authenticated } = this.props;

    //if (authenticated) {
    //console.log('로그인 되었습니다.');
    //	return <Redirect to={path} />;
    //}

    return (
      <Fragment>
        <div className="container my-5">
          <div className="row">
            <div className="col-auto align-self-start">
              <Link
                to="/join"
                className="btn btn-sm btn-block btn-link text-secondary">
                회원가입
              </Link>
            </div>
            <div className="col"></div>
            <div className="col-auto align-self-end">
              <Link
                to="/recertify"
                className="btn btn-sm btn-block btn-link text-secondary">
                인증메일요청
              </Link>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 align-self-center text-center">
              <h5>로그인 (Login)</h5>
            </div>
          </div>
          <div className="row flex-colum">
            <div className="col-12 mx-auto login-row">
              <div className="align-self-center text-center">
                <form className="form-signin">
                  <div className="row">
                    <div className="col-auto align-self-center">
                      <Icon name="mail" />
                    </div>
                    <div className="col pl-0">
                      <Validation isValid={email.error}>
                        <div className="form-group float-label active">
                          <input
                            id="inputEmail"
                            name="email"
                            type="email"
                            className="form-control"
                            onChange={this.onInputTextChange('email')}
                            value={this.state.email}
                            required
                            autoFocus
                          />
                          <label
                            htmlFor="inputEmail"
                            className="form-control-label">
                            Email address
                          </label>
                        </div>
                        <Message once valid={email} render={this.errorPrint} />
                      </Validation>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-auto align-self-center">
                      <Icon name="lock" />
                    </div>
                    <div className="col pl-0">
                      <Validation isValid={password.error}>
                        <div className="form-group float-label">
                          <input
                            id="inputPassword"
                            name="password"
                            type="password"
                            className="form-control"
                            onChange={this.onInputTextChange('password')}
                            onKeyPress={this.handleKeyPress}
                            value={this.state.password}
                            required
                          />
                          <label
                            htmlFor="inputPassword"
                            className="form-control-label">
                            Password
                          </label>
                        </div>
                        <Message
                          once
                          valid={password}
                          render={this.errorPrint}
                        />
                      </Validation>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-12 mt-auto pb-4 mx-auto login-footer">
              <Link
                to="/find"
                className="btn btn-lg btn-block btn-link text-secondary">
                비밀번호를 잊어버리셨나요?
              </Link>
              <div
                className="btn btn-lg btn-block btn-default position-relative"
                onClick={this.onSubmit}>
                <span>들어가기</span>
                <Icon name="arrow right" className="right-absoute" />
              </div>
            </div>
          </div>
        </div>
        <Modal
          size="small"
          open={openUserCheck}
          onClose={this.handleClickCloseUserCheck}
          className="custom-modal">
          <div className="pt-3 pl-3 text-13 font-600">
            <Icon name="users" />
            <span>중복 로그인</span>
          </div>
          <Divider />
          <div className="pl-3">
            <p>
              <span className="text-11">
                현재 동일 아이디로 다른기기에서 로그인 중입니다. '사용자변경'을
                누르시면 이전에 로그인된 기기는 접속이 종료됩니다.
              </span>
            </p>
          </div>
          <Divider />
          <div className="text-center mb-3">
            <Button color="red" onClick={this.handleClickCloseUserCheck}>
              <Icon name="remove" /> 취소
            </Button>
            <Button color="green" onClick={this.handleClickChangeUser}>
              <Icon name="checkmark" /> 사용자변경
            </Button>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
